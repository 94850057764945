import React from 'react';
import { useForm } from 'react-hook-form';

import Button from '@/components/Button';
import ButtonFooter from '@/components/ButtonFooter';
import Form from '@/components/Form';
import FormLabel from '@/components/FormLabel';
import FormTextarea from '@/components/FormTextarea';
import Modal, { ModalBaseProps } from '@/components/Modal';
import styles from '@/components/SupportModal.module.css';
import Toast from '@/components/Toast';
import { useSendSupportEmailMutation } from '@/graphql';
import useToast from '@/hooks/useToast';

export default function SupportModal({ hide }: ModalBaseProps) {
  const functions = useForm({
    defaultValues: {
      message: '',
    },
  });

  const { showToast } = useToast();

  const [sendSupportEmail] = useSendSupportEmailMutation({
    onCompleted: () => {
      showToast(() => (
        <Toast>We got your message and will get back to you soon.</Toast>
      ));
      hide();
    },
  });

  return (
    <Modal className={styles.modal} hide={hide} title="Contact us">
      <Form
        functions={functions}
        onSubmit={({ message }) => {
          sendSupportEmail({ variables: { message } });
        }}
      >
        <FormLabel name="message">Tell us how we can help</FormLabel>
        <FormTextarea autoFocus name="message" rules={{ required: true }} />

        <ButtonFooter>
          <p className={styles.footer}>
            You can also email us at{' '}
            <Button href="mailto:support@wealthie.app" variant="link">
              support@wealthie.app
            </Button>
            .
          </p>
          <Button variant="primaryButton" size="large" type="submit">
            Send message
          </Button>
        </ButtonFooter>
      </Form>
    </Modal>
  );
}
