import { Decimal, toNumber } from 'bigint-decimal/esm/jsbi';

export function capitalize(input: string): string {
  return input[0].toUpperCase() + input.slice(1);
}

const formatDateFormat = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});

export function formatDate(date: Date): string {
  return formatDateFormat.format(date);
}

const formatDateLongFormat = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
});

export function formatDateLong(date: Date): string {
  return formatDateLongFormat.format(date).replace(/ /g, '\u00A0');
}

const formatDateMonthFormat = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
});

export function formatDateMonth(date: Date): string {
  return formatDateMonthFormat.format(date).replace(/ /g, '\u00A0');
}

const formatDateYearFormat = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
});

export function formatDateYear(date: Date): string {
  return formatDateYearFormat.format(date);
}

const formatQuantityFormat = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

export function formatQuantity(value: Decimal | string): string {
  return formatQuantityFormat.format(parseFloat(value.toString()));
}

const formatQuantityDecimalFormat = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export function formatQuantityDecimal(value: Decimal | string): string {
  return formatQuantityDecimalFormat.format(parseFloat(value.toString()));
}

const formatQuantityRoundedFormat = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export function formatQuantityRounded(value: Decimal | string): string {
  return formatQuantityRoundedFormat.format(parseFloat(value.toString()));
}

export function formatPercentage(value: Decimal | number): string {
  return `${formatQuantityFormat.format(
    typeof value === 'number' ? value : toNumber(value),
  )}%`;
}

const formatPriceFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

// TODO: Replace with custom formatter? Sigh...
// https://stackoverflow.com/q/51983700/365238
export function formatPrice(value: Decimal | string | null): string {
  if (value === null) {
    return '⚠';
  }

  return formatPriceFormat.format(parseFloat(value.toString()));
}

const formatPriceRoundedFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

// TODO: Replace with custom formatter? Sigh...
// https://stackoverflow.com/q/51983700/365238
export function formatPriceRounded(value: Decimal | null): string {
  if (value === null) {
    return '⚠';
  }

  return formatPriceRoundedFormat.format(parseFloat(value.toString()));
}

const formatPriceMultipliedFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

export function formatPriceMultiplied(value: number): string {
  const number = typeof value === 'number' ? value : parseFloat(value);

  if (Math.abs(number) >= 1e12) {
    return `${formatPriceMultipliedFormat.format(number / 1e12)}T`;
  }

  if (Math.abs(number) >= 1e9) {
    return `${formatPriceMultipliedFormat.format(number / 1e9)}B`;
  }

  if (Math.abs(number) >= 1e6) {
    return `${formatPriceMultipliedFormat.format(number / 1e6)}M`;
  }

  if (Math.abs(number) >= 1e3) {
    return `${formatPriceMultipliedFormat.format(number / 1e3)}K`;
  }

  return formatPriceMultipliedFormat.format(number);
}
