import { addDays, startOfDay } from 'date-fns/fp';
import React, { useEffect, useState } from 'react';

import Loader from '@/components/Loader';
import { News, useNewsLazyQuery } from '@/graphql';

import styles from './ChartNewsTooltip.module.css';

const formatDateTimeFormat = new Intl.DateTimeFormat('en-US', {
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  timeZone: 'America/New_York',
});

function formatDateTime(date: Date): string {
  return formatDateTimeFormat.format(date);
}

interface ChartNewsTooltipProps {
  at: Date;
  securityId: string;
}

export default function ChartNewsTooltip({
  at,
  securityId,
}: ChartNewsTooltipProps) {
  const [news, setNews] = useState<News[]>([]);
  const [loading, setLoading] = useState(true);
  const [queryNews] = useNewsLazyQuery({
    onCompleted: (data) => {
      setNews(data.news);
      setLoading(false);
    },
  });
  const startAt = startOfDay(at).toISOString();
  const endAt = startOfDay(addDays(1, at)).toISOString();

  useEffect(() => {
    setNews([]);
    setLoading(true);

    const timer = setTimeout(() => {
      queryNews({
        variables: {
          securityIds: [securityId],
          startAt,
          endAt,
        },
      });
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [queryNews, securityId, startAt, endAt]);

  return (
    <>
      {loading && (
        <div className={styles.loader}>
          <Loader centerHorizontally delay={0} />
        </div>
      )}
      {news.length > 0 && (
        <>
          <ul className={styles.news}>
            {news.slice(0, 3).map(({ at: postedAt, source, url, title }) => (
              <li key={url}>
                {title}
                <div className={styles.source}>
                  {source}
                  <time dateTime={postedAt}>
                    {formatDateTime(new Date(postedAt))}
                  </time>
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  );
}
