import { useMemo, useRef } from 'react';

import getHoldingsSeries from '@/calc/getHoldingsSeries';
import { ActivitySeries, HoldingsSeries } from '@/calc/types';
import usePriceMapSeries from '@/hooks/usePriceMapSeries';

interface UseHoldingsSeriesArgs {
  dates: Date[];
  securityIds: string[];
  activitySeries: ActivitySeries;
}

interface UseHoldingsSeriesResult {
  data: HoldingsSeries;
  loading: boolean;
}

export default function useHoldingsSeries({
  dates,
  securityIds,
  activitySeries,
}: UseHoldingsSeriesArgs): UseHoldingsSeriesResult {
  const prevResult = useRef<Omit<UseHoldingsSeriesResult, 'loading'>>({
    data: [],
  });

  const { data: priceMapSeries, loading } = usePriceMapSeries({
    dates,
    securityIds,
  });

  const result = useMemo(
    () =>
      loading
        ? prevResult.current
        : {
            data: getHoldingsSeries({
              dates,
              priceMapSeries,
              securityIds,
              activitySeries,
            }),
            loading,
          },
    [dates, loading, priceMapSeries, securityIds, activitySeries],
  );

  prevResult.current = result;

  return useMemo(() => ({ ...result, loading }), [loading, result]);
}
