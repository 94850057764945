import React from 'react';

import styles from './ButtonFooter.module.css';

interface ButtonFooterProps {
  children: React.ReactNode;
}

export default function ButtonFooter({ children }: ButtonFooterProps) {
  return <div className={styles.buttonFooter}>{children}</div>;
}
