import React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

import DateInput from '@/components/DateInput';
import { useFormId } from '@/components/Form';

interface FormDateInputProps {
  name: string;
  rules?: RegisterOptions;
}

export default function FormDateInput({ name, rules }: FormDateInputProps) {
  const { control } = useFormContext();
  const formId = useFormId();
  const id = `${name}_${formId}`;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ onBlur, onChange, ref, value }) => (
        <DateInput
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          ref={ref}
          value={value}
        />
      )}
    />
  );
}
