import { useMemo, useRef } from 'react';

import { SecuritiesMap } from '@/calc/types';
import { useSecuritiesQuery } from '@/graphql';

interface UseSecuritiesMapArgs {
  securityIds: string[];
}

interface UseSecuritiesMapResult {
  data: SecuritiesMap;
  loading: boolean;
}

export default function useSecuritiesMap({
  securityIds,
}: UseSecuritiesMapArgs): UseSecuritiesMapResult {
  const prevResult = useRef<Omit<UseSecuritiesMapResult, 'loading'>>({
    data: {},
  });

  const { data, loading } = useSecuritiesQuery({
    skip: securityIds.length === 0,
    variables: { ids: securityIds.slice().sort() },
  });

  const result = useMemo(
    () =>
      loading
        ? prevResult.current
        : {
            data: (data?.securities ?? []).reduce<SecuritiesMap>(
              (acc, s) => ({ ...acc, [s.id]: s }),
              {},
            ),
          },
    [data, loading],
  );

  prevResult.current = result;

  return useMemo(() => ({ ...result, loading }), [loading, result]);
}
