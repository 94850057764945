import { DECIMAL_0, equal, lessThan } from 'bigint-decimal/esm/jsbi';
import React, { useMemo } from 'react';

import {
  getChangePercentage,
  getTotalCostBasis,
  getTotalValue,
} from '@/calc/getHoldingsSeries';
import Header, {
  HeaderGroup,
  HeaderHighlight,
  HeaderItem,
  Heading,
} from '@/components/Header';
import Icon from '@/components/Icon';
import { formatPercentage, formatPriceRounded } from '@/format';
import useLatestHoldings from '@/hooks/useLatestHoldings';
import usePathParams from '@/hooks/usePathParams';

interface GroupHeaderProps {
  group: {
    name: string;
    parentId: string | null;
    allSecurityIds: string[];
  };
}

export default function GroupHeader({
  group: { name, parentId, allSecurityIds },
}: GroupHeaderProps) {
  const { portfolioId } = usePathParams('portfolioId');

  const {
    data: [prevDatum, lastDatum],
  } = useLatestHoldings({
    portfolioId,
    securityIds: allSecurityIds,
  });

  const values = useMemo(() => {
    if (!lastDatum || !prevDatum) {
      return null;
    }

    const returnPercentage = getChangePercentage(
      getTotalCostBasis(lastDatum),
      getTotalValue(lastDatum),
    );

    return (
      <HeaderGroup>
        <HeaderItem label="Value">
          {formatPriceRounded(getTotalValue(lastDatum))}
        </HeaderItem>
        <HeaderItem
          variant={
            (equal(returnPercentage, DECIMAL_0) && 'regular') ||
            (lessThan(DECIMAL_0, returnPercentage) ? 'positive' : 'negative')
          }
          label="Return"
        >
          {!equal(returnPercentage, DECIMAL_0) && (
            <Icon
              name={
                lessThan(DECIMAL_0, returnPercentage) ? 'arrowUp' : 'arrowDown'
              }
              size="32"
            />
          )}
          {formatPercentage(returnPercentage)}
          <HeaderHighlight
            changePercentage={getChangePercentage(
              getTotalValue(prevDatum),
              getTotalValue(lastDatum),
            )}
            icon
            sideNote="today"
          />
        </HeaderItem>
      </HeaderGroup>
    );
  }, [lastDatum, prevDatum]);

  return (
    <Header>
      <HeaderGroup>
        <HeaderItem label={parentId ? 'Group' : 'Portfolio'}>
          <Heading icon={parentId ? 'group' : 'home'}>{name}</Heading>
        </HeaderItem>
      </HeaderGroup>

      {values}
    </Header>
  );
}
