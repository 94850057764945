import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';

import App from '@/App';
import config from '@/config';
import * as serviceWorker from '@/serviceWorker';

Sentry.init({
  dsn: config.sentry.dsn,
  enabled: process.env.NODE_ENV === 'production',
  environment: process.env.NODE_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.01,
});

window.analytics.load(config.segment.key);

window.gtag('config', config.googleAnalytics.measurementId, {
  anonymize_ip: true,
  cookie_domain: config.googleAnalytics.cookieDomain,
  cookie_flags: config.googleAnalytics.cookieFlags,
  debug_mode: true,
});

const params = new URLSearchParams(window.location.search);
const promoCode = params.get('promoCode');

if (promoCode) {
  window.localStorage.setItem('promoCode', promoCode);
}

document.getElementById('loading')?.remove();
const rootEl = document.getElementById('root');
rootEl?.classList.remove('loading');
ReactDOM.render(<App />, rootEl);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
