import { Decimal } from 'bigint-decimal/esm/jsbi';
import { useCallback, useMemo } from 'react';

import { Transaction } from '@/calc/types';
import {
  useCreateTransactionsMutation,
  useDeleteTransactionMutation,
  useTransactionsQuery,
  useUpdateTransactionMutation,
} from '@/graphql';

interface UseTransactionsArgs {
  portfolioId: string;
  securityIds?: string[];
}

interface UseTransactionsResult {
  createTransactions: (transactions: Omit<Transaction, 'id'>[]) => void;
  deleteTransaction: (id: string) => void;
  loading: boolean;
  transactions: Transaction[];
  updateTransaction: (id: string, t: Omit<Transaction, 'id'>) => void;
}

export default function useTransactions({
  portfolioId,
  securityIds,
}: UseTransactionsArgs): UseTransactionsResult {
  const { data, loading } = useTransactionsQuery({
    variables: { portfolioId },
  });
  const [createTransactionsMutation] = useCreateTransactionsMutation({
    refetchQueries: ['groupsSecurities', 'transactions'],
  });
  const [deleteTransactionMutation] = useDeleteTransactionMutation({
    refetchQueries: ['transactions'],
  });
  const [updateTransactionMutation] = useUpdateTransactionMutation({
    refetchQueries: ['transactions'],
  });

  const transactions = useMemo(
    () =>
      (data?.transactions ?? [])
        // TODO: Add test
        .filter(
          ({ securityId }) => !securityIds || securityIds.includes(securityId),
        )
        .map(
          ({
            id,
            isDemo,
            postedAt,
            type,
            securityId,
            price,
            quantity,
            fees,
            uniqueHash,
          }) => ({
            id,
            isDemo,
            at: new Date(postedAt),
            type: type as 'buy' | 'sell',
            securityId,
            price: new Decimal(price),
            quantity: new Decimal(quantity),
            fees: new Decimal(fees),
            uniqueHash: uniqueHash || undefined,
          }),
        ),
    [data, securityIds],
  );

  const createTransactions = useCallback(
    (input: Omit<Transaction, 'id'>[]) =>
      createTransactionsMutation({
        variables: {
          input: input.map(
            ({ at, type, securityId, price, quantity, fees, uniqueHash }) => ({
              postedAt: at.toISOString(),
              type,
              securityId,
              price: price.toString(),
              quantity: quantity.toString(),
              fees: fees.toString(),
              uniqueHash,
            }),
          ),
          portfolioId,
        },
      }),
    [createTransactionsMutation, portfolioId],
  );

  const deleteTransaction = useCallback(
    (id: string) =>
      deleteTransactionMutation({
        variables: { id },
      }),
    [deleteTransactionMutation],
  );

  const updateTransaction = useCallback(
    (
      id: string,
      { at, type, securityId, price, quantity, fees }: Omit<Transaction, 'id'>,
    ) =>
      updateTransactionMutation({
        variables: {
          id,
          input: {
            postedAt: at.toISOString(),
            type,
            securityId,
            price: price.toString(),
            quantity: quantity.toString(),
            fees: fees.toString(),
            portfolioId,
          },
        },
      }),
    [portfolioId, updateTransactionMutation],
  );

  return useMemo(
    () => ({
      createTransactions,
      deleteTransaction,
      loading,
      transactions,
      updateTransaction,
    }),
    [
      createTransactions,
      deleteTransaction,
      loading,
      transactions,
      updateTransaction,
    ],
  );
}
