import React, { useState } from 'react';

import { Security } from '@/calc/types';
import Button from '@/components/Button';
import { NUM_COLORS } from '@/components/Chart';
import ComparisonMarker from '@/components/ComparisonMarker';
import DateRangePicker from '@/components/DateRangePicker';
import SecurityInput from '@/components/SecurityInput';
import Toolbar, { ToolbarGroup } from '@/components/Toolbar';
import getComparisonCharacters from '@/getComparisonCharacters';
import { useSecuritiesQuery } from '@/graphql';
import usePathParams from '@/hooks/usePathParams';
import { securityPath } from '@/paths';

import styles from './ChartToolbar.module.css';

interface ChartToolbarProps {
  children?: React.ReactNode;
  comparisonSecurityIds: string[];
  endAt: Date;
  onAdd: (id: string) => void;
  onRemove: (id: string) => void;
  startAt: Date;
}

export default function ChartToolbar({
  children,
  comparisonSecurityIds,
  endAt,
  onAdd,
  onRemove,
  startAt,
}: ChartToolbarProps) {
  const { portfolioId } = usePathParams('portfolioId');
  const [isSearching, setIsSearching] = useState(false);
  const comparisonSecuritiesQuery = useSecuritiesQuery({
    variables: { ids: comparisonSecurityIds },
  });
  const comparisonSecurities = comparisonSecuritiesQuery.data?.securities || [];
  const comparisonSecuritiesMap = comparisonSecurities.reduce(
    (acc, s) => ({
      ...acc,
      [s.id]: s,
    }),
    {} as { [key: string]: Security },
  );
  const comparisonCharacters = getComparisonCharacters(
    comparisonSecurities.map((cs) => cs.tickerSymbol),
  );

  return (
    <div className={styles.toolbars}>
      <Toolbar>
        <DateRangePicker startAt={startAt} endAt={endAt} />
        {children}
      </Toolbar>

      <div className={styles.comparisonToolbar}>
        {!isSearching ? (
          <>
            <div className={styles.label}>Compare:</div>
            <Toolbar>
              {comparisonSecurityIds.map((id, i) => {
                const tickerSymbol = comparisonSecuritiesMap[id]?.tickerSymbol;

                return (
                  <ToolbarGroup key={id}>
                    <Button
                      className={styles.securityLabel}
                      href={securityPath({ portfolioId, securityId: id })}
                      variant="plain"
                    >
                      <ComparisonMarker
                        color={i % NUM_COLORS}
                        character={comparisonCharacters[tickerSymbol]}
                      />
                      {tickerSymbol}
                    </Button>
                    <Button
                      icon="x"
                      label="Remove"
                      onClick={() => {
                        onRemove(id);
                      }}
                      variant="lightButton"
                    />
                  </ToolbarGroup>
                );
              })}
              <ToolbarGroup>
                <Button
                  icon="plus"
                  label="Add comparison"
                  onClick={() => {
                    setIsSearching(true);
                  }}
                  variant="lightButton"
                />
              </ToolbarGroup>
            </Toolbar>
          </>
        ) : (
          <div className={styles.search}>
            <SecurityInput
              autoFocus
              onBlur={(e) => {
                if (!e.currentTarget.contains(e.relatedTarget as Node)) {
                  setIsSearching(false);
                }
              }}
              onChange={(id) => {
                onAdd(id);
                setIsSearching(false);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
