import { addMinutes } from 'date-fns/fp';

import { ActivitySeries, Split, Transaction } from '@/calc/types';
import { benchmark } from '@/utils';

interface GetActivitySeriesArgs {
  dates: Date[];
  splits: Split[];
  transactions: Transaction[];
}

export default function getActivitySeries({
  dates,
  splits,
  transactions,
}: GetActivitySeriesArgs): ActivitySeries {
  return benchmark('getActivitySeries', () => {
    let iS = 0;
    let iT = 0;

    return dates.map((at) => {
      const activity = [];

      while (
        // TODO: addMinutes is here so that we move the split to the close time
        // on a given date. This is necessary when bucketing splits and prices
        // for HoldingsSeries, otherwise a split may end up on the same day as
        // previous day's price in certain timezones (e.g. PT).
        // This will require revisiting when we have splits from non-US markets.
        (iS < splits.length && addMinutes(990, splits[iS].exAt) <= at) ||
        (iT < transactions.length && transactions[iT].at <= at)
      ) {
        const split = splits[iS];
        const transaction = transactions[iT];

        if (split && (!transaction || split.exAt <= transaction.at)) {
          activity.push({
            type: 'split' as const,
            data: splits[iS],
          });

          iS += 1;
        } else {
          activity.push({
            type: 'transaction' as const,
            data: transactions[iT],
          });

          iT += 1;
        }
      }

      return { at, activity };
    });
  });
}
