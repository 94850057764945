import classNames from 'classnames';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import styles from './Link.module.css';

const EXTERNAL_URL_REGEXP = /^(https?:\/\/|mailto:)/;

interface LinkProps {
  children: React.ReactNode;
  className?: string;
  replace?: boolean;
  target?: '_blank';
  to: string;
}

export default function Link({
  children,
  className,
  replace,
  target,
  to,
}: LinkProps) {
  const classes = classNames(styles.link, className);

  return EXTERNAL_URL_REGEXP.test(to) ? (
    <a
      className={classes}
      href={to}
      rel={target ? 'noopener noreferrer' : undefined}
      target={target}
    >
      {children}
    </a>
  ) : (
    <RouterLink className={classes} replace={replace} target={target} to={to}>
      {children}
    </RouterLink>
  );
}
