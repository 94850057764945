import React from 'react';

import Content from '@/components/Content';
import SecurityChart from '@/components/SecurityChart';
import SecurityDetails from '@/components/SecurityDetails';

export default function SecurityOverview() {
  return (
    <>
      <Content>
        <SecurityChart />
      </Content>

      <Content extraSpacing>
        <SecurityDetails />
      </Content>
    </>
  );
}
