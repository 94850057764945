import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import Content from '@/components/Content';
import { PortfoliosQuery, usePortfoliosQuery } from '@/graphql';
import { groupPath } from '@/paths';

interface HomeInnerProps {
  portfolios: PortfoliosQuery['portfolios'];
}

function HomeInner({ portfolios }: HomeInnerProps) {
  const portfolioId = portfolios[0].id;
  const rootGroupId = portfolios[0].rootGroup.id;

  const history = useHistory();

  if (portfolios.length === 1) {
    history.replace(
      groupPath({
        portfolioId,
        groupId: rootGroupId,
      }),
    );
  }

  return (
    <Content>
      <ul>
        {portfolios.map(({ id, rootGroup }) => (
          <li key={id}>
            <Link to={groupPath({ portfolioId: id, groupId: rootGroup.id })}>
              {rootGroup.name}
            </Link>
          </li>
        ))}
      </ul>
    </Content>
  );
}

export default function Home() {
  const { data } = usePortfoliosQuery();

  if (!data) {
    return null;
  }

  return <HomeInner portfolios={data.portfolios} />;
}
