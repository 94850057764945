import { SeriesItem } from '@/calc/types';
import { benchmark } from '@/utils';

export default function mergeSeries<
  T1 extends SeriesItem,
  T2 extends SeriesItem
>(seriesA: T1[], seriesB: T2[]): (T1 & T2)[] {
  return benchmark('mergeSeries', () =>
    seriesA.map((a, i) => ({ ...a, ...seriesB[i] })),
  );
}
