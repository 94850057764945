import { DECIMAL_0 } from 'bigint-decimal/esm/jsbi';
import { subDays } from 'date-fns/fp';
import { useMemo, useRef } from 'react';

import {
  getComparisonValue,
  getImpreciseComparisonValue,
} from '@/calc/getComparisonSeries';
import {
  getImpreciseTotalCostBasis,
  getImpreciseTotalValue,
  getTotalCostBasis,
  getTotalValue,
} from '@/calc/getHoldingsSeries';
import mergeSeries from '@/calc/mergeSeries';
import { ChartSeries, ChartSeriesItem, SeriesDescriptor } from '@/calc/types';
import { NUM_COLORS } from '@/components/Chart';
import { useSecuritiesQuery } from '@/graphql';
import useActivitySeries from '@/hooks/useActivitySeries';
import useComparisonSeries from '@/hooks/useComparisonSeries';
import useHoldingsSeries from '@/hooks/useHoldingsSeries';

interface UseChartSeriesArgs {
  comparisonSecurityIds: string[];
  dates: Date[];
  portfolioId: string;
  securityIds: string[];
}

interface UseChartSeriesResult {
  data: ChartSeries;
  loading: boolean;
  seriesDescriptors: SeriesDescriptor<ChartSeriesItem>[];
}

export default function useChartSeries({
  comparisonSecurityIds,
  dates,
  portfolioId,
  securityIds,
}: UseChartSeriesArgs): UseChartSeriesResult {
  const prevResult = useRef<Omit<UseChartSeriesResult, 'loading'>>({
    data: [],
    seriesDescriptors: [],
  });

  // Add one day at the beginning that will contain all the past activity.
  // We eliminate it at the end just before returning.
  const augmentedDates = useMemo(() => [subDays(1, dates[0]), ...dates], [
    dates,
  ]);

  const augmentedActivitySeries = useActivitySeries({
    dates: augmentedDates,
    portfolioId,
    securityIds,
  });

  const augmentedHoldingsSeries = useHoldingsSeries({
    dates: augmentedDates,
    securityIds,
    activitySeries: augmentedActivitySeries.data,
  });

  const activitySeries = useMemo(() => augmentedActivitySeries.data.slice(1), [
    augmentedActivitySeries,
  ]);
  const holdingsSeries = useMemo(() => augmentedHoldingsSeries.data.slice(1), [
    augmentedHoldingsSeries,
  ]);

  const comparisonSeries = useComparisonSeries({
    dates,
    initialValue: useMemo(
      () =>
        holdingsSeries.length !== 0
          ? getTotalValue(holdingsSeries[0])
          : DECIMAL_0,
      [holdingsSeries],
    ),
    securityIds: comparisonSecurityIds,
    activitySeries,
  });

  const comparisonSecuritiesQuery = useSecuritiesQuery({
    variables: { ids: comparisonSecurityIds },
  });

  const comparisonSecurities = useMemo(
    () =>
      comparisonSecuritiesQuery.data?.securities
        .slice()
        .sort(
          (a, b) =>
            comparisonSecurityIds.indexOf(a.id) -
            comparisonSecurityIds.indexOf(b.id),
        ) || [],
    [comparisonSecuritiesQuery.data, comparisonSecurityIds],
  );

  const loading =
    [
      augmentedActivitySeries,
      augmentedHoldingsSeries,
      comparisonSecuritiesQuery,
    ].some((s) => s.loading) ||
    (comparisonSecurityIds.length !== 0 && comparisonSeries.loading);

  const result = useMemo(
    () =>
      loading
        ? prevResult.current
        : {
            data: mergeSeries(
              mergeSeries(holdingsSeries, activitySeries),
              comparisonSeries.data,
            ),
            seriesDescriptors: [
              {
                type: 'main' as const,
                label: 'Value',
                getImpreciseValue: getImpreciseTotalValue,
                getValue: getTotalValue,
              },
              ...comparisonSecurities.map(
                ({ tickerSymbol }, i) =>
                  ({
                    color: i % NUM_COLORS,
                    type: 'comparison' as const,
                    label: tickerSymbol,
                    getImpreciseValue: (d) => getImpreciseComparisonValue(d, i),
                    getValue: (d) => getComparisonValue(d, i),
                  } as SeriesDescriptor<ChartSeriesItem>),
              ),
              {
                type: 'costBasis' as const,
                label: 'Cost',
                getImpreciseValue: getImpreciseTotalCostBasis,
                getValue: getTotalCostBasis,
              },
            ],
          },
    [
      activitySeries,
      comparisonSecurities,
      comparisonSeries,
      holdingsSeries,
      loading,
    ],
  );

  prevResult.current = result;

  return useMemo(() => ({ ...result, loading }), [loading, result]);
}
