import React, { useRef } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

import { useFormId } from '@/components/Form';
import SelectInput, { SelectInputProps } from '@/components/SelectInput';

interface FormSecurityInputProps {
  items: SelectInputProps['items'];
  name: string;
  rules?: RegisterOptions;
}

export default function FormDateInput({
  items,
  name,
  rules,
}: FormSecurityInputProps) {
  const { control } = useFormContext();
  const formId = useFormId();
  const id = `${name}_${formId}`;
  const ref = useRef<HTMLButtonElement>(null);

  return (
    <Controller
      name={name}
      control={control}
      onFocus={() => {
        if (ref.current) {
          ref.current.focus();
        }
      }}
      rules={rules}
      render={({ onBlur, onChange, value }) => (
        <SelectInput
          id={id}
          items={items}
          onBlur={onBlur}
          onChange={onChange}
          ref={ref}
          value={value}
        />
      )}
    />
  );
}
