import classNames from 'classnames';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';

import Button from '@/components/Button';
import { MAPPING } from '@/components/Icon';

import styles from './OverflowMenu.module.css';

interface OverflowMenuItemProps {
  icon?: keyof typeof MAPPING;
  children: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export function OverflowMenuItem({
  children,
  icon,
  onClick,
}: OverflowMenuItemProps) {
  return (
    <li>
      <Button
        className={styles.button}
        icon={icon}
        onClick={(e) => {
          e.currentTarget.focus();
          e.currentTarget.blur();

          onClick(e);
        }}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        variant="lightButton"
      >
        {children}
      </Button>
    </li>
  );
}

interface OverflowMenuProps {
  children: React.ReactNode;
  className?: string;
  renderButton: (props: {
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    ref: React.Ref<HTMLButtonElement>;
  }) => React.ReactNode;
}

export default function OverflowMenu({
  children,
  className,
  renderButton,
}: OverflowMenuProps) {
  const rootEl = document.getElementById('root');
  const [isOpen, setIsOpen] = useState(false);
  const [referenceEl, setReferenceEl] = useState<HTMLButtonElement | null>(
    null,
  );
  const [popperEl, setPopperEl] = useState<HTMLDivElement | null>(null);
  const [arrowEl, setArrowEl] = useState<HTMLDivElement | null>(null);

  const popper = usePopper(referenceEl, popperEl, {
    modifiers: [{ name: 'arrow', options: { element: arrowEl } }],
    placement: 'bottom-start',
  });

  function onClick(e: React.MouseEvent<HTMLElement>) {
    if (!isOpen) {
      e.currentTarget.focus();
      setIsOpen(true);
    } else {
      e.currentTarget.blur();
      setIsOpen(false);
    }
  }

  return (
    <>
      <div
        onBlur={() => {
          setIsOpen(false);
        }}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        role="presentation"
      >
        {renderButton({ onClick, ref: setReferenceEl })}
      </div>
      {rootEl &&
        isOpen &&
        ReactDOM.createPortal(
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
          <div
            ref={setPopperEl}
            style={popper.styles.popper}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...popper.attributes.popper}
            className={classNames(styles.listWrapper, className)}
          >
            <div
              className={styles.arrow}
              ref={setArrowEl}
              style={popper.styles.arrow}
            />
            <ul className={styles.list}>{children}</ul>
          </div>,
          rootEl,
        )}
    </>
  );
}
