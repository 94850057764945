import { addDays, startOfDay } from 'date-fns/fp';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Content from '@/components/Content';
import DateInput from '@/components/DateInput';
import Link from '@/components/Link';
import Loader from '@/components/Loader';
import Toolbar, { ToolbarGroup, ToolbarLabel } from '@/components/Toolbar';
import { formatDate } from '@/format';
import { useNewsQuery } from '@/graphql';
import usePathParams from '@/hooks/usePathParams';

import styles from './SecurityNews.module.css';

export default function SecurityNews() {
  const { securityId } = usePathParams('securityId');
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const newsAt = new Date(params.get('at') || startOfDay(new Date()));

  const { data } = useNewsQuery({
    variables: {
      securityIds: [securityId],
      startAt: newsAt.toISOString(),
      endAt: addDays(1, newsAt).toISOString(),
    },
  });

  function changePath(value: Date) {
    history.replace(
      `${location.pathname}?${new URLSearchParams({
        at: value.toISOString(),
      }).toString()}`,
    );
  }

  return (
    <Content>
      <Toolbar>
        <ToolbarGroup>
          <ToolbarLabel label="Date:">
            <DateInput
              id="newsDate"
              value={newsAt}
              onChange={(value) => {
                changePath(value);
              }}
              variant="plain"
            />
          </ToolbarLabel>
        </ToolbarGroup>
      </Toolbar>

      {!data ? (
        <div className={styles.content}>
          <Loader />
        </div>
      ) : (
        <ul className={styles.content}>
          {data.news.map(({ at, source, title, url }) => (
            <li key={url}>
              <Link className={styles.title} to={url} target="_blank">
                {title}
              </Link>
              <div className={styles.source}>
                {source}
                <time dateTime={at}>{formatDate(new Date(at))}</time>
              </div>
            </li>
          ))}
        </ul>
      )}
    </Content>
  );
}
