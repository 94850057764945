/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import { useCombobox } from 'downshift';
import React, { useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import Icon from '@/components/Icon';
import { Security, useSearchQuery, useSecuritiesQuery } from '@/graphql';

import styles from './SecurityInput.module.css';
import selectInputStyles from './SelectInput.module.css';

const DEBOUNCE_DELAY = 150; // ms

function noop() {
  // noop
}

export interface SecurityInputProps {
  autoFocus?: boolean;
  id?: string;
  rounded?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (id: string) => void;
  openOnFocus?: boolean;
  size?: 'medium' | 'large';
  value?: string;
}

type Item = Partial<Pick<Security, 'id' | 'market' | 'name' | 'tickerSymbol'>>;

export default React.forwardRef(
  (
    {
      autoFocus = false,
      id,
      rounded = false,
      onBlur,
      onChange = noop,
      openOnFocus = false,
      size = 'medium',
      value,
    }: SecurityInputProps,
    ref?: React.Ref<HTMLInputElement>,
  ) => {
    const [results, setResults] = useState<Item[]>([]);
    const [query, setQuery] = useState('');
    const [loadedQuery, setLoadedQuery] = useState('');
    const [debouncedQuery] = useDebounce(query, DEBOUNCE_DELAY);

    useSearchQuery({
      variables: { q: debouncedQuery },
      onCompleted(data) {
        if (data) {
          setResults(data.search);
          setLoadedQuery(debouncedQuery);
        }
      },
      skip: debouncedQuery === '',
    });

    const { data: securityData } = useSecuritiesQuery({
      variables: { ids: [value ?? ''] },
      skip: !value,
    });

    const selectedItem = useMemo(
      () => ({
        id: value,
        market: securityData?.securities[0].market,
        name: securityData?.securities[0].name,
        tickerSymbol: securityData?.securities[0].tickerSymbol,
      }),
      [securityData, value],
    );

    const {
      isOpen,
      getMenuProps,
      getInputProps,
      getComboboxProps,
      getItemProps,
      openMenu,
    } = useCombobox({
      defaultHighlightedIndex: 0,
      items: results,
      itemToString: (i) =>
        i?.tickerSymbol ? `${i.tickerSymbol}.${i.market?.toUpperCase()}` : '',
      onInputValueChange: ({ inputValue = '' }) => {
        setQuery(inputValue);

        if (inputValue === '') {
          setResults([]);
        }
      },
      onSelectedItemChange: (data) => {
        if (data.selectedItem?.id) {
          onChange(data.selectedItem.id);
        }
      },
      selectedItem,
      stateReducer: (state, actionAndChanges) => {
        const { type, changes } = actionAndChanges;

        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
            // Select new item with Enter only when done loading and there are
            // results.
            if (loadedQuery === query && results.length > 0) {
              return changes;
            }
            return state;

          default:
            return changes;
        }
      },
    });

    function highlightQuery(text: string): React.ReactNode {
      const index = text.toLowerCase().indexOf(loadedQuery.toLowerCase());

      if (index === -1) {
        return text;
      }

      return (
        <>
          {text.slice(0, index)}
          <strong>{text.slice(index, index + loadedQuery.length)}</strong>
          {text.slice(index + loadedQuery.length)}
        </>
      );
    }

    return (
      <div
        className={classNames(
          selectInputStyles.container,
          styles.container,
          styles[`${size}Size`],
          {
            [styles.rounded]: rounded,
          },
        )}
        onBlur={onBlur}
      >
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
        <div
          {...getComboboxProps()}
          aria-label="Search"
          className={styles.inputWrapper}
          onMouseDown={(e) => {
            e.preventDefault();
            e.currentTarget.querySelector('input')?.focus();
          }}
        >
          <Icon
            bold={rounded}
            className={styles.icon}
            name="search"
            size={rounded || size === 'large' ? '24' : '16'}
          />
          <input
            {...getInputProps({ ref })}
            // For fields shown after a specific user action.
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            id={id}
            onFocus={() => {
              // Run in next tick or onClick / onSelectedItemChange won't fire
              setTimeout(() => {
                if (openOnFocus && query !== '') {
                  openMenu();
                }
              });
            }}
            placeholder="Search..."
          />
        </div>
        <div
          {...getMenuProps()}
          className={classNames(selectInputStyles.items, styles.items, {
            [selectInputStyles.isOpen]: isOpen && results.length > 0,
          })}
          tabIndex={-1}
        >
          <ul className={styles.itemsInner}>
            {results.map((item, index) => (
              <li
                {...getItemProps({ item, index })}
                key={item.id}
                className={classNames(selectInputStyles.item, styles.item)}
              >
                <span className={styles.symbol}>
                  {highlightQuery(item.tickerSymbol ?? '')}.
                  {item.market?.toUpperCase()}
                </span>
                <span className={styles.name}>
                  {highlightQuery(item.name ?? '')}
                </span>
              </li>
            ))}
          </ul>
        </div>
        {isOpen &&
          results.length === 0 &&
          query !== '' &&
          loadedQuery === query && (
            <p
              className={classNames(
                selectInputStyles.items,
                selectInputStyles.isOpen,
                styles.noResults,
              )}
            >
              No results.
            </p>
          )}
      </div>
    );
  },
);
