import '@/index.css';

import { ApolloProvider } from '@apollo/client';
import { createBrowserHistory } from 'history';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Redirect, Route, RouteProps, Router, Switch } from 'react-router-dom';

import createApolloClient from '@/apolloClient';
import { AuthProvider, useAuth } from '@/auth';
import { PointerProvider } from '@/components/PointerDot';
import { ModalProvider } from '@/hooks/useModal';
import { ToastProvider } from '@/hooks/useToast';
import Group from '@/views/Group';
import Home from '@/views/Home';
import Login from '@/views/Login';
import Security from '@/views/Security';
import Signup from '@/views/Signup';
import Verify from '@/views/Verify';

const history = createBrowserHistory();
const apolloClient = createApolloClient({ history });

history.listen(() => {
  window.gtag('event', 'page_view');

  window.analytics.page();
});

function PrivateRoute({ children, ...rest }: RouteProps) {
  const { isLoggedIn } = useAuth();

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={({ location }) =>
        isLoggedIn() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default function App() {
  return (
    <React.StrictMode>
      <Router history={history}>
        <ApolloProvider client={apolloClient}>
          <AuthProvider apolloClient={apolloClient} history={history}>
            <DndProvider backend={HTML5Backend}>
              <PointerProvider>
                <ToastProvider>
                  <ModalProvider>
                    <Switch>
                      <Route path="/login">
                        <Login />
                      </Route>
                      <Route path="/signup">
                        <Signup />
                      </Route>
                      <Route path="/verify/:verificationToken">
                        <Verify />
                      </Route>
                      <PrivateRoute path="/portfolio/:portfolioId/group/:groupId">
                        <Group />
                      </PrivateRoute>
                      <PrivateRoute path="/portfolio/:portfolioId/security/:securityId">
                        <Security />
                      </PrivateRoute>
                      <PrivateRoute path="/">
                        <Home />
                      </PrivateRoute>
                    </Switch>
                  </ModalProvider>
                </ToastProvider>
              </PointerProvider>
            </DndProvider>
          </AuthProvider>
        </ApolloProvider>
      </Router>
    </React.StrictMode>
  );
}
