import React from 'react';

import {
  formatDateLong,
  formatPrice,
  formatPriceMultiplied,
  formatQuantityDecimal,
  formatQuantityRounded,
} from '@/format';
import { SecurityDetails } from '@/graphql';

import styles from './SecurityStats.module.css';

interface SecurityStatsProps {
  securityDetails: SecurityDetails;
}

export default function SecurityStats({ securityDetails }: SecurityStatsProps) {
  return (
    <dl className={styles.securityStats}>
      {([
        {
          label: 'Market cap',
          key: 'marketCap',
          format: formatPriceMultiplied,
        },
        {
          label: 'Beta',
          key: 'beta',
          format: formatQuantityDecimal,
        },
        { label: 'PE ratio', key: 'peRatio', format: formatQuantityDecimal },
        { label: 'EPS', key: 'ttmEPS', format: formatQuantityDecimal },
        {
          label: '52W range',
          key: 'week52Low',
          format: () =>
            `${formatPrice(securityDetails.week52Low)} – ${formatPrice(
              securityDetails.week52High,
            )}`,
        },
        {
          label: 'Average volume',
          key: 'day30AverageVolume',
          format: formatQuantityRounded,
        },
        {
          label: 'Next earnings',
          key: 'nextEarningsAt',
          format: () =>
            formatDateLong(new Date(securityDetails.nextEarningsAt || 0)),
        },
        {
          label: 'Next dividend',
          key: 'nextDividendAt',
          format: () =>
            formatDateLong(new Date(securityDetails.nextDividendAt || 0)),
        },
      ] as {
        label: string;
        key: keyof typeof securityDetails;
        format: (v: unknown) => string;
      }[])
        .filter(({ key }) => securityDetails[key])
        .map(({ label, key, format }) => (
          <div key={key}>
            <dt>{label}</dt>
            <dd>{format(securityDetails[key])}</dd>
          </div>
        ))}
    </dl>
  );
}
