import { DECIMAL_0, equal } from 'bigint-decimal/esm/jsbi';
import { startOfDay, subMonths } from 'date-fns/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import getDates from '@/calc/getDates';
import {
  ChartSeriesItem,
  PriceChartSeriesItem,
  SeriesDescriptor,
  SeriesItem,
} from '@/calc/types';
import Chart from '@/components/Chart';
import ChartActivityTooltip from '@/components/ChartActivityTooltip';
import ChartNewsTooltip from '@/components/ChartNewsTooltip';
import ChartToolbar from '@/components/ChartToolbar';
import ChartTooltip from '@/components/ChartTooltip';
import { useDateRangeQueryParams } from '@/components/DateRangePicker';
import SecurityStats from '@/components/SecurityStats';
import Switch from '@/components/Switch';
import { useSecurityDetailsQuery } from '@/graphql';
import useChartSeries from '@/hooks/useChartSeries';
import usePathParams from '@/hooks/usePathParams';
import usePriceChartSeries from '@/hooks/usePriceChartSeries';
import useSecuritiesMap from '@/hooks/useSecuritiesMap';
import useSetting from '@/hooks/useSetting';
import { securityPath } from '@/paths';

import styles from './SecurityChart.module.css';

export default function SecurityChart() {
  const history = useHistory();
  const { portfolioId, securityId } = usePathParams(
    'portfolioId',
    'securityId',
  );
  const { data } = useSecurityDetailsQuery({ variables: { id: securityId } });

  const { startAt, endAt } = useDateRangeQueryParams({
    startAt: subMonths(3, new Date()),
  });
  const [comparisonSecurityIds = [], setComparisonSecurityIds] = useSetting(
    'comparisonSecurityIds',
  );
  const securityIds = useMemo(() => [securityId], [securityId]);
  const dates = useMemo(() => getDates({ startAt, endAt }), [endAt, startAt]);

  const [includePosition, setIncludePosition] = useState(false);

  const priceChartSeries = usePriceChartSeries({
    comparisonSecurityIds,
    dates,
    portfolioId,
    securityId,
  });

  const chartSeries = useChartSeries({
    comparisonSecurityIds,
    dates,
    portfolioId,
    securityIds,
  });

  const series = includePosition ? chartSeries : priceChartSeries;

  const hasOrHadPosition = chartSeries.data.some(
    (h) => !equal(h.holdings[0].quantity, DECIMAL_0),
  );

  const { data: securitiesMap } = useSecuritiesMap({ securityIds });

  const handleClick = useCallback(
    (d: SeriesItem) => {
      history.push(
        `${securityPath({
          portfolioId,
          securityId,
        })}/news?${new URLSearchParams({
          at: startOfDay(d.at).toISOString(),
        }).toString()}`,
      );
    },
    [history, portfolioId, securityId],
  );

  return (
    <>
      <ChartToolbar
        comparisonSecurityIds={comparisonSecurityIds}
        endAt={endAt}
        onAdd={(id) => {
          setComparisonSecurityIds([...comparisonSecurityIds, id]);
        }}
        onRemove={(id) => {
          setComparisonSecurityIds(
            comparisonSecurityIds.filter((cId) => cId !== id),
          );
        }}
        startAt={startAt}
      >
        {hasOrHadPosition && (
          <div className={styles.switchWrapper}>
            <Switch
              label="My value"
              onChange={(value) => {
                setIncludePosition(value);
              }}
              value={includePosition}
            />
          </div>
        )}
      </ChartToolbar>

      <div className={styles.container}>
        <Chart<ChartSeriesItem | PriceChartSeriesItem>
          series={series.data}
          getDate={(d) => d.at}
          renderTooltipContent={(d) => (
            <>
              <ChartTooltip<ChartSeriesItem | PriceChartSeriesItem>
                datum={d}
                seriesDescriptors={
                  series.seriesDescriptors as SeriesDescriptor<
                    ChartSeriesItem | PriceChartSeriesItem
                  >[]
                }
              />
              <ChartActivityTooltip datum={d} securitiesMap={securitiesMap} />
              <ChartNewsTooltip at={d.at} securityId={securityId} />
            </>
          )}
          loading={series.loading}
          onClick={handleClick}
          seriesDescriptors={
            series.seriesDescriptors as SeriesDescriptor<
              ChartSeriesItem | PriceChartSeriesItem
            >[]
          }
        />

        {data &&
          Object.entries(data.securityDetails).some(
            ([k, v]) => k !== '__typename' && v !== null,
          ) && <SecurityStats securityDetails={data.securityDetails} />}
      </div>
    </>
  );
}
