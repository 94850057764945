import classNames from 'classnames';
import React from 'react';

import styles from './PercentageBar.module.css';

interface PercentageBarProps {
  className?: string;
  value: number;
}

export default function PercentageBar({
  className,
  value,
}: PercentageBarProps) {
  return (
    <div className={classNames(styles.percentageBar, className)}>
      <div style={{ width: `${value}%` }} />
    </div>
  );
}
