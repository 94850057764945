import React from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';

import { useFormId } from '@/components/Form';
import RadioInput from '@/components/RadioInput';

interface FormRadioInputProps {
  children: React.ReactNode;
  name: string;
  rules?: RegisterOptions;
  value: string;
}

export default function FormRadioInput({
  children,
  name,
  rules,
  value,
}: FormRadioInputProps) {
  const { register } = useFormContext();
  const formId = useFormId();
  const id = `${name}_${value}_${formId}`;

  return (
    <RadioInput id={id} name={name} ref={register(rules)} value={value}>
      {children}
    </RadioInput>
  );
}
