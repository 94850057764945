import React from 'react';

import styles from './Toolbar.module.css';

interface ToolbarGroupProps {
  children: React.ReactNode;
}

export function ToolbarGroup({ children }: ToolbarGroupProps) {
  return <div className={styles.toolbarGroup}>{children}</div>;
}

interface ToolbarLabelProps {
  children: React.ReactNode;
  label: string;
}

export function ToolbarLabel({ children, label }: ToolbarLabelProps) {
  return (
    <label className={styles.toolbarLabel}>
      <div className={styles.label}>{label}</div> {children}
    </label>
  );
}

interface ToolbarProps {
  children: React.ReactNode;
}

export default function Toolbar({ children }: ToolbarProps) {
  return <div className={styles.toolbar}>{children}</div>;
}
