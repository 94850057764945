import { Decimal, DECIMAL_0 } from 'bigint-decimal/esm/jsbi';
import csvParse from 'csv-parse/lib/sync';
import { parse } from 'date-fns/fp';

import { Transaction } from '@/calc/types';
import { sha256 } from '@/utils';

const CSV_TRANSACTION_DEFAULTS = {
  fees: DECIMAL_0,
};

const DECIMAL_REGEXP = /^[+-]?\$?[0-9,.]*$/;

const DEFAULT_DATE = new Date();

function dateParse(formatString: string) {
  const hasTime = formatString.includes('HH');

  const p = hasTime
    ? parse(DEFAULT_DATE, formatString)
    : parse(DEFAULT_DATE, `${formatString} HHX`);

  // `21Z` is 4pm ET (US markets close time)
  return (dateString: string) =>
    hasTime ? p(dateString) : p(`${dateString} 21Z`);
}

const DATE_PARSERS = [
  dateParse('MM/dd/yyyy'),
  dateParse('yyyy-MM-dd HH:mm'),
  dateParse('yyyy-MM-dd HH:mm:ss'),
];

const HEADER_REGEXPS = {
  at: [/\btrade date\b/i, /\bdate\b/i],
  type: [/\btype\b/i, /\baction\b/i, /\bdescription\b/i],
  tickerSymbol: [/\bsymbol\b/i],
  quantity: [/\bquantity\b/i],
  price: [/\bprice\b/i],
  fees: [/\bfees\b/i],
};

const REQUIRED_COLUMNS = ['at', 'type', 'tickerSymbol', 'quantity', 'price'];

const TYPE_REGEXPS = [
  { type: 'buy', regexp: /\bbuy\b/i },
  { type: 'buy', regexp: /\bpurchase\b/i },
  { type: 'sell', regexp: /\bsell\b/i },
  { type: 'sell', regexp: /\bsale\b/i },
];

const PARSERS = {
  at(value: string) {
    // Replace non-breaking spaces with regular ones
    const cleanValue = value.replace(/\u00A0/gu, ' ');

    const fn = DATE_PARSERS.find(
      (dp) => !Number.isNaN(dp(cleanValue).getTime()),
    );

    return fn ? fn(cleanValue) : null;
  },

  type(value: string) {
    const tr = TYPE_REGEXPS.find(({ regexp }) => regexp.test(value));
    return tr?.type ?? null;
  },

  tickerSymbol(value: string) {
    return value;
  },

  quantity(value: string) {
    return DECIMAL_REGEXP.test(value)
      ? new Decimal(value.replace(/[$,-]/g, ''))
      : null;
  },

  price(value: string) {
    return DECIMAL_REGEXP.test(value)
      ? new Decimal(value.replace(/[$,-]/g, ''))
      : null;
  },

  fees(value: string) {
    return DECIMAL_REGEXP.test(value)
      ? new Decimal(value.replace(/[$,-]/g, ''))
      : null;
  },
};

function findColumnMatches(regexps: RegExp[], row: string[]) {
  return regexps.reduce(
    (acc, re) => [
      ...acc,
      ...row.reduce(
        (rAcc, column, index) => (re.test(column) ? [...rAcc, index] : rAcc),
        [] as number[],
      ),
    ],
    [] as number[],
  );
}

function getColumns(rows: string[][]) {
  const result = {} as { [key: string]: number[] };
  let prevRowLength = 0;

  // eslint-disable-next-line no-restricted-syntax
  for (const row of rows) {
    Object.entries(HEADER_REGEXPS).forEach(([key, regexps]) => {
      result[key] = [
        ...new Set([
          ...(result[key] || []),
          ...findColumnMatches(regexps, row),
        ]),
      ];
    });

    if (
      REQUIRED_COLUMNS.every((key) => result[key]?.length >= 1) &&
      row.length === prevRowLength
    ) {
      return result;
    }

    prevRowLength = row.length;
  }

  return null;
}

async function getUniqueHash(
  transaction: Partial<Transaction>,
  uniqueHashes: Set<string>,
) {
  for (let i = 0; ; i += 1) {
    const data = { ...transaction, _counter: i };

    // eslint-disable-next-line no-await-in-loop
    const hash = await sha256(
      // Need to provide sorted keys so that `stringify` serializes them
      // always in the same order.
      // https://stackoverflow.com/a/16168003/365238
      JSON.stringify(data, Object.keys(data).sort()),
    );

    if (!uniqueHashes.has(hash)) {
      return hash;
    }
  }
}

export interface CSVTransaction {
  at: Date;
  type: 'buy' | 'sell';
  tickerSymbol: string;
  quantity: Decimal;
  price: Decimal;
  fees: Decimal;
}

export function getTickerSymbols(data: { tickerSymbol: string }[]): string[] {
  return data.map((d) => d.tickerSymbol);
}

interface GetTransactionsArgs {
  csvTransactions: CSVTransaction[];
  tickerSymbolsMap: Record<string, string>;
}

interface GetTransactionsResult {
  transactions: Omit<Transaction, 'id'>[];
  unknownTickerSymbols: string[];
}

export function getTransactions({
  csvTransactions,
  tickerSymbolsMap,
}: GetTransactionsArgs): GetTransactionsResult {
  return csvTransactions.reduce<GetTransactionsResult>(
    (
      { transactions, unknownTickerSymbols },
      { at, type, tickerSymbol, quantity, price, fees },
    ) => {
      const securityId = tickerSymbolsMap[tickerSymbol];
      const transaction = {
        at,
        type,
        securityId,
        quantity,
        price,
        fees,
      };

      return securityId
        ? {
            transactions: [...transactions, transaction],
            unknownTickerSymbols,
          }
        : {
            transactions,
            unknownTickerSymbols: [...unknownTickerSymbols, tickerSymbol],
          };
    },
    { transactions: [], unknownTickerSymbols: [] },
  );
}

export async function injectUniqueHashes(
  transactions: Omit<Transaction, 'id'>[],
) {
  const uniqueHashes = new Set<string>();
  const result: Omit<Transaction, 'id'>[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const t of transactions) {
    // eslint-disable-next-line no-await-in-loop
    const uniqueHash = await getUniqueHash(t, uniqueHashes);
    uniqueHashes.add(uniqueHash);

    result.push({ ...t, uniqueHash });
  }

  return result;
}

interface ParseCSVResult {
  csvTransactions: CSVTransaction[];
  errors: Error[];
}

export function parseCSV(csvContent: string): ParseCSVResult {
  try {
    const rows = csvParse(csvContent, {
      delimiter: ',',
      relaxColumnCount: true,
      trim: true,
    }) as string[][];

    const columns = getColumns(rows);

    if (columns === null) {
      return { csvTransactions: [], errors: [] };
    }

    const csvTransactions = rows.reduce((acc, row) => {
      const csvTransaction = Object.fromEntries(
        Object.entries(columns)
          .map(([key, indexes]) => {
            // eslint-disable-next-line no-restricted-syntax
            for (const index of indexes) {
              const value =
                row[index] !== undefined
                  ? PARSERS[key as keyof typeof HEADER_REGEXPS](row[index])
                  : null;

              if (value !== null) {
                return [key, value];
              }
            }

            return [key, null];
          })
          .filter(([, value]) => value !== null),
      );

      return REQUIRED_COLUMNS.every((key) => csvTransaction[key] !== undefined)
        ? [
            ...acc,
            {
              ...CSV_TRANSACTION_DEFAULTS,
              ...csvTransaction,
            } as CSVTransaction,
          ]
        : acc;
    }, [] as CSVTransaction[]);

    return { csvTransactions, errors: [] };
  } catch (error) {
    return { csvTransactions: [], errors: [error] };
  }
}
