import React, { useRef } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

import { useFormId } from '@/components/Form';
import SecurityInput, { SecurityInputProps } from '@/components/SecurityInput';

interface FormSecurityInputProps {
  autoFocus?: boolean;
  name: string;
  rules?: RegisterOptions;
  size: SecurityInputProps['size'];
}

export default function FormDateInput({
  autoFocus,
  name,
  rules,
  size,
}: FormSecurityInputProps) {
  const { control } = useFormContext();
  const formId = useFormId();
  const id = `${name}_${formId}`;
  const ref = useRef<HTMLInputElement>(null);

  return (
    <Controller
      name={name}
      control={control}
      onFocus={() => {
        if (ref.current) {
          ref.current.focus();
        }
      }}
      rules={rules}
      render={({ onBlur, onChange, value }) => (
        <SecurityInput
          autoFocus={autoFocus}
          id={id}
          onBlur={onBlur}
          onChange={onChange}
          ref={ref}
          size={size}
          value={value}
        />
      )}
    />
  );
}
