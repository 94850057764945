import React from 'react';

import { useAuth } from '@/auth';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import OverflowMenu, { OverflowMenuItem } from '@/components/OverflowMenu';
import { PointerTarget } from '@/components/PointerDot';
import SupportModal from '@/components/SupportModal';
import { useCurrentUserQuery } from '@/graphql';
import useModal from '@/hooks/useModal';
import useSubscription from '@/hooks/useSubscription';

import styles from './TopBar.module.css';

export interface Breadcrumb {
  label: string;
  to: string;
}

interface TopBarProps {
  breadcrumbs?: Breadcrumb[];
}

export default function TopBar({ breadcrumbs }: TopBarProps) {
  const { logOut } = useAuth();
  const currentUserQuery = useCurrentUserQuery();

  const { showBillingModal, trialDaysRemaining } = useSubscription();

  const [showSupportModal] = useModal(({ hide }) => (
    <SupportModal hide={hide} />
  ));

  if (currentUserQuery.data) {
    const { id, email } = currentUserQuery.data.currentUser;

    window.analytics.identify(id, { email });
  }

  return (
    <ul className={styles.topBar}>
      <li />
      <li className={styles.breadcrumbs}>
        {breadcrumbs && breadcrumbs.length > 0 && (
          <nav aria-label="Breadcrumbs">
            <ol>
              {breadcrumbs.map(({ label, to }, i) => {
                const fullLabel =
                  i === 0 ? (
                    <Icon bold className={styles.icon} name="home" size="24" />
                  ) : (
                    label
                  );

                return (
                  <li key={to}>
                    {i < breadcrumbs.length - 1 ? (
                      <Button
                        className={styles.button}
                        href={to}
                        variant="plain"
                      >
                        {fullLabel}
                      </Button>
                    ) : (
                      fullLabel
                    )}
                  </li>
                );
              })}
            </ol>
          </nav>
        )}
      </li>
      <li className={styles.user}>
        <PointerTarget name="helpButton">
          <Button
            icon="help"
            label="Get help"
            variant="lightButton"
            onClick={showSupportModal}
            round
            size="large"
          />
        </PointerTarget>
        <OverflowMenu
          className={styles.overflowMenu}
          renderButton={({ onClick, ref }) => (
            <Button
              className={styles.userButton}
              icon={
                currentUserQuery.data?.currentUser.avatarUrl ? (
                  <div
                    className={styles.avatar}
                    style={{
                      backgroundImage: `url(${currentUserQuery.data.currentUser.avatarUrl})`,
                    }}
                  />
                ) : (
                  'user'
                )
              }
              label="User settings"
              onClick={onClick}
              ref={ref}
              round
              size="large"
              variant="lightButton"
            />
          )}
        >
          {currentUserQuery.data && (
            <li className={styles.userDetail}>
              {currentUserQuery.data.currentUser.email}
            </li>
          )}

          <OverflowMenuItem
            icon="creditCard"
            onClick={() => {
              showBillingModal();
            }}
          >
            Billing
            {trialDaysRemaining !== null && trialDaysRemaining > 0 && (
              <span className={styles.trialEnds}>
                {trialDaysRemaining >= 1
                  ? `${Math.round(trialDaysRemaining)} days left`
                  : 'Trial almost over'}
              </span>
            )}
          </OverflowMenuItem>
          <OverflowMenuItem
            icon="logOut"
            onClick={() => {
              logOut();
            }}
          >
            Log out
          </OverflowMenuItem>
        </OverflowMenu>
      </li>
    </ul>
  );
}
