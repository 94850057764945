import {
  Decimal,
  DECIMAL_0,
  divide,
  equal,
  multiply,
} from 'bigint-decimal/esm/jsbi';

import { Price, Split } from '@/calc/types';
import { benchmark } from '@/utils';

const DECIMAL_1 = new Decimal('1');

interface AdjustPricesArgs {
  prices: Price[];
  splits: Split[];
}

export default function adjustPrices(args: AdjustPricesArgs): Price[] {
  return benchmark('adjustPrices', () => {
    const multipliers: Record<string, Decimal> = {};
    const splits = args.splits.slice().reverse();

    return args.prices
      .slice()
      .reverse()
      .map((p) => {
        const splitsIndex = splits.findIndex(({ exAt }) => exAt < p.at);
        const splitsPivot = splitsIndex === -1 ? splits.length : splitsIndex;

        splits
          .splice(0, splitsPivot)
          .forEach(({ securityId, fromFactor, toFactor }) => {
            if (!equal(toFactor, DECIMAL_0)) {
              multipliers[securityId] = divide(
                multiply(multipliers[securityId] || DECIMAL_1, fromFactor),
                toFactor,
              );
            }
          });

        return {
          ...p,
          value: multiply(p.value, multipliers[p.securityId] || DECIMAL_1),
        };
      })
      .reverse();
  });
}
