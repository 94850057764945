import development from '@/config/development';
import production from '@/config/production';
import test from '@/config/test';
import { Config } from '@/config/types';

const config: Config = (() => {
  switch (process.env.NODE_ENV) {
    case 'production':
      return production;
    case 'test':
      return test;
    default:
      return development;
  }
})();

export default config;
