import classNames from 'classnames';
import React from 'react';

import styles from './Content.module.css';

interface ContentProps {
  children: React.ReactNode;
  extraSpacing?: boolean;
}

export default function Content({
  children,
  extraSpacing = false,
}: ContentProps) {
  return (
    <div
      className={classNames(styles.content, {
        [styles.extraSpacing]: extraSpacing,
      })}
    >
      {children}
    </div>
  );
}
