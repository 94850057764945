interface GroupPathArgs {
  portfolioId: string;
  groupId: string;
}

export function groupPath({ portfolioId, groupId }: GroupPathArgs): string {
  return `/portfolio/${portfolioId}/group/${groupId}`;
}

interface SecurityPathArgs {
  portfolioId: string;
  securityId: string;
}

export function securityPath({
  portfolioId,
  securityId,
}: SecurityPathArgs): string {
  return `/portfolio/${portfolioId}/security/${securityId}`;
}
