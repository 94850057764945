import {
  add,
  Decimal,
  DECIMAL_0,
  divide,
  equal,
  lessThan,
  multiply,
  subtract,
  toNumber,
} from 'bigint-decimal/esm/jsbi';

import {
  ActivitySeries,
  Holding,
  HoldingsSeries,
  HoldingsSeriesItem,
  PriceMapSeries,
  Split,
  Transaction,
} from '@/calc/types';
import { assertNever, benchmark } from '@/utils';

const DECIMAL_1 = new Decimal('1');
const DECIMAL_100 = new Decimal('100');

interface Lot {
  quantity: Decimal;
  price: Decimal;
}

const INITIAL_VALUES = Object.freeze({
  costBasis: DECIMAL_0,
  quantity: DECIMAL_0,
  price: DECIMAL_0,
});

interface ApplySplitArgs {
  holding: Holding;
  lots: Lot[];
  split: Split;
}

function applySplit({ holding, lots, split }: ApplySplitArgs) {
  const { securityId, fromFactor, toFactor } = split;
  const multiplier = equal(fromFactor, DECIMAL_0)
    ? DECIMAL_1
    : divide(toFactor, fromFactor);

  return {
    holding: {
      ...holding,
      securityId,
      quantity: multiply(holding.quantity, multiplier),
    },
    lots: lots.map((l) => ({
      quantity: multiply(l.quantity, multiplier),
      price: divide(l.price, multiplier),
    })),
  };
}

interface ApplyTransactionArgs {
  holding: Holding;
  lots: Lot[];
  transaction: Transaction;
}

function applyBuyTransaction({
  holding,
  lots,
  transaction,
}: ApplyTransactionArgs) {
  const { securityId, quantity, price } = transaction;

  return {
    holding: {
      ...holding,
      securityId,
      costBasis: add(holding.costBasis, multiply(quantity, price)),
      quantity: add(holding.quantity, quantity),
    },
    lots: [...lots, { quantity, price }],
  };
}

function applySellTransaction({
  holding,
  lots,
  transaction,
}: ApplyTransactionArgs) {
  const { securityId } = transaction;
  let { costBasis } = holding;
  let i = 0;
  let q = transaction.quantity;
  let partialLot;

  while (!equal(q, DECIMAL_0)) {
    if (lots[i] === undefined) {
      break;
    }

    const { quantity, price } = lots[i];
    const usedQ = lessThan(q, quantity) ? q : quantity;

    costBasis = subtract(costBasis, multiply(usedQ, price));
    q = subtract(q, usedQ);
    const remainingQ = subtract(quantity, usedQ);

    if (!equal(remainingQ, DECIMAL_0)) {
      partialLot = { quantity: remainingQ, price };
    }

    i += 1;
  }

  return {
    holding: {
      ...holding,
      securityId,
      costBasis,
      quantity: subtract(holding.quantity, transaction.quantity),
    },
    lots: partialLot ? [partialLot, ...lots.slice(i)] : lots.slice(i),
  };
}

function applyTransaction(args: ApplyTransactionArgs) {
  const { holding, lots, transaction } = args;

  switch (transaction.type) {
    case 'buy':
      return applyBuyTransaction(args);
    case 'sell':
      return applySellTransaction(args);
    default:
      return { holding, lots };
  }
}

interface GetHoldingsSeriesArgs {
  dates: Date[];
  priceMapSeries: PriceMapSeries;
  securityIds?: string[];
  activitySeries: ActivitySeries;
}

export default function getHoldingsSeries({
  dates,
  priceMapSeries,
  securityIds = [],
  activitySeries,
}: GetHoldingsSeriesArgs): HoldingsSeries {
  return benchmark('getHoldingsSeries', () => {
    const lotsMap: { [key: string]: Lot[] } = {};
    let prevHoldingMap: { [key: string]: Holding } = Object.fromEntries(
      securityIds.map((securityId) => [
        securityId,
        { ...INITIAL_VALUES, securityId },
      ]),
    );

    return dates.map((at, i) => {
      const { priceMap } = priceMapSeries[i];
      const { activity } = activitySeries[i];

      const holdingMap = activity.reduce((acc, activityItem) => {
        const { securityId } = activityItem.data;

        switch (activityItem.type) {
          case 'split': {
            const { holding, lots } = applySplit({
              holding: acc[securityId] || INITIAL_VALUES,
              lots: lotsMap[securityId] || [],
              split: activityItem.data,
            });

            lotsMap[securityId] = lots;

            return { ...acc, [securityId]: holding };
          }

          case 'transaction': {
            const { holding, lots } = applyTransaction({
              holding: acc[securityId] || INITIAL_VALUES,
              lots: lotsMap[securityId] || [],
              transaction: activityItem.data,
            });

            lotsMap[securityId] = lots;

            return { ...acc, [securityId]: holding };
          }

          default: {
            return assertNever(activityItem);
          }
        }
      }, prevHoldingMap);

      prevHoldingMap = holdingMap;

      const holdings = Object.values(holdingMap).map(
        ({ securityId, costBasis, quantity }) => ({
          securityId,
          costBasis,
          quantity,
          price: priceMap[securityId] || DECIMAL_0,
        }),
      );

      return { at, holdings };
    });
  });
}

export function getChangePercentage(base: Decimal, last: Decimal): Decimal {
  if (equal(base, DECIMAL_0)) {
    return DECIMAL_0;
  }

  return multiply(subtract(divide(last, base), DECIMAL_1), DECIMAL_100);
}

export function getImpreciseTotalCostBasis(item: HoldingsSeriesItem): number {
  return item.holdings.reduce(
    (acc, { costBasis }) => acc + toNumber(costBasis),
    0,
  );
}

export function getImpreciseTotalValue(item: HoldingsSeriesItem): number {
  return item.holdings.reduce(
    (acc, { quantity, price }) => acc + toNumber(quantity) * toNumber(price),
    0,
  );
}

export function getPrice(item: HoldingsSeriesItem): Decimal {
  return item.holdings[0]?.price;
}

export function getTotalCostBasis(item: HoldingsSeriesItem): Decimal {
  return item.holdings.reduce(
    (acc, { costBasis }) => add(acc, costBasis),
    DECIMAL_0,
  );
}

export function getTotalValue(item: HoldingsSeriesItem): Decimal {
  return item.holdings.reduce(
    (acc, { quantity, price }) => add(acc, multiply(quantity, price)),
    DECIMAL_0,
  );
}
