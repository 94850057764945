import { useCallback, useMemo } from 'react';

import getGroups from '@/calc/getGroups';
import { Group } from '@/calc/types';
import {
  useAssignSecuritiesToGroupMutation,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useGroupsQuery,
  useGroupsSecuritiesQuery,
  useUpdateGroupMutation,
} from '@/graphql';

interface UseGroupArgs {
  id: string;
  portfolioId: string;
  skip?: boolean;
}

interface UpdateGroupInput {
  name?: string;
  parentId?: string;
}

interface UseGroupResult {
  assignSecuritiesToGroup: (securityIds: string[], groupId: string) => void;
  createGroup: (name: string) => void;
  deleteGroup: (id: string) => void;
  group: Group | null;
  loading: boolean;
  updateGroup: (id: string, input: UpdateGroupInput) => void;
}

export default function useGroup({
  id,
  portfolioId,
  skip = false,
}: UseGroupArgs): UseGroupResult {
  const groupsQuery = useGroupsQuery({
    skip,
    variables: { portfolioId },
  });
  const groupsSecuritiesQuery = useGroupsSecuritiesQuery({
    skip,
    variables: { portfolioId },
  });
  const [
    assignSecuritiesToGroupMutation,
  ] = useAssignSecuritiesToGroupMutation();
  const [createGroupMutation] = useCreateGroupMutation({
    refetchQueries: ['groups'],
  });
  const [deleteGroupMutation] = useDeleteGroupMutation({
    refetchQueries: ['groups', 'groupsSecurities'],
  });
  const [updateGroupMutation] = useUpdateGroupMutation({
    refetchQueries: ['groups'],
  });

  const group = useMemo(
    () =>
      groupsQuery.data?.groups && groupsSecuritiesQuery.data?.groupsSecurities
        ? getGroups({
            groups: groupsQuery.data.groups,
            groupsSecurities: groupsSecuritiesQuery.data.groupsSecurities,
          })[id] ?? null
        : null,
    [groupsQuery, groupsSecuritiesQuery, id],
  );

  const assignSecuritiesToGroup = useCallback(
    (securityIds: string[], groupId: string) =>
      assignSecuritiesToGroupMutation({
        variables: {
          groupId,
          portfolioId,
          securityIds,
        },
        optimisticResponse: {
          assignSecuritiesToGroup: securityIds.map((securityId) => ({
            __typename: 'GroupSecurity',
            groupId,
            portfolioId,
            securityId,
          })),
        },
      }),
    [assignSecuritiesToGroupMutation, portfolioId],
  );

  const createGroup = useCallback(
    (name: string) =>
      createGroupMutation({
        variables: {
          name,
          parentId: id,
          portfolioId,
        },
      }),
    [createGroupMutation, id, portfolioId],
  );

  const deleteGroup = useCallback(
    (deletedId: string) =>
      deleteGroupMutation({
        variables: {
          id: deletedId,
        },
      }),
    [deleteGroupMutation],
  );

  const updateGroup = useCallback(
    (updatedId: string, input: UpdateGroupInput) =>
      updateGroupMutation({
        variables: { id: updatedId, input },
      }),
    [updateGroupMutation],
  );

  const loading = [groupsQuery, groupsSecuritiesQuery].some((q) => q.loading);

  return useMemo(
    () => ({
      assignSecuritiesToGroup,
      createGroup,
      deleteGroup,
      group,
      loading,
      updateGroup,
    }),
    [
      assignSecuritiesToGroup,
      createGroup,
      deleteGroup,
      group,
      loading,
      updateGroup,
    ],
  );
}
