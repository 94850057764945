import { useHistory, useParams } from 'react-router-dom';

type PathParam = 'groupId' | 'portfolioId' | 'securityId' | 'verificationToken';

export default function usePathParams<T extends PathParam>(
  first: T,
  ...rest: T[]
) {
  const history = useHistory();
  const routerParams = useParams() as Record<T, string>;

  return [first, ...rest].reduce((acc, param) => {
    const value = routerParams[param];

    if (!value) {
      // TODO: Log this
      console.error(`Missing ${param} at ${history.location.pathname}`);
    }

    return { ...acc, [param]: value };
  }, {} as Record<T, string>);
}
