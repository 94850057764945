import { Price, PriceMap, PriceMapSeries } from '@/calc/types';
import { benchmark } from '@/utils';

interface GetPriceMapSeriesArgs {
  dates: Date[];
  prices: Price[];
}

export default function getPriceMapSeries({
  dates,
  prices,
}: GetPriceMapSeriesArgs): PriceMapSeries {
  return benchmark('getPriceMapSeries', () => {
    let i = 0;
    let priceMap: PriceMap = {};
    let prevPriceMap: PriceMap = {};

    return dates.map((at) => {
      priceMap = { ...prevPriceMap };

      while (i < prices.length && prices[i].at <= at) {
        const { securityId, value } = prices[i];

        priceMap[securityId] = value;

        i += 1;
      }

      prevPriceMap = priceMap;

      return { at, priceMap };
    });
  });
}
