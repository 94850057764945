import React from 'react';

import HeaderTabs, { Tab } from '@/components/HeaderTabs';
import { securityPath } from '@/paths';

interface SecurityTabsProps {
  id: string;
  portfolioId: string;
}

export default function SecurityTabs({ id, portfolioId }: SecurityTabsProps) {
  const rootPath = securityPath({ portfolioId, securityId: id });

  return (
    <HeaderTabs>
      <Tab to={rootPath}>Overview</Tab>
      <Tab to={`${rootPath}/transactions`}>Transactions</Tab>
      <Tab to={`${rootPath}/news`}>News</Tab>
    </HeaderTabs>
  );
}
