import React, { useEffect, useMemo } from 'react';

import Banner from '@/components/Banner';
import Button from '@/components/Button';
import { PointerTarget } from '@/components/PointerDot';
import Scrollable from '@/components/Scrollable';
import Sidebar from '@/components/Sidebar';
import TopBar, { Breadcrumb } from '@/components/TopBar';
import WelcomeModal from '@/components/WelcomeModal';
import { useDeleteDemoTransactionsMutation } from '@/graphql';
import useModal from '@/hooks/useModal';
import usePathParams from '@/hooks/usePathParams';
import useSetting from '@/hooks/useSetting';
import useSubscription from '@/hooks/useSubscription';
import useTransactions from '@/hooks/useTransactions';

import styles from './Layout.module.css';

interface LayoutProps {
  breadcrumbs?: Breadcrumb[];
  children: React.ReactNode;
}

export default function Layout({ breadcrumbs = [], children }: LayoutProps) {
  const { portfolioId } = usePathParams('portfolioId');

  const {
    activeOrTrial,
    showBillingModal,
    trialDaysRemaining,
  } = useSubscription();

  const { transactions } = useTransactions({ portfolioId });
  const [showWelcome = false] = useSetting('showWelcome');

  const [deleteDemoTransactions] = useDeleteDemoTransactionsMutation({
    refetchQueries: ['transactions'],
    variables: { portfolioId },
  });

  const [showWelcomeModal] = useModal(({ hide, setCanHide }) => (
    <WelcomeModal hide={hide} setCanHide={setCanHide} />
  ));

  const isDemo = useMemo(() => transactions.some((t) => t.isDemo), [
    transactions,
  ]);

  useEffect(() => {
    if (showWelcome) {
      showWelcomeModal();
    }
  }, [showWelcome, showWelcomeModal]);

  function renderBanner() {
    if (!activeOrTrial) {
      return (
        <Banner variant="error">
          Your trial has ended.{' '}
          <Button
            onClick={() => {
              showBillingModal();
            }}
            variant="link"
          >
            Subscribe now
          </Button>{' '}
          to add more transactions!
        </Banner>
      );
    }

    if (isDemo) {
      return (
        <Banner variant="info">
          We added a few demo transactions for you.{' '}
          <PointerTarget name="removeDemoButton">
            <Button
              onClick={() => {
                deleteDemoTransactions();
              }}
              variant="link"
            >
              Remove demo
            </Button>
          </PointerTarget>{' '}
          when you&apos;re ready.
        </Banner>
      );
    }

    if (
      activeOrTrial &&
      trialDaysRemaining !== null &&
      trialDaysRemaining <= 5
    ) {
      return (
        <Banner variant="warning">
          Your trial ends in{' '}
          {trialDaysRemaining >= 1
            ? `${Math.round(trialDaysRemaining)} days`
            : 'less than a day'}
          .{' '}
          <Button
            onClick={() => {
              showBillingModal();
            }}
            variant="link"
          >
            Subscribe now
          </Button>{' '}
          to keep using Wealthie!
        </Banner>
      );
    }

    return null;
  }

  return (
    <div className={styles.appWrapper}>
      <nav className={styles.sidebarWrapper}>
        <Sidebar />
      </nav>
      <div className={styles.mainWrapper}>
        <div className={styles.topBarWrapper}>
          <TopBar breadcrumbs={breadcrumbs} />
        </div>
        {renderBanner()}
        <Scrollable>
          <main className={styles.main}>{children}</main>
        </Scrollable>
      </div>
    </div>
  );
}
