import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import styles from './Loader.module.css';

const DEFAULT_DELAY = 150; // ms

interface LoaderProps {
  centerHorizontally?: boolean;
  delay?: number;
}

export default function Loader({
  centerHorizontally = false,
  delay = DEFAULT_DELAY,
}: LoaderProps) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay, setIsVisible]);

  return isVisible ? (
    <div
      className={classNames({
        [styles.centerHorizontally]: centerHorizontally,
      })}
    >
      <div className={styles.loader}>
        Loading...
        <div className={styles.one} />
        <div className={styles.two} />
        <div className={styles.three} />
      </div>
    </div>
  ) : null;
}
