import classNames from 'classnames';
import React from 'react';

import { ReactComponent as EmptyGroupIllustration } from '@/assets/illustrations/emptyGroup.svg';
import { ReactComponent as EmptyPortfolioIllustration } from '@/assets/illustrations/emptyPortfolio.svg';
import { ReactComponent as NoDataIllustration } from '@/assets/illustrations/noData.svg';
import { ReactComponent as WelcomeIllustration } from '@/assets/illustrations/welcome.svg';

import styles from './IllustrationPanel.module.css';

const MAPPING = {
  emptyGroup: EmptyGroupIllustration,
  emptyPortfolio: EmptyPortfolioIllustration,
  noData: NoDataIllustration,
  welcome: WelcomeIllustration,
};

interface IllustrationPanelProps {
  centerVertically?: boolean;
  children: React.ReactNode;
  className?: string;
  illustration: keyof typeof MAPPING;
  size?: 'large' | 'small';
}

export default function IllustrationPanel({
  centerVertically = false,
  children,
  className,
  illustration,
  size = 'large',
}: IllustrationPanelProps) {
  const Illustration = MAPPING[illustration];

  return (
    <div
      className={classNames(styles.wrapper, className, styles[`${size}Size`], {
        [styles.centerVertically]: centerVertically,
      })}
    >
      <article className={styles.container}>
        <Illustration className={styles.illustration} />
        <div className={styles.content}>{children}</div>
      </article>
    </div>
  );
}
