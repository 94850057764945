import { ApolloClient } from '@apollo/client';
import { History } from 'history';
import React, { createContext, useContext } from 'react';

interface CreateAuthFunctionsArgs {
  apolloClient: ApolloClient<unknown>;
  history: History;
}

export function createAuthFunctions({
  apolloClient,
  history,
}: CreateAuthFunctionsArgs) {
  function getAuthToken() {
    return window.localStorage.getItem('authToken');
  }

  function isLoggedIn() {
    return getAuthToken() !== null;
  }

  async function logIn(authToken?: string | null) {
    if (authToken) {
      window.localStorage.setItem('authToken', authToken);
      // https://github.com/apollographql/apollo-cache-persist/issues/34#issuecomment-371177206
      await apolloClient.clearStore();
    }

    if (isLoggedIn()) {
      history.replace('/');
    }
  }

  async function logOut() {
    window.analytics.track('user logged out');
    window.analytics.reset();
    window.localStorage.removeItem('authToken');
    // https://github.com/apollographql/apollo-cache-persist/issues/34#issuecomment-371177206
    await apolloClient.clearStore();
    history.replace('/login');
  }

  return {
    getAuthToken,
    isLoggedIn,
    logIn,
    logOut,
  };
}

const AuthContext = createContext<ReturnType<
  typeof createAuthFunctions
> | null>(null);

interface AuthProviderProps extends CreateAuthFunctionsArgs {
  children: React.ReactNode;
}

export function AuthProvider({
  apolloClient,
  history,
  children,
}: AuthProviderProps) {
  return (
    <AuthContext.Provider
      value={createAuthFunctions({ apolloClient, history })}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (context === null) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
