import { AxisBottom, AxisLeft, AxisScale } from '@visx/axis';
import { curveLinear } from '@visx/curve';
import { localPoint } from '@visx/event';
import { GridRows } from '@visx/grid';
import { ParentSizeModern } from '@visx/responsive';
import { scaleLinear, scaleTime } from '@visx/scale';
import { AreaClosed, Bar, Line, LinePath } from '@visx/shape';
import { useTooltip } from '@visx/tooltip';
import classNames from 'classnames';
import { bisector, extent, max, min } from 'd3-array';
import { differenceInCalendarDays } from 'date-fns/fp';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { SeriesDescriptor, SeriesItem } from '@/calc/types';
import ChartMarkerWrapper from '@/components/ChartMarkerWrapper';
import ComparisonMarker from '@/components/ComparisonMarker';
import CostMarker from '@/components/CostMarker';
import Loader from '@/components/Loader';
import MainMarker from '@/components/MainMarker';
import Tooltip from '@/components/Tooltip';
import {
  formatDateLong,
  formatDateMonth,
  formatDateYear,
  formatPriceMultiplied,
} from '@/format';
import getComparisonCharacters from '@/getComparisonCharacters';
import { benchmark } from '@/utils';

import styles from './Chart.module.css';

const LOADER_DELAY = 150; // ms
export const NUM_COLORS = 3;
const NUM_TICKS_X = 6;
const NUM_TICKS_Y = 4;
const X_AXIS_HEIGHT = 24;
const DATE_MONTH_THRESHOLD = 86;
const DATE_YEAR_THRESHOLD = 1087;

function noop() {
  // noop
}

function findScrollableParent(el: Element | null): Element {
  if (el === null || el === document.body) {
    return document.body;
  }

  const { overflow, overflowX, overflowY } = window.getComputedStyle(el);

  if (
    [overflow, overflowX, overflowY].some(
      (prop) => prop === 'auto' || prop === 'scroll',
    )
  ) {
    return el;
  }

  return findScrollableParent(el.parentElement);
}

function getFormatDate(dateMin: Date, dateMax: Date) {
  const diff = differenceInCalendarDays(dateMin, dateMax);

  if (diff > DATE_YEAR_THRESHOLD) {
    return formatDateYear;
  }

  if (diff > DATE_MONTH_THRESHOLD) {
    return formatDateMonth;
  }

  return formatDateLong;
}

interface ChartPlotProps<T extends SeriesItem> {
  series: T[];
  dateScale: AxisScale<number>;
  getDate: (d: T) => Date;
  seriesDescriptor: SeriesDescriptor<T>;
  valueScale: AxisScale<number>;
}

function ChartPlot<T extends SeriesItem>({
  series,
  dateScale,
  getDate,
  seriesDescriptor: { color, getImpreciseValue, type },
  valueScale,
}: ChartPlotProps<T>) {
  return (
    <>
      {type === 'main' && (
        <>
          <AreaClosed
            className={styles.areaClosed}
            data={series}
            x={(d) => dateScale(getDate(d)) ?? 0}
            y={(d) => valueScale(getImpreciseValue(d)) ?? 0}
            yScale={valueScale}
            curve={curveLinear}
          />
          <LinePath
            className={styles.linePath}
            data={series}
            x={(d) => dateScale(getDate(d)) ?? 0}
            y={(d) => valueScale(getImpreciseValue(d)) ?? 0}
            curve={curveLinear}
          />
        </>
      )}
      {type === 'comparison' && (
        <LinePath
          className={styles.linePath}
          style={{
            ['--color' as string]:
              color !== undefined ? `var(--color-chart-${color})` : undefined,
          }}
          data={series}
          x={(d) => dateScale(getDate(d)) ?? 0}
          y={(d) => valueScale(getImpreciseValue(d)) ?? 0}
          curve={curveLinear}
        />
      )}
      {type === 'costBasis' && (
        <LinePath
          className={styles.thinLinePath}
          data={series}
          x={(d) => dateScale(getDate(d)) ?? 0}
          y={(d) => valueScale(getImpreciseValue(d)) ?? 0}
          curve={curveLinear}
        />
      )}
    </>
  );
}

const MemoChartPlot = React.memo(ChartPlot) as typeof ChartPlot;

interface ChartProps<T extends SeriesItem> {
  series: T[];
  getDate: (d: T) => Date;
  renderTooltipContent: (d: T) => React.ReactNode;
  loading?: boolean;
  onClick?: (d: T) => void;
  onHover?: (d: T | null) => void;
  seriesDescriptors: SeriesDescriptor<T>[];
}

function ChartArea<T extends SeriesItem>({
  containerRef,
  series,
  getDate,
  renderTooltipContent,
  height,
  loading = false,
  onClick = noop,
  onHover = noop,
  seriesDescriptors,
  width,
}: ChartProps<T> & {
  containerRef: React.RefObject<HTMLDivElement>;
  height: number;
  width: number;
}) {
  const {
    hideTooltip,
    showTooltip,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip<T>();

  const bisectDate = bisector<T, Date>(getDate).left;

  const xMax = width;
  const yMax = height - X_AXIS_HEIGHT;

  const [dateMin, dateMax] = extent(series, getDate) as [Date, Date];

  const xAxisFormatDate = getFormatDate(dateMin, dateMax);

  const dateScale = useMemo(
    () =>
      scaleTime({
        range: [0, xMax],
        domain: [dateMin, dateMax],
      }),
    [xMax, dateMin, dateMax],
  );

  const valueScale = useMemo(
    () =>
      benchmark('Chart.valueScale', () => {
        const domainMin =
          min(
            seriesDescriptors.map(
              ({ getImpreciseValue }) => min(series, getImpreciseValue) || 0,
            ),
          ) || 0;
        const domainMax =
          max(
            seriesDescriptors.map(
              ({ getImpreciseValue }) => max(series, getImpreciseValue) || 0,
            ),
          ) || 0;
        const scale = scaleLinear({
          range: [yMax, 0],
          domain: [domainMin, domainMax],
          nice: NUM_TICKS_Y,
        });

        return scale;
      }),
    [series, seriesDescriptors, yMax],
  );

  const comparisonCharacters = getComparisonCharacters(
    seriesDescriptors
      .filter((sd) => sd.type === 'comparison')
      .map((sd) => sd.label),
  );

  function getDatumFromEvent(
    event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>,
  ) {
    const { x } = localPoint(event) || { x: 0 };
    const x0 = dateScale.invert(x);
    const index = bisectDate(series, x0, 1);
    const d0 = series[index - 1];
    const d1 = series[index];
    let d = d0;

    if (d1 && getDate(d1)) {
      d =
        x0.valueOf() - getDate(d0).valueOf() >
        getDate(d1).valueOf() - x0.valueOf()
          ? d1
          : d0;
    }

    return d;
  }

  function handleHover(
    event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>,
  ) {
    const d = getDatumFromEvent(event);

    if (d) {
      showTooltip({
        tooltipData: d,
        tooltipLeft: dateScale(getDate(d)),
        tooltipTop: localPoint(event)?.y ?? 0,
      });

      onHover(d);
    }
  }

  function handleHoverEnd() {
    hideTooltip();

    const { length } = series;
    if (length > 0) {
      onHover(null);
    }
  }

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const timer = setTimeout(
      () => {
        setShowLoader(loading);
      },
      loading ? LOADER_DELAY : 0,
    );

    return () => clearTimeout(timer);
  }, [loading, setShowLoader]);

  useEffect(() => {
    const scrollableEl = findScrollableParent(containerRef.current);

    scrollableEl.addEventListener('scroll', hideTooltip, { passive: true });

    return () => {
      scrollableEl.removeEventListener('scroll', hideTooltip);
    };
  }, [containerRef, hideTooltip]);

  // https://github.com/popperjs/react-popper/issues/391
  const tooltipReferenceEl = useMemo(() => {
    const containerRect = containerRef.current?.getBoundingClientRect();
    const x = tooltipLeft + (containerRect?.x ?? 0);
    const y = tooltipTop + (containerRect?.y ?? 0);

    return {
      getBoundingClientRect: () => ({
        width: 0,
        height: 0,
        top: y,
        right: x,
        bottom: y,
        left: x,
      }),
    };
  }, [containerRef, tooltipLeft, tooltipTop]);

  return (
    <>
      {showLoader && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
      <svg width={width} height={height}>
        <GridRows
          className={styles.grid}
          height={yMax}
          numTicks={NUM_TICKS_Y}
          scale={valueScale}
          width={xMax}
          top={0.5}
        />
        {seriesDescriptors.map((sd) => (
          <MemoChartPlot<T>
            series={series}
            dateScale={dateScale}
            getDate={getDate}
            key={sd.label}
            seriesDescriptor={sd}
            valueScale={valueScale}
          />
        ))}
        {!loading && (
          <>
            <AxisLeft
              hideAxisLine
              hideTicks
              labelOffset={0}
              numTicks={NUM_TICKS_Y}
              scale={valueScale}
              tickLabelProps={() => ({})}
              tickLength={0}
              tickFormat={(value) => formatPriceMultiplied(Number(value))}
              tickComponent={({ formattedValue, ...tickProps }) => (
                <text
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...tickProps}
                  className={styles.tickLabel}
                  dx="0.4em"
                  dy="-0.5em"
                >
                  {formattedValue}
                </text>
              )}
            />
            <AxisBottom<AxisScale>
              hideAxisLine
              numTicks={NUM_TICKS_X}
              top={yMax}
              scale={dateScale}
              tickFormat={xAxisFormatDate}
              tickLength={4}
              tickClassName={styles.tick}
              tickComponent={({ formattedValue, ...tickProps }) => (
                <text
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...tickProps}
                  className={styles.tickLabel}
                >
                  {formattedValue}
                </text>
              )}
            />
          </>
        )}
        <Bar
          className={classNames({
            [styles.clickable]: onClick !== noop,
          })}
          x={0}
          y={0}
          width={width}
          height={height}
          fill="transparent"
          onClick={(event) => {
            onClick(getDatumFromEvent(event));
          }}
          onTouchStart={handleHover}
          onTouchMove={handleHover}
          onTouchEnd={handleHoverEnd}
          onMouseMove={handleHover}
          onMouseLeave={handleHoverEnd}
        />
        {tooltipData && (
          <Line
            className={styles.line}
            from={{ x: tooltipLeft, y: 0 }}
            to={{ x: tooltipLeft, y: yMax }}
            pointerEvents="none"
          />
        )}
      </svg>
      {tooltipData && (
        <>
          {seriesDescriptors.map(
            ({ color, getImpreciseValue, label, type }) => (
              <ChartMarkerWrapper
                key={label}
                left={tooltipLeft}
                top={valueScale(getImpreciseValue(tooltipData)) ?? 0}
              >
                {type === 'main' && <MainMarker />}
                {type === 'costBasis' && <CostMarker />}
                {type === 'comparison' && (
                  <ComparisonMarker
                    color={color || 0}
                    character={comparisonCharacters[label]}
                  />
                )}
              </ChartMarkerWrapper>
            ),
          )}

          <Tooltip placement="left" referenceEl={tooltipReferenceEl}>
            {renderTooltipContent(tooltipData)}
          </Tooltip>
        </>
      )}
    </>
  );
}

export default function Chart<T extends SeriesItem>(props: ChartProps<T>) {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={styles.container}
      ref={containerRef}
      style={{ ['--x-axis-height' as string]: `${X_AXIS_HEIGHT}px` }}
    >
      <ParentSizeModern debounceTime={0}>
        {({ width, height }) => (
          <ChartArea<T>
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            containerRef={containerRef}
            height={height}
            width={width}
          />
        )}
      </ParentSizeModern>
    </div>
  );
}
