import React from 'react';

import { SeriesDescriptor, SeriesItem } from '@/calc/types';
import ComparisonMarker from '@/components/ComparisonMarker';
import CostMarker from '@/components/CostMarker';
import MainMarker from '@/components/MainMarker';
import { formatDateLong, formatPrice } from '@/format';
import getComparisonCharacters from '@/getComparisonCharacters';

import styles from './ChartTooltip.module.css';

interface ChartTooltipProps<T extends SeriesItem> {
  datum: T;
  seriesDescriptors: SeriesDescriptor<T>[];
}

export default function ChartTooltip<T extends SeriesItem>({
  datum,
  seriesDescriptors,
}: ChartTooltipProps<T>) {
  const { at } = datum;
  const comparisonCharacters = getComparisonCharacters(
    seriesDescriptors
      .filter((sd) => sd.type === 'comparison')
      .map((sd) => sd.label),
  );

  return (
    <>
      <h3>{formatDateLong(at)}</h3>
      <table className={styles.totals}>
        <tbody>
          {seriesDescriptors.map(({ color, getValue, label, type }) => (
            <tr className={styles[type]} key={label}>
              <th>
                <div>
                  {type === 'main' && <MainMarker />}
                  {type === 'costBasis' && <CostMarker />}
                  {type === 'comparison' && (
                    <ComparisonMarker
                      color={color || 0}
                      character={comparisonCharacters[label]}
                    />
                  )}
                  {label}:
                </div>
              </th>
              <td>{formatPrice(getValue(datum))}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
