import React from 'react';

import HeaderTabs, { Tab } from '@/components/HeaderTabs';
import { groupPath } from '@/paths';

interface GroupTabsProps {
  id: string;
  portfolioId: string;
}

export default function GroupTabs({ id, portfolioId }: GroupTabsProps) {
  const rootPath = groupPath({ portfolioId, groupId: id });

  return (
    <HeaderTabs>
      <Tab to={rootPath}>Overview</Tab>
      <Tab to={`${rootPath}/transactions`}>Transactions</Tab>
    </HeaderTabs>
  );
}
