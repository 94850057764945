import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { History } from 'history';

import { createAuthFunctions } from '@/auth';
import config from '@/config';

const EVICT_LAST_PRICES_INTERVAL = 5 * 60 * 1000;

interface CreateApolloClientArgs {
  history: History;
}

export default function createApolloClient({
  history,
}: CreateApolloClientArgs) {
  let getAuthToken: () => string | null;
  let logOut: () => void;

  const httpLink = createHttpLink({
    uri: `${config.apiUrl}/graphql`,
  });

  const authLink = setContext((_, { headers }) => {
    const authToken = getAuthToken();

    return {
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : '',
      },
    };
  });

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors?.some((e) => e.extensions?.code === 'UNAUTHENTICATED')) {
      logOut();
    }
  });

  const cache = new InMemoryCache({
    typePolicies: {
      GroupSecurity: {
        keyFields: ['portfolioId', 'securityId'],
      },
    },
  });

  // Remove when we have price streaming
  setInterval(() => {
    cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'lastPrices',
      broadcast: true,
    });
  }, EVICT_LAST_PRICES_INTERVAL);

  const apolloClient = new ApolloClient({
    link: authLink.concat(errorLink).concat(httpLink),
    cache,
    assumeImmutableResults: true,
  });

  ({ getAuthToken, logOut } = createAuthFunctions({ apolloClient, history }));

  return apolloClient;
}
