import classNames from 'classnames';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { getErrorMessage, useFormId } from '@/components/Form';

import styles from './FormLabel.module.css';

interface FormLabelProps {
  children: React.ReactNode;
  name?: string;
  right?: boolean;
}

export default function FormLabel({
  children,
  name,
  right = false,
}: FormLabelProps) {
  const { errors } = useFormContext();
  const formId = useFormId();
  const error = name && errors[name];
  const id = name && `${name}_${formId}`;

  return (
    <div className={classNames(styles.wrapper, { [styles.right]: right })}>
      <label className={styles.label} htmlFor={id}>
        {children}
      </label>
      {error && (
        <label className={styles.error} htmlFor={id}>
          {error.message || getErrorMessage(error.type)}
        </label>
      )}
    </div>
  );
}
