let _Symbol$toStringTag;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import JSBI from 'jsbi';
const BI_0 = JSBI.BigInt('0');
const BI_10 = JSBI.BigInt('10');
const DIVIDE_PRECISION = 20;
_Symbol$toStringTag = Symbol.toStringTag;
export class Decimal {
  constructor(input) {
    _defineProperty(this, "value", BI_0);

    _defineProperty(this, "scale", 0);

    if (typeof input === 'string') {
      const dotPosition = input.indexOf('.');
      return new Decimal({
        value: JSBI.BigInt(input.replace('.', '')),
        scale: dotPosition >= 0 ? input.length - dotPosition - 1 : 0
      });
    }

    this.value = input.value;
    this.scale = input.scale;

    while (JSBI.equal(JSBI.remainder(this.value, BI_10), BI_0) && this.scale > 0) {
      this.value = JSBI.divide(this.value, BI_10);
      this.scale -= 1;
    }
  }

  toJSON() {
    return this.toString();
  }

  toString() {
    const {
      value,
      scale
    } = this;
    const stringValue = value.toString().replace('-', '');
    const stringLength = stringValue.length;
    const sign = JSBI.lessThan(value, BI_0) ? '-' : '';
    const dotPosition = stringLength - scale;

    if (dotPosition === stringLength) {
      return `${sign}${stringValue}`;
    }

    if (dotPosition <= 0) {
      return `${sign}0.${stringValue.padStart(scale, '0')}`;
    }

    const left = stringValue.slice(0, dotPosition);
    const right = stringValue.slice(dotPosition, stringLength);
    return `${sign}${left}.${right}`;
  }

  get [_Symbol$toStringTag]() {
    return this.toString();
  }

}

function toDecimal(input) {
  return input instanceof Decimal ? input : new Decimal(input);
}

function getScaledValues(a, b) {
  const scale = Math.max(a.scale, b.scale);
  return {
    valueA: JSBI.multiply(a.value, JSBI.exponentiate(BI_10, JSBI.BigInt(scale - a.scale))),
    valueB: JSBI.multiply(b.value, JSBI.exponentiate(BI_10, JSBI.BigInt(scale - b.scale))),
    scale
  };
}

export const DECIMAL_0 = new Decimal('0');
export function add(a, b) {
  const {
    valueA,
    valueB,
    scale
  } = getScaledValues(toDecimal(a), toDecimal(b));
  return new Decimal({
    value: JSBI.add(valueA, valueB),
    scale
  });
}
export function divide(a, b) {
  const decimalA = toDecimal(a);
  const decimalB = toDecimal(b);
  const scaleDelta = Math.max(0, DIVIDE_PRECISION - decimalA.scale + decimalB.scale);
  return new Decimal({
    value: JSBI.divide(JSBI.multiply(decimalA.value, JSBI.exponentiate(BI_10, JSBI.BigInt(scaleDelta))), decimalB.value),
    scale: decimalA.scale - decimalB.scale + scaleDelta
  });
}
export function equal(a, b) {
  const decimalA = toDecimal(a);
  const decimalB = toDecimal(b);
  return decimalA.scale === decimalB.scale && JSBI.equal(decimalA.value, decimalB.value);
}
export function lessThan(a, b) {
  const {
    valueA,
    valueB
  } = getScaledValues(toDecimal(a), toDecimal(b));
  return JSBI.lessThan(valueA, valueB);
}
export function multiply(a, b) {
  const decimalA = toDecimal(a);
  const decimalB = toDecimal(b);
  return new Decimal({
    value: JSBI.multiply(decimalA.value, decimalB.value),
    scale: decimalA.scale + decimalB.scale
  });
}
export function subtract(a, b) {
  const {
    valueA,
    valueB,
    scale
  } = getScaledValues(toDecimal(a), toDecimal(b));
  return new Decimal({
    value: JSBI.subtract(valueA, valueB),
    scale
  });
}
export function toNumber(a) {
  return JSBI.toNumber(a.value) / Math.pow(10, a.scale);
}
