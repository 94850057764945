import React, { useState } from 'react';

import { SeriesDescriptor, SeriesItem } from '@/calc/types';
import AllocationChart, { AllocationDatum } from '@/components/AllocationChart';
import Chart from '@/components/Chart';

import styles from './ChartWithAllocation.module.css';

interface ChartWithAllocationProps<T extends SeriesItem> {
  series: T[];
  getAllocationData: (d?: T) => AllocationDatum[];
  getDate: (d: T) => Date;
  renderTooltipContent: (d: T) => React.ReactNode;
  loading?: boolean;
  seriesDescriptors: SeriesDescriptor<T>[];
}

export default function ChartWithAllocation<T extends SeriesItem>({
  series,
  getAllocationData,
  getDate,
  renderTooltipContent,
  loading,
  seriesDescriptors,
}: ChartWithAllocationProps<T>) {
  const [hoveredDatum, setHoveredDatum] = useState<T | null>(null);

  return (
    <div className={styles.container}>
      <Chart<T>
        series={series}
        getDate={getDate}
        renderTooltipContent={renderTooltipContent}
        loading={loading}
        onHover={(d) => {
          setHoveredDatum(d);
        }}
        seriesDescriptors={seriesDescriptors}
      />
      <AllocationChart
        data={getAllocationData(hoveredDatum || series.slice(-1)[0])}
      />
    </div>
  );
}
