import Mousetrap from 'mousetrap';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import ModalOverlay from '@/components/ModalOverlay';

const mousetrap = new Mousetrap(document.body);

interface RenderModalFnArgs {
  canHide: boolean;
  hide: (force?: boolean) => void;
  setCanHide: (value: boolean) => void;
}

type RenderModalFn = (args: RenderModalFnArgs) => React.ReactNode;

interface ModalContextValue {
  canHide: boolean;
  hide: (force?: boolean) => void;
  setCanHide: (value: boolean) => void;
  show: (renderModal: RenderModalFn) => void;
}

const ModalContext = createContext<ModalContextValue | null>(null);

interface ModalProviderProps {
  children: React.ReactNode;
}

export function ModalProvider({ children }: ModalProviderProps) {
  const [modal, setModal] = useState<React.ReactNode | null>(null);
  const [canHide, setCanHide] = useState(true);

  const hide = useCallback(
    (force = false) => {
      if (canHide || force) {
        setModal(null);
      }
    },
    [canHide],
  );

  const show = useCallback(
    (renderModal: RenderModalFn) => {
      setModal(renderModal({ canHide, hide, setCanHide }));
    },
    [canHide, hide, setCanHide],
  );

  useEffect(() => {
    if (modal) {
      mousetrap.bind('esc', hide);

      return () => {
        mousetrap.unbind('esc');
      };
    }

    return undefined;
  }, [hide, modal]);

  const value = useMemo(() => ({ canHide, hide, setCanHide, show }), [
    canHide,
    hide,
    setCanHide,
    show,
  ]);

  return (
    <ModalContext.Provider value={value}>
      {children}
      {modal && <ModalOverlay onClick={hide}>{modal}</ModalOverlay>}
    </ModalContext.Provider>
  );
}

type UseModalResult = [() => void, () => void];

export default function useModal(renderModal: RenderModalFn): UseModalResult {
  const context = useContext(ModalContext);

  if (context === null) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  const { hide, show } = context;

  return [() => show(renderModal), hide];
}
