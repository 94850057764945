import React from 'react';
import { useFormContext } from 'react-hook-form';

import { getErrorMessage } from '@/components/Form';

import styles from './FormFieldset.module.css';

interface FormFieldsetProps {
  children: React.ReactNode;
  legend: string;
  name: string;
}

export default function FormFieldset({
  children,
  legend,
  name,
}: FormFieldsetProps) {
  const { errors } = useFormContext();
  const error = errors[name];

  return (
    <fieldset className={styles.fieldset}>
      <legend>
        {legend}
        {error && (
          <span className={styles.error}>
            {error.message || getErrorMessage(error.type)}
          </span>
        )}
      </legend>

      {children}
    </fieldset>
  );
}
