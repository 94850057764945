import { Decimal } from 'bigint-decimal/esm/jsbi';
import { useMemo } from 'react';

import getActivitySeries from '@/calc/getActivitySeries';
import { ActivitySeries } from '@/calc/types';
import { useSplitsQuery } from '@/graphql';
import useTransactions from '@/hooks/useTransactions';

interface UseActivitySeriesArgs {
  dates: Date[];
  portfolioId: string;
  securityIds: string[];
}

interface UseActivitySeriesResult {
  data: ActivitySeries;
  loading: boolean;
}

export default function useActivitySeries({
  dates,
  portfolioId,
  securityIds,
}: UseActivitySeriesArgs): UseActivitySeriesResult {
  const splitsQuery = useSplitsQuery({ variables: { securityIds } });

  const splits = useMemo(
    () =>
      (splitsQuery.data?.splits || []).map(
        ({ id, exAt, securityId, fromFactor, toFactor }) => ({
          id,
          exAt: new Date(exAt),
          securityId,
          fromFactor: new Decimal(fromFactor),
          toFactor: new Decimal(toFactor),
        }),
      ),
    [splitsQuery],
  );

  const { transactions, loading } = useTransactions({
    portfolioId,
    securityIds,
  });

  return useMemo(
    () => ({
      data: getActivitySeries({
        dates,
        splits,
        transactions,
      }),
      loading: loading || splitsQuery.loading,
    }),
    [dates, loading, splits, splitsQuery, transactions],
  );
}
