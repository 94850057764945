import React, { useEffect, useState } from 'react';

import Button from '@/components/Button';
import ButtonFooter from '@/components/ButtonFooter';
import Icon from '@/components/Icon';
import IllustrationPanel from '@/components/IllustrationPanel';
import Modal, { ModalBaseProps } from '@/components/Modal';
import PointerDot from '@/components/PointerDot';
import useSetting from '@/hooks/useSetting';

import PercentageBar from './PercentageBar';
import styles from './WelcomeModal.module.css';

const PAGES = [
  <>
    <h2>Welcome</h2>

    <p>Thank you for giving Wealthie a try!</p>

    <p>
      Wealthie generates all of its charts and reports based on your historical
      transactions.
    </p>

    <p>
      To add a transaction, use{' '}
      <span className={styles.buttonText}>
        the <Icon bold name="plus" size="16" /> button
      </span>{' '}
      in the sidebar on the left.
    </p>

    <PointerDot name="plusButton" />
  </>,

  <>
    <p>
      We added a few demo transactions for you so that you can try Wealthie out.
    </p>

    <p>
      You can remove them whenever you&apos;d like by clicking on the &ldquo;
      <strong>Remove demo</strong>&rdquo; link above.
    </p>

    <p>
      We&apos;ll keep all the transactions that you&apos;ve added yourself
      intact.
    </p>

    <PointerDot name="removeDemoButton" />
  </>,

  <>
    <p>We strive to make Wealthie as easy to use as possible.</p>

    <p>
      If you have any questions, doubts, or feedback feel free to contact us by
      clicking on{' '}
      <span className={styles.buttonText}>
        the <Icon bold name="help" size="16" /> button
      </span>{' '}
      in the top right corner.
    </p>

    <p>Happy investing!</p>

    <PointerDot name="helpButton" />
  </>,
];

export default function WelcomeModal({
  hide,
  setCanHide,
}: ModalBaseProps & { setCanHide: (value: boolean) => void }) {
  const [page, setPage] = useState(0);
  const [, setShowWelcome] = useSetting('showWelcome');

  useEffect(() => {
    setCanHide(false);

    return () => {
      setCanHide(true);
    };
  });

  return (
    <Modal className={styles.modal} hide={hide}>
      <div className={styles.content}>
        <IllustrationPanel illustration="welcome" size="small">
          {PAGES[page]}
        </IllustrationPanel>
      </div>

      <ButtonFooter>
        <div className={styles.footer}>
          <PercentageBar value={((page + 1) * 100) / PAGES.length} />
        </div>

        {page > 0 && (
          <Button
            onClick={() => {
              setPage(page - 1);
            }}
            size="large"
            variant="regularButton"
          >
            Back
          </Button>
        )}
        {page < PAGES.length - 1 && (
          <Button
            onClick={() => {
              setPage(page + 1);
            }}
            size="large"
            variant="primaryButton"
          >
            Next
          </Button>
        )}
        {page === PAGES.length - 1 && (
          <Button
            onClick={() => {
              setShowWelcome(false);

              // Wait for `setShowWelcome` to propagate
              setTimeout(() => {
                hide(true);
              });
            }}
            size="large"
            variant="primaryButton"
          >
            Close
          </Button>
        )}
      </ButtonFooter>
    </Modal>
  );
}
