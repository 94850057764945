import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';

import { assertNever } from '@/utils';

import styles from './PointerDot.module.css';

type ReferenceElement = HTMLElement | null;
type State = Record<string, ReferenceElement>;

type Action =
  | {
      type: 'add';
      name: string;
      referenceEl: ReferenceElement;
    }
  | { type: 'remove'; name: string };

interface PointerContextValue {
  dispatch: React.Dispatch<Action>;
  state: State;
}

const PointerContext = createContext<PointerContextValue | null>(null);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'add': {
      const { name, referenceEl } = action;
      return { ...state, [name]: referenceEl };
    }

    case 'remove': {
      const { [action.name]: _removed, ...rest } = state;
      return rest;
    }

    default: {
      return assertNever(action);
    }
  }
}

interface PointerProviderProps {
  children: React.ReactNode;
}

export function PointerProvider({ children }: PointerProviderProps) {
  const [state, dispatch] = useReducer(reducer, {} as State);

  return (
    <PointerContext.Provider value={{ dispatch, state }}>
      {children}
    </PointerContext.Provider>
  );
}

interface PointerTargetProps {
  children: React.ReactNode;
  name: string;
}

export function PointerTarget({ children, name }: PointerTargetProps) {
  const pointerContext = useContext(PointerContext);

  if (pointerContext === null) {
    throw new Error('PointerTarget must be used within a PointerProvider');
  }

  const { dispatch } = pointerContext;
  const [referenceEl, setReferenceEl] = useState<ReferenceElement>(null);

  useEffect(() => {
    dispatch({ type: 'add', name, referenceEl });

    return () => {
      dispatch({ type: 'remove', name });
    };
  }, [dispatch, name, referenceEl]);

  return (
    <span className={styles.pointerTarget} ref={setReferenceEl}>
      {children}
    </span>
  );
}

interface PointerDotProps {
  name: string;
}

export default function PointerDot({ name }: PointerDotProps) {
  const pointerContext = useContext(PointerContext);

  if (pointerContext === null) {
    throw new Error('PointerDot must be used within a PointerProvider');
  }

  const rootEl = document.getElementById('root');
  const referenceEl = pointerContext.state[name];
  const [popperEl, setPopperEl] = useState<ReferenceElement>(null);

  const popper = usePopper(referenceEl, popperEl, {
    modifiers: [{ name: 'offset', options: { offset: [0, -2] } }],
    placement: 'bottom',
  });

  if (!rootEl || !referenceEl) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      ref={setPopperEl}
      style={popper.styles.popper}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...popper.attributes.popper}
      className={styles.pointerDot}
    />,
    rootEl,
  );
}
