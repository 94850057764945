/* eslint-disable @typescript-eslint/no-explicit-any, import/no-duplicates */
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  JSON: any;
};

export type AuthResult = {
  __typename?: 'AuthResult';
  authToken: Maybe<Scalars['String']>;
  error: Maybe<Scalars['String']>;
};

export type CancelSubscriptionResult = {
  __typename?: 'CancelSubscriptionResult';
  error: Maybe<Scalars['String']>;
};

export type VerificationResult = {
  __typename?: 'VerificationResult';
  error: Maybe<Scalars['String']>;
};

export type SendSupportEmailResult = {
  __typename?: 'SendSupportEmailResult';
  error: Maybe<Scalars['String']>;
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
  parentId: Maybe<Scalars['ID']>;
  portfolioId: Scalars['ID'];
  name: Scalars['String'];
};

export type GroupSecurity = {
  __typename?: 'GroupSecurity';
  groupId: Scalars['ID'];
  portfolioId: Scalars['ID'];
  securityId: Scalars['ID'];
};

export type UpdateGroupInput = {
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
  postedAt: Scalars['DateTime'];
  quantity: Scalars['String'];
  uniqueHash: Maybe<Scalars['String']>;
  portfolioId: Scalars['ID'];
  securityId: Scalars['ID'];
  price: Scalars['String'];
  type: Scalars['String'];
  fees: Scalars['String'];
  isDemo: Scalars['Boolean'];
};

export type CreateSubscriptionInput = {
  subscriptionPlan: Scalars['String'];
  stripePaymentMethodId: Scalars['String'];
  stripePromotionCodeId?: Maybe<Scalars['String']>;
};

export type CreateSubscriptionResult = {
  __typename?: 'CreateSubscriptionResult';
  clientSecret: Maybe<Scalars['String']>;
  error: Maybe<Scalars['String']>;
};

export type CreateTransactionInput = {
  postedAt: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  quantity: Scalars['String'];
  securityId: Scalars['ID'];
  price: Scalars['String'];
  type: Scalars['String'];
  fees: Scalars['String'];
  isoCurrencyCode?: Maybe<Scalars['String']>;
  uniqueHash?: Maybe<Scalars['String']>;
};

export type UpdateTransactionInput = {
  postedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  portfolioId?: Maybe<Scalars['ID']>;
  securityId?: Maybe<Scalars['ID']>;
  price?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  fees?: Maybe<Scalars['String']>;
  isoCurrencyCode?: Maybe<Scalars['String']>;
};

export type SecurityPath = {
  __typename?: 'SecurityPath';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Security = {
  __typename?: 'Security';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  market: Scalars['String'];
  name: Maybe<Scalars['String']>;
  path: Maybe<Array<SecurityPath>>;
  plaidSecurityId: Maybe<Scalars['ID']>;
  tickerSymbol: Scalars['String'];
  type: Maybe<Scalars['String']>;
  dayPricesFetchedAt: Maybe<Scalars['DateTime']>;
  splitsFetchedAt: Maybe<Scalars['DateTime']>;
};

export type SecurityDetails = {
  __typename?: 'SecurityDetails';
  CEO: Maybe<Scalars['String']>;
  beta: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  day30AverageVolume: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  employees: Maybe<Scalars['String']>;
  marketCap: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  nextDividendAt: Maybe<Scalars['DateTime']>;
  nextEarningsAt: Maybe<Scalars['DateTime']>;
  peRatio: Maybe<Scalars['String']>;
  ttmEPS: Maybe<Scalars['String']>;
  website: Maybe<Scalars['String']>;
  week52High: Maybe<Scalars['String']>;
  week52Low: Maybe<Scalars['String']>;
};

export type News = {
  __typename?: 'News';
  at: Scalars['DateTime'];
  source: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  avatarUrl: Maybe<Scalars['String']>;
  email: Scalars['String'];
  isEmailVerified: Scalars['Boolean'];
  provider: Maybe<Scalars['String']>;
  passwordHash: Maybe<Scalars['String']>;
  settings: UserSettings;
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionCancelAtPeriodEnd: Scalars['Boolean'];
  stripeSubscriptionId: Maybe<Scalars['String']>;
  stripeSubscriptionStatus: Maybe<Scalars['String']>;
  subscriptionNextPaymentAt: Maybe<Scalars['DateTime']>;
  subscriptionPlan: Maybe<Scalars['String']>;
  trialEndAt: Scalars['DateTime'];
};

export type UserSettings = {
  __typename?: 'UserSettings';
  comparisonSecurityIds: Maybe<Array<Scalars['ID']>>;
  showWelcome: Maybe<Scalars['Boolean']>;
};

export type Portfolio = {
  __typename?: 'Portfolio';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
  rootGroup: Group;
};

export type Price = {
  __typename?: 'Price';
  at: Scalars['DateTime'];
  securityId: Scalars['ID'];
  value: Scalars['String'];
};

export type Split = {
  __typename?: 'Split';
  id: Scalars['ID'];
  exAt: Scalars['DateTime'];
  fromFactor: Scalars['String'];
  securityId: Scalars['ID'];
  toFactor: Scalars['String'];
};

export type StripePaymentMethod = {
  __typename?: 'StripePaymentMethod';
  brand: Scalars['String'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  id: Scalars['String'];
  last4: Scalars['String'];
};

export enum StripeCouponDuration {
  Once = 'once',
  Repeating = 'repeating',
  Forever = 'forever',
}

export type StripePromotionCode = {
  __typename?: 'StripePromotionCode';
  code: Scalars['String'];
  couponAmountOff: Maybe<Scalars['Int']>;
  couponDuration: StripeCouponDuration;
  couponDurationInMonths: Maybe<Scalars['Int']>;
  couponName: Maybe<Scalars['String']>;
  couponPercentOff: Maybe<Scalars['Int']>;
  id: Scalars['String'];
};

export type UpdateStripePaymentMethodInput = {
  stripePaymentMethodId: Scalars['String'];
};

export type UpdateStripePaymentMethodResult = {
  __typename?: 'UpdateStripePaymentMethodResult';
  clientSecret: Maybe<Scalars['String']>;
  error: Maybe<Scalars['String']>;
};

export type UpdateSubscriptionInput = {
  subscriptionPlan: Scalars['String'];
};

export type UpdateSubscriptionResult = {
  __typename?: 'UpdateSubscriptionResult';
  error: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  assignSecuritiesToGroup: Array<GroupSecurity>;
  cancelSubscription: CancelSubscriptionResult;
  createDemo: Scalars['Boolean'];
  createGroup: Group;
  createSubscription: CreateSubscriptionResult;
  createTransactions: Array<Transaction>;
  deleteDemoTransactions: Array<Transaction>;
  deleteGroup: Group;
  deleteTransaction: Transaction;
  logIn: AuthResult;
  sendSupportEmail: SendSupportEmailResult;
  signUp: AuthResult;
  syncSubscription: Scalars['Boolean'];
  updateGroup: Group;
  updateSetting: User;
  updateStripePaymentMethod: UpdateStripePaymentMethodResult;
  updateSubscription: UpdateSubscriptionResult;
  updateTransaction: Transaction;
  verifyEmail: VerificationResult;
};

export type MutationAssignSecuritiesToGroupArgs = {
  groupId: Scalars['ID'];
  portfolioId: Scalars['ID'];
  securityIds: Array<Scalars['ID']>;
};

export type MutationCreateDemoArgs = {
  portfolioId: Scalars['ID'];
};

export type MutationCreateGroupArgs = {
  name: Scalars['String'];
  parentId: Scalars['ID'];
  portfolioId: Scalars['ID'];
};

export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};

export type MutationCreateTransactionsArgs = {
  input: Array<CreateTransactionInput>;
  portfolioId: Scalars['ID'];
};

export type MutationDeleteDemoTransactionsArgs = {
  portfolioId: Scalars['ID'];
};

export type MutationDeleteGroupArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteTransactionArgs = {
  id: Scalars['ID'];
};

export type MutationLogInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationSendSupportEmailArgs = {
  message: Scalars['String'];
};

export type MutationSignUpArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationUpdateGroupArgs = {
  id: Scalars['ID'];
  input: UpdateGroupInput;
};

export type MutationUpdateSettingArgs = {
  key: Scalars['String'];
  value: Scalars['JSON'];
};

export type MutationUpdateStripePaymentMethodArgs = {
  input: UpdateStripePaymentMethodInput;
};

export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};

export type MutationUpdateTransactionArgs = {
  id: Scalars['ID'];
  input: UpdateTransactionInput;
};

export type MutationVerifyEmailArgs = {
  verificationToken: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  currentUser: User;
  dayPrices: Array<Price>;
  groups: Array<Group>;
  groupsSecurities: Array<GroupSecurity>;
  lastPrices: Array<Price>;
  news: Array<News>;
  portfolios: Array<Portfolio>;
  search: Array<Security>;
  securities: Array<Security>;
  securitiesByMarketAndTickerSymbols: Array<Security>;
  security: Security;
  securityDetails: SecurityDetails;
  splits: Array<Split>;
  stripePaymentMethod: Maybe<StripePaymentMethod>;
  stripePromotionCode: Maybe<StripePromotionCode>;
  transactions: Array<Transaction>;
};

export type QueryDayPricesArgs = {
  securityIds: Array<Scalars['ID']>;
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
};

export type QueryGroupsArgs = {
  portfolioId: Scalars['ID'];
};

export type QueryGroupsSecuritiesArgs = {
  portfolioId: Scalars['ID'];
};

export type QueryLastPricesArgs = {
  securityIds: Array<Scalars['ID']>;
};

export type QueryNewsArgs = {
  securityIds: Array<Scalars['ID']>;
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
};

export type QuerySearchArgs = {
  q: Scalars['String'];
};

export type QuerySecuritiesArgs = {
  ids: Array<Scalars['ID']>;
};

export type QuerySecuritiesByMarketAndTickerSymbolsArgs = {
  market: Scalars['String'];
  tickerSymbols: Array<Scalars['String']>;
};

export type QuerySecurityArgs = {
  id: Scalars['ID'];
  portfolioId: Scalars['ID'];
};

export type QuerySecurityDetailsArgs = {
  id: Scalars['ID'];
};

export type QuerySplitsArgs = {
  securityIds: Array<Scalars['ID']>;
};

export type QueryStripePromotionCodeArgs = {
  code: Scalars['String'];
};

export type QueryTransactionsArgs = {
  portfolioId: Scalars['ID'];
};

export type SettingsFragment = { __typename?: 'User' } & {
  settings: { __typename?: 'UserSettings' } & Pick<
    UserSettings,
    'comparisonSecurityIds' | 'showWelcome'
  >;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = { __typename?: 'Query' } & {
  currentUser: { __typename?: 'User' } & Pick<
    User,
    | 'id'
    | 'avatarUrl'
    | 'email'
    | 'stripeSubscriptionCancelAtPeriodEnd'
    | 'stripeSubscriptionStatus'
    | 'subscriptionNextPaymentAt'
    | 'subscriptionPlan'
    | 'trialEndAt'
  > &
    SettingsFragment;
};

export type DayPricesQueryVariables = Exact<{
  securityIds: Array<Scalars['ID']> | Scalars['ID'];
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
}>;

export type DayPricesQuery = { __typename?: 'Query' } & {
  dayPrices: Array<
    { __typename?: 'Price' } & Pick<Price, 'at' | 'securityId' | 'value'>
  >;
};

export type GroupsQueryVariables = Exact<{
  portfolioId: Scalars['ID'];
}>;

export type GroupsQuery = { __typename?: 'Query' } & {
  groups: Array<
    { __typename?: 'Group' } & Pick<Group, 'id' | 'name' | 'parentId'>
  >;
};

export type GroupsSecuritiesQueryVariables = Exact<{
  portfolioId: Scalars['ID'];
}>;

export type GroupsSecuritiesQuery = { __typename?: 'Query' } & {
  groupsSecurities: Array<
    { __typename?: 'GroupSecurity' } & Pick<
      GroupSecurity,
      'groupId' | 'portfolioId' | 'securityId'
    >
  >;
};

export type LastPricesQueryVariables = Exact<{
  securityIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type LastPricesQuery = { __typename?: 'Query' } & {
  lastPrices: Array<
    { __typename?: 'Price' } & Pick<Price, 'at' | 'securityId' | 'value'>
  >;
};

export type NewsQueryVariables = Exact<{
  securityIds: Array<Scalars['ID']> | Scalars['ID'];
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
}>;

export type NewsQuery = { __typename?: 'Query' } & {
  news: Array<
    { __typename?: 'News' } & Pick<News, 'title' | 'url' | 'source' | 'at'>
  >;
};

export type PortfoliosQueryVariables = Exact<{ [key: string]: never }>;

export type PortfoliosQuery = { __typename?: 'Query' } & {
  portfolios: Array<
    { __typename?: 'Portfolio' } & Pick<Portfolio, 'id'> & {
        rootGroup: { __typename?: 'Group' } & Pick<Group, 'id' | 'name'>;
      }
  >;
};

export type SearchQueryVariables = Exact<{
  q: Scalars['String'];
}>;

export type SearchQuery = { __typename?: 'Query' } & {
  search: Array<
    { __typename?: 'Security' } & Pick<
      Security,
      'id' | 'market' | 'name' | 'tickerSymbol'
    >
  >;
};

export type SecuritiesQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type SecuritiesQuery = { __typename?: 'Query' } & {
  securities: Array<
    { __typename?: 'Security' } & Pick<
      Security,
      'id' | 'market' | 'name' | 'tickerSymbol'
    >
  >;
};

export type SecuritiesByMarketAndTickerSymbolsQueryVariables = Exact<{
  market: Scalars['String'];
  tickerSymbols: Array<Scalars['String']> | Scalars['String'];
}>;

export type SecuritiesByMarketAndTickerSymbolsQuery = {
  __typename?: 'Query';
} & {
  securitiesByMarketAndTickerSymbols: Array<
    { __typename?: 'Security' } & Pick<Security, 'id' | 'name' | 'tickerSymbol'>
  >;
};

export type SecurityQueryVariables = Exact<{
  id: Scalars['ID'];
  portfolioId: Scalars['ID'];
}>;

export type SecurityQuery = { __typename?: 'Query' } & {
  security: { __typename?: 'Security' } & Pick<
    Security,
    'id' | 'market' | 'name' | 'tickerSymbol'
  > & {
      path: Maybe<
        Array<
          { __typename?: 'SecurityPath' } & Pick<SecurityPath, 'id' | 'name'>
        >
      >;
    };
};

export type SecurityDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SecurityDetailsQuery = { __typename?: 'Query' } & {
  securityDetails: { __typename?: 'SecurityDetails' } & Pick<
    SecurityDetails,
    | 'CEO'
    | 'beta'
    | 'country'
    | 'day30AverageVolume'
    | 'description'
    | 'employees'
    | 'marketCap'
    | 'name'
    | 'nextDividendAt'
    | 'nextEarningsAt'
    | 'peRatio'
    | 'ttmEPS'
    | 'website'
    | 'week52High'
    | 'week52Low'
  >;
};

export type SplitsQueryVariables = Exact<{
  securityIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type SplitsQuery = { __typename?: 'Query' } & {
  splits: Array<
    { __typename?: 'Split' } & Pick<
      Split,
      'id' | 'exAt' | 'securityId' | 'fromFactor' | 'toFactor'
    >
  >;
};

export type StripePaymentMethodQueryVariables = Exact<{ [key: string]: never }>;

export type StripePaymentMethodQuery = { __typename?: 'Query' } & {
  stripePaymentMethod: Maybe<
    { __typename?: 'StripePaymentMethod' } & Pick<
      StripePaymentMethod,
      'brand' | 'expMonth' | 'expYear' | 'id' | 'last4'
    >
  >;
};

export type StripePromotionCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;

export type StripePromotionCodeQuery = { __typename?: 'Query' } & {
  stripePromotionCode: Maybe<
    { __typename?: 'StripePromotionCode' } & Pick<
      StripePromotionCode,
      | 'code'
      | 'couponAmountOff'
      | 'couponDuration'
      | 'couponDurationInMonths'
      | 'couponName'
      | 'couponPercentOff'
      | 'id'
    >
  >;
};

export type TransactionsQueryVariables = Exact<{
  portfolioId: Scalars['ID'];
}>;

export type TransactionsQuery = { __typename?: 'Query' } & {
  transactions: Array<
    { __typename?: 'Transaction' } & Pick<
      Transaction,
      | 'id'
      | 'isDemo'
      | 'postedAt'
      | 'quantity'
      | 'price'
      | 'fees'
      | 'securityId'
      | 'type'
      | 'uniqueHash'
    >
  >;
};

export type AssignSecuritiesToGroupMutationVariables = Exact<{
  groupId: Scalars['ID'];
  portfolioId: Scalars['ID'];
  securityIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type AssignSecuritiesToGroupMutation = { __typename?: 'Mutation' } & {
  assignSecuritiesToGroup: Array<
    { __typename?: 'GroupSecurity' } & Pick<
      GroupSecurity,
      'groupId' | 'securityId' | 'portfolioId'
    >
  >;
};

export type CancelSubscriptionMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CancelSubscriptionMutation = { __typename?: 'Mutation' } & {
  cancelSubscription: { __typename?: 'CancelSubscriptionResult' } & Pick<
    CancelSubscriptionResult,
    'error'
  >;
};

export type CreateDemoMutationVariables = Exact<{
  portfolioId: Scalars['ID'];
}>;

export type CreateDemoMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'createDemo'
>;

export type CreateGroupMutationVariables = Exact<{
  name: Scalars['String'];
  parentId: Scalars['ID'];
  portfolioId: Scalars['ID'];
}>;

export type CreateGroupMutation = { __typename?: 'Mutation' } & {
  createGroup: { __typename?: 'Group' } & Pick<
    Group,
    'id' | 'name' | 'portfolioId'
  >;
};

export type CreateSubscriptionMutationVariables = Exact<{
  input: CreateSubscriptionInput;
}>;

export type CreateSubscriptionMutation = { __typename?: 'Mutation' } & {
  createSubscription: { __typename?: 'CreateSubscriptionResult' } & Pick<
    CreateSubscriptionResult,
    'clientSecret' | 'error'
  >;
};

export type CreateTransactionsMutationVariables = Exact<{
  input: Array<CreateTransactionInput> | CreateTransactionInput;
  portfolioId: Scalars['ID'];
}>;

export type CreateTransactionsMutation = { __typename?: 'Mutation' } & {
  createTransactions: Array<
    { __typename?: 'Transaction' } & Pick<Transaction, 'id'>
  >;
};

export type DeleteGroupMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteGroupMutation = { __typename?: 'Mutation' } & {
  deleteGroup: { __typename?: 'Group' } & Pick<Group, 'id'>;
};

export type DeleteDemoTransactionsMutationVariables = Exact<{
  portfolioId: Scalars['ID'];
}>;

export type DeleteDemoTransactionsMutation = { __typename?: 'Mutation' } & {
  deleteDemoTransactions: Array<
    { __typename?: 'Transaction' } & Pick<Transaction, 'id'>
  >;
};

export type DeleteTransactionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteTransactionMutation = { __typename?: 'Mutation' } & {
  deleteTransaction: { __typename?: 'Transaction' } & Pick<Transaction, 'id'>;
};

export type LogInMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type LogInMutation = { __typename?: 'Mutation' } & {
  logIn: { __typename?: 'AuthResult' } & Pick<
    AuthResult,
    'authToken' | 'error'
  >;
};

export type SendSupportEmailMutationVariables = Exact<{
  message: Scalars['String'];
}>;

export type SendSupportEmailMutation = { __typename?: 'Mutation' } & {
  sendSupportEmail: { __typename?: 'SendSupportEmailResult' } & Pick<
    SendSupportEmailResult,
    'error'
  >;
};

export type SignUpMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type SignUpMutation = { __typename?: 'Mutation' } & {
  signUp: { __typename?: 'AuthResult' } & Pick<
    AuthResult,
    'authToken' | 'error'
  >;
};

export type SyncSubscriptionMutationVariables = Exact<{ [key: string]: never }>;

export type SyncSubscriptionMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'syncSubscription'
>;

export type UpdateGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateGroupInput;
}>;

export type UpdateGroupMutation = { __typename?: 'Mutation' } & {
  updateGroup: { __typename?: 'Group' } & Pick<
    Group,
    'id' | 'name' | 'parentId'
  >;
};

export type UpdateSettingMutationVariables = Exact<{
  key: Scalars['String'];
  value: Scalars['JSON'];
}>;

export type UpdateSettingMutation = { __typename?: 'Mutation' } & {
  updateSetting: { __typename?: 'User' } & Pick<User, 'id'> & SettingsFragment;
};

export type UpdateStripePaymentMethodMutationVariables = Exact<{
  input: UpdateStripePaymentMethodInput;
}>;

export type UpdateStripePaymentMethodMutation = { __typename?: 'Mutation' } & {
  updateStripePaymentMethod: {
    __typename?: 'UpdateStripePaymentMethodResult';
  } & Pick<UpdateStripePaymentMethodResult, 'clientSecret' | 'error'>;
};

export type UpdateSubscriptionMutationVariables = Exact<{
  input: UpdateSubscriptionInput;
}>;

export type UpdateSubscriptionMutation = { __typename?: 'Mutation' } & {
  updateSubscription: { __typename?: 'UpdateSubscriptionResult' } & Pick<
    UpdateSubscriptionResult,
    'error'
  >;
};

export type UpdateTransactionMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateTransactionInput;
}>;

export type UpdateTransactionMutation = { __typename?: 'Mutation' } & {
  updateTransaction: { __typename?: 'Transaction' } & Pick<Transaction, 'id'>;
};

export type VerifyEmailMutationVariables = Exact<{
  verificationToken: Scalars['String'];
}>;

export type VerifyEmailMutation = { __typename?: 'Mutation' } & {
  verifyEmail: { __typename?: 'VerificationResult' } & Pick<
    VerificationResult,
    'error'
  >;
};

export const SettingsFragmentDoc = gql`
  fragment settings on User {
    settings {
      comparisonSecurityIds
      showWelcome
    }
  }
`;
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      ...settings
      id
      avatarUrl
      email
      stripeSubscriptionCancelAtPeriodEnd
      stripeSubscriptionStatus
      subscriptionNextPaymentAt
      subscriptionPlan
      trialEndAt
    }
  }
  ${SettingsFragmentDoc}
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    baseOptions,
  );
}
export function useCurrentUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >(CurrentUserDocument, baseOptions);
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>;
export type CurrentUserQueryResult = ApolloReactCommon.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
export const DayPricesDocument = gql`
  query dayPrices(
    $securityIds: [ID!]!
    $startAt: DateTime!
    $endAt: DateTime!
  ) {
    dayPrices(securityIds: $securityIds, startAt: $startAt, endAt: $endAt) {
      at
      securityId
      value
    }
  }
`;

/**
 * __useDayPricesQuery__
 *
 * To run a query within a React component, call `useDayPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDayPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDayPricesQuery({
 *   variables: {
 *      securityIds: // value for 'securityIds'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *   },
 * });
 */
export function useDayPricesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    DayPricesQuery,
    DayPricesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<DayPricesQuery, DayPricesQueryVariables>(
    DayPricesDocument,
    baseOptions,
  );
}
export function useDayPricesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DayPricesQuery,
    DayPricesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<DayPricesQuery, DayPricesQueryVariables>(
    DayPricesDocument,
    baseOptions,
  );
}
export type DayPricesQueryHookResult = ReturnType<typeof useDayPricesQuery>;
export type DayPricesLazyQueryHookResult = ReturnType<
  typeof useDayPricesLazyQuery
>;
export type DayPricesQueryResult = ApolloReactCommon.QueryResult<
  DayPricesQuery,
  DayPricesQueryVariables
>;
export const GroupsDocument = gql`
  query groups($portfolioId: ID!) {
    groups(portfolioId: $portfolioId) {
      id
      name
      parentId
    }
  }
`;

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function useGroupsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GroupsQuery,
    GroupsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<GroupsQuery, GroupsQueryVariables>(
    GroupsDocument,
    baseOptions,
  );
}
export function useGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GroupsQuery,
    GroupsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<GroupsQuery, GroupsQueryVariables>(
    GroupsDocument,
    baseOptions,
  );
}
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>;
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>;
export type GroupsQueryResult = ApolloReactCommon.QueryResult<
  GroupsQuery,
  GroupsQueryVariables
>;
export const GroupsSecuritiesDocument = gql`
  query groupsSecurities($portfolioId: ID!) {
    groupsSecurities(portfolioId: $portfolioId) {
      groupId
      portfolioId
      securityId
    }
  }
`;

/**
 * __useGroupsSecuritiesQuery__
 *
 * To run a query within a React component, call `useGroupsSecuritiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsSecuritiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsSecuritiesQuery({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function useGroupsSecuritiesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GroupsSecuritiesQuery,
    GroupsSecuritiesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    GroupsSecuritiesQuery,
    GroupsSecuritiesQueryVariables
  >(GroupsSecuritiesDocument, baseOptions);
}
export function useGroupsSecuritiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GroupsSecuritiesQuery,
    GroupsSecuritiesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GroupsSecuritiesQuery,
    GroupsSecuritiesQueryVariables
  >(GroupsSecuritiesDocument, baseOptions);
}
export type GroupsSecuritiesQueryHookResult = ReturnType<
  typeof useGroupsSecuritiesQuery
>;
export type GroupsSecuritiesLazyQueryHookResult = ReturnType<
  typeof useGroupsSecuritiesLazyQuery
>;
export type GroupsSecuritiesQueryResult = ApolloReactCommon.QueryResult<
  GroupsSecuritiesQuery,
  GroupsSecuritiesQueryVariables
>;
export const LastPricesDocument = gql`
  query lastPrices($securityIds: [ID!]!) {
    lastPrices(securityIds: $securityIds) {
      at
      securityId
      value
    }
  }
`;

/**
 * __useLastPricesQuery__
 *
 * To run a query within a React component, call `useLastPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastPricesQuery({
 *   variables: {
 *      securityIds: // value for 'securityIds'
 *   },
 * });
 */
export function useLastPricesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    LastPricesQuery,
    LastPricesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<LastPricesQuery, LastPricesQueryVariables>(
    LastPricesDocument,
    baseOptions,
  );
}
export function useLastPricesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LastPricesQuery,
    LastPricesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    LastPricesQuery,
    LastPricesQueryVariables
  >(LastPricesDocument, baseOptions);
}
export type LastPricesQueryHookResult = ReturnType<typeof useLastPricesQuery>;
export type LastPricesLazyQueryHookResult = ReturnType<
  typeof useLastPricesLazyQuery
>;
export type LastPricesQueryResult = ApolloReactCommon.QueryResult<
  LastPricesQuery,
  LastPricesQueryVariables
>;
export const NewsDocument = gql`
  query news($securityIds: [ID!]!, $startAt: DateTime!, $endAt: DateTime!) {
    news(securityIds: $securityIds, startAt: $startAt, endAt: $endAt) {
      title
      url
      source
      at
    }
  }
`;

/**
 * __useNewsQuery__
 *
 * To run a query within a React component, call `useNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsQuery({
 *   variables: {
 *      securityIds: // value for 'securityIds'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *   },
 * });
 */
export function useNewsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<NewsQuery, NewsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<NewsQuery, NewsQueryVariables>(
    NewsDocument,
    baseOptions,
  );
}
export function useNewsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    NewsQuery,
    NewsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<NewsQuery, NewsQueryVariables>(
    NewsDocument,
    baseOptions,
  );
}
export type NewsQueryHookResult = ReturnType<typeof useNewsQuery>;
export type NewsLazyQueryHookResult = ReturnType<typeof useNewsLazyQuery>;
export type NewsQueryResult = ApolloReactCommon.QueryResult<
  NewsQuery,
  NewsQueryVariables
>;
export const PortfoliosDocument = gql`
  query portfolios {
    portfolios {
      id
      rootGroup {
        id
        name
      }
    }
  }
`;

/**
 * __usePortfoliosQuery__
 *
 * To run a query within a React component, call `usePortfoliosQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfoliosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfoliosQuery({
 *   variables: {
 *   },
 * });
 */
export function usePortfoliosQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PortfoliosQuery,
    PortfoliosQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<PortfoliosQuery, PortfoliosQueryVariables>(
    PortfoliosDocument,
    baseOptions,
  );
}
export function usePortfoliosLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PortfoliosQuery,
    PortfoliosQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    PortfoliosQuery,
    PortfoliosQueryVariables
  >(PortfoliosDocument, baseOptions);
}
export type PortfoliosQueryHookResult = ReturnType<typeof usePortfoliosQuery>;
export type PortfoliosLazyQueryHookResult = ReturnType<
  typeof usePortfoliosLazyQuery
>;
export type PortfoliosQueryResult = ApolloReactCommon.QueryResult<
  PortfoliosQuery,
  PortfoliosQueryVariables
>;
export const SearchDocument = gql`
  query search($q: String!) {
    search(q: $q) {
      id
      market
      name
      tickerSymbol
    }
  }
`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useSearchQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SearchQuery,
    SearchQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<SearchQuery, SearchQueryVariables>(
    SearchDocument,
    baseOptions,
  );
}
export function useSearchLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SearchQuery,
    SearchQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<SearchQuery, SearchQueryVariables>(
    SearchDocument,
    baseOptions,
  );
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = ApolloReactCommon.QueryResult<
  SearchQuery,
  SearchQueryVariables
>;
export const SecuritiesDocument = gql`
  query securities($ids: [ID!]!) {
    securities(ids: $ids) {
      id
      market
      name
      tickerSymbol
    }
  }
`;

/**
 * __useSecuritiesQuery__
 *
 * To run a query within a React component, call `useSecuritiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecuritiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecuritiesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSecuritiesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SecuritiesQuery,
    SecuritiesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<SecuritiesQuery, SecuritiesQueryVariables>(
    SecuritiesDocument,
    baseOptions,
  );
}
export function useSecuritiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SecuritiesQuery,
    SecuritiesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    SecuritiesQuery,
    SecuritiesQueryVariables
  >(SecuritiesDocument, baseOptions);
}
export type SecuritiesQueryHookResult = ReturnType<typeof useSecuritiesQuery>;
export type SecuritiesLazyQueryHookResult = ReturnType<
  typeof useSecuritiesLazyQuery
>;
export type SecuritiesQueryResult = ApolloReactCommon.QueryResult<
  SecuritiesQuery,
  SecuritiesQueryVariables
>;
export const SecuritiesByMarketAndTickerSymbolsDocument = gql`
  query securitiesByMarketAndTickerSymbols(
    $market: String!
    $tickerSymbols: [String!]!
  ) {
    securitiesByMarketAndTickerSymbols(
      market: $market
      tickerSymbols: $tickerSymbols
    ) {
      id
      name
      tickerSymbol
    }
  }
`;

/**
 * __useSecuritiesByMarketAndTickerSymbolsQuery__
 *
 * To run a query within a React component, call `useSecuritiesByMarketAndTickerSymbolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecuritiesByMarketAndTickerSymbolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecuritiesByMarketAndTickerSymbolsQuery({
 *   variables: {
 *      market: // value for 'market'
 *      tickerSymbols: // value for 'tickerSymbols'
 *   },
 * });
 */
export function useSecuritiesByMarketAndTickerSymbolsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SecuritiesByMarketAndTickerSymbolsQuery,
    SecuritiesByMarketAndTickerSymbolsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    SecuritiesByMarketAndTickerSymbolsQuery,
    SecuritiesByMarketAndTickerSymbolsQueryVariables
  >(SecuritiesByMarketAndTickerSymbolsDocument, baseOptions);
}
export function useSecuritiesByMarketAndTickerSymbolsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SecuritiesByMarketAndTickerSymbolsQuery,
    SecuritiesByMarketAndTickerSymbolsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    SecuritiesByMarketAndTickerSymbolsQuery,
    SecuritiesByMarketAndTickerSymbolsQueryVariables
  >(SecuritiesByMarketAndTickerSymbolsDocument, baseOptions);
}
export type SecuritiesByMarketAndTickerSymbolsQueryHookResult = ReturnType<
  typeof useSecuritiesByMarketAndTickerSymbolsQuery
>;
export type SecuritiesByMarketAndTickerSymbolsLazyQueryHookResult = ReturnType<
  typeof useSecuritiesByMarketAndTickerSymbolsLazyQuery
>;
export type SecuritiesByMarketAndTickerSymbolsQueryResult = ApolloReactCommon.QueryResult<
  SecuritiesByMarketAndTickerSymbolsQuery,
  SecuritiesByMarketAndTickerSymbolsQueryVariables
>;
export const SecurityDocument = gql`
  query security($id: ID!, $portfolioId: ID!) {
    security(id: $id, portfolioId: $portfolioId) {
      id
      market
      name
      tickerSymbol
      path {
        id
        name
      }
    }
  }
`;

/**
 * __useSecurityQuery__
 *
 * To run a query within a React component, call `useSecurityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecurityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecurityQuery({
 *   variables: {
 *      id: // value for 'id'
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function useSecurityQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SecurityQuery,
    SecurityQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<SecurityQuery, SecurityQueryVariables>(
    SecurityDocument,
    baseOptions,
  );
}
export function useSecurityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SecurityQuery,
    SecurityQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<SecurityQuery, SecurityQueryVariables>(
    SecurityDocument,
    baseOptions,
  );
}
export type SecurityQueryHookResult = ReturnType<typeof useSecurityQuery>;
export type SecurityLazyQueryHookResult = ReturnType<
  typeof useSecurityLazyQuery
>;
export type SecurityQueryResult = ApolloReactCommon.QueryResult<
  SecurityQuery,
  SecurityQueryVariables
>;
export const SecurityDetailsDocument = gql`
  query securityDetails($id: ID!) {
    securityDetails(id: $id) {
      CEO
      beta
      country
      day30AverageVolume
      description
      employees
      marketCap
      name
      nextDividendAt
      nextEarningsAt
      peRatio
      ttmEPS
      website
      week52High
      week52Low
    }
  }
`;

/**
 * __useSecurityDetailsQuery__
 *
 * To run a query within a React component, call `useSecurityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecurityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecurityDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSecurityDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SecurityDetailsQuery,
    SecurityDetailsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    SecurityDetailsQuery,
    SecurityDetailsQueryVariables
  >(SecurityDetailsDocument, baseOptions);
}
export function useSecurityDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SecurityDetailsQuery,
    SecurityDetailsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    SecurityDetailsQuery,
    SecurityDetailsQueryVariables
  >(SecurityDetailsDocument, baseOptions);
}
export type SecurityDetailsQueryHookResult = ReturnType<
  typeof useSecurityDetailsQuery
>;
export type SecurityDetailsLazyQueryHookResult = ReturnType<
  typeof useSecurityDetailsLazyQuery
>;
export type SecurityDetailsQueryResult = ApolloReactCommon.QueryResult<
  SecurityDetailsQuery,
  SecurityDetailsQueryVariables
>;
export const SplitsDocument = gql`
  query splits($securityIds: [ID!]!) {
    splits(securityIds: $securityIds) {
      id
      exAt
      securityId
      fromFactor
      toFactor
    }
  }
`;

/**
 * __useSplitsQuery__
 *
 * To run a query within a React component, call `useSplitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSplitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSplitsQuery({
 *   variables: {
 *      securityIds: // value for 'securityIds'
 *   },
 * });
 */
export function useSplitsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SplitsQuery,
    SplitsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<SplitsQuery, SplitsQueryVariables>(
    SplitsDocument,
    baseOptions,
  );
}
export function useSplitsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SplitsQuery,
    SplitsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<SplitsQuery, SplitsQueryVariables>(
    SplitsDocument,
    baseOptions,
  );
}
export type SplitsQueryHookResult = ReturnType<typeof useSplitsQuery>;
export type SplitsLazyQueryHookResult = ReturnType<typeof useSplitsLazyQuery>;
export type SplitsQueryResult = ApolloReactCommon.QueryResult<
  SplitsQuery,
  SplitsQueryVariables
>;
export const StripePaymentMethodDocument = gql`
  query stripePaymentMethod {
    stripePaymentMethod {
      brand
      expMonth
      expYear
      id
      last4
    }
  }
`;

/**
 * __useStripePaymentMethodQuery__
 *
 * To run a query within a React component, call `useStripePaymentMethodQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripePaymentMethodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripePaymentMethodQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripePaymentMethodQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StripePaymentMethodQuery,
    StripePaymentMethodQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    StripePaymentMethodQuery,
    StripePaymentMethodQueryVariables
  >(StripePaymentMethodDocument, baseOptions);
}
export function useStripePaymentMethodLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StripePaymentMethodQuery,
    StripePaymentMethodQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    StripePaymentMethodQuery,
    StripePaymentMethodQueryVariables
  >(StripePaymentMethodDocument, baseOptions);
}
export type StripePaymentMethodQueryHookResult = ReturnType<
  typeof useStripePaymentMethodQuery
>;
export type StripePaymentMethodLazyQueryHookResult = ReturnType<
  typeof useStripePaymentMethodLazyQuery
>;
export type StripePaymentMethodQueryResult = ApolloReactCommon.QueryResult<
  StripePaymentMethodQuery,
  StripePaymentMethodQueryVariables
>;
export const StripePromotionCodeDocument = gql`
  query stripePromotionCode($code: String!) {
    stripePromotionCode(code: $code) {
      code
      couponAmountOff
      couponDuration
      couponDurationInMonths
      couponName
      couponPercentOff
      id
    }
  }
`;

/**
 * __useStripePromotionCodeQuery__
 *
 * To run a query within a React component, call `useStripePromotionCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripePromotionCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripePromotionCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useStripePromotionCodeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    StripePromotionCodeQuery,
    StripePromotionCodeQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    StripePromotionCodeQuery,
    StripePromotionCodeQueryVariables
  >(StripePromotionCodeDocument, baseOptions);
}
export function useStripePromotionCodeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StripePromotionCodeQuery,
    StripePromotionCodeQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    StripePromotionCodeQuery,
    StripePromotionCodeQueryVariables
  >(StripePromotionCodeDocument, baseOptions);
}
export type StripePromotionCodeQueryHookResult = ReturnType<
  typeof useStripePromotionCodeQuery
>;
export type StripePromotionCodeLazyQueryHookResult = ReturnType<
  typeof useStripePromotionCodeLazyQuery
>;
export type StripePromotionCodeQueryResult = ApolloReactCommon.QueryResult<
  StripePromotionCodeQuery,
  StripePromotionCodeQueryVariables
>;
export const TransactionsDocument = gql`
  query transactions($portfolioId: ID!) {
    transactions(portfolioId: $portfolioId) {
      id
      isDemo
      postedAt
      quantity
      price
      fees
      securityId
      type
      uniqueHash
    }
  }
`;

/**
 * __useTransactionsQuery__
 *
 * To run a query within a React component, call `useTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsQuery({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function useTransactionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    TransactionsQuery,
    TransactionsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    TransactionsQuery,
    TransactionsQueryVariables
  >(TransactionsDocument, baseOptions);
}
export function useTransactionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TransactionsQuery,
    TransactionsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    TransactionsQuery,
    TransactionsQueryVariables
  >(TransactionsDocument, baseOptions);
}
export type TransactionsQueryHookResult = ReturnType<
  typeof useTransactionsQuery
>;
export type TransactionsLazyQueryHookResult = ReturnType<
  typeof useTransactionsLazyQuery
>;
export type TransactionsQueryResult = ApolloReactCommon.QueryResult<
  TransactionsQuery,
  TransactionsQueryVariables
>;
export const AssignSecuritiesToGroupDocument = gql`
  mutation assignSecuritiesToGroup(
    $groupId: ID!
    $portfolioId: ID!
    $securityIds: [ID!]!
  ) {
    assignSecuritiesToGroup(
      groupId: $groupId
      portfolioId: $portfolioId
      securityIds: $securityIds
    ) {
      groupId
      securityId
      portfolioId
    }
  }
`;
export type AssignSecuritiesToGroupMutationFn = ApolloReactCommon.MutationFunction<
  AssignSecuritiesToGroupMutation,
  AssignSecuritiesToGroupMutationVariables
>;

/**
 * __useAssignSecuritiesToGroupMutation__
 *
 * To run a mutation, you first call `useAssignSecuritiesToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignSecuritiesToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignSecuritiesToGroupMutation, { data, loading, error }] = useAssignSecuritiesToGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      portfolioId: // value for 'portfolioId'
 *      securityIds: // value for 'securityIds'
 *   },
 * });
 */
export function useAssignSecuritiesToGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AssignSecuritiesToGroupMutation,
    AssignSecuritiesToGroupMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    AssignSecuritiesToGroupMutation,
    AssignSecuritiesToGroupMutationVariables
  >(AssignSecuritiesToGroupDocument, baseOptions);
}
export type AssignSecuritiesToGroupMutationHookResult = ReturnType<
  typeof useAssignSecuritiesToGroupMutation
>;
export type AssignSecuritiesToGroupMutationResult = ApolloReactCommon.MutationResult<AssignSecuritiesToGroupMutation>;
export type AssignSecuritiesToGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AssignSecuritiesToGroupMutation,
  AssignSecuritiesToGroupMutationVariables
>;
export const CancelSubscriptionDocument = gql`
  mutation cancelSubscription {
    cancelSubscription {
      error
    }
  }
`;
export type CancelSubscriptionMutationFn = ApolloReactCommon.MutationFunction<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >(CancelSubscriptionDocument, baseOptions);
}
export type CancelSubscriptionMutationHookResult = ReturnType<
  typeof useCancelSubscriptionMutation
>;
export type CancelSubscriptionMutationResult = ApolloReactCommon.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>;
export const CreateDemoDocument = gql`
  mutation createDemo($portfolioId: ID!) {
    createDemo(portfolioId: $portfolioId)
  }
`;
export type CreateDemoMutationFn = ApolloReactCommon.MutationFunction<
  CreateDemoMutation,
  CreateDemoMutationVariables
>;

/**
 * __useCreateDemoMutation__
 *
 * To run a mutation, you first call `useCreateDemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDemoMutation, { data, loading, error }] = useCreateDemoMutation({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function useCreateDemoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateDemoMutation,
    CreateDemoMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateDemoMutation,
    CreateDemoMutationVariables
  >(CreateDemoDocument, baseOptions);
}
export type CreateDemoMutationHookResult = ReturnType<
  typeof useCreateDemoMutation
>;
export type CreateDemoMutationResult = ApolloReactCommon.MutationResult<CreateDemoMutation>;
export type CreateDemoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateDemoMutation,
  CreateDemoMutationVariables
>;
export const CreateGroupDocument = gql`
  mutation createGroup($name: String!, $parentId: ID!, $portfolioId: ID!) {
    createGroup(name: $name, parentId: $parentId, portfolioId: $portfolioId) {
      id
      name
      portfolioId
    }
  }
`;
export type CreateGroupMutationFn = ApolloReactCommon.MutationFunction<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *      parentId: // value for 'parentId'
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function useCreateGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateGroupMutation,
    CreateGroupMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateGroupMutation,
    CreateGroupMutationVariables
  >(CreateGroupDocument, baseOptions);
}
export type CreateGroupMutationHookResult = ReturnType<
  typeof useCreateGroupMutation
>;
export type CreateGroupMutationResult = ApolloReactCommon.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;
export const CreateSubscriptionDocument = gql`
  mutation createSubscription($input: CreateSubscriptionInput!) {
    createSubscription(input: $input) {
      clientSecret
      error
    }
  }
`;
export type CreateSubscriptionMutationFn = ApolloReactCommon.MutationFunction<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateSubscriptionMutation,
    CreateSubscriptionMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateSubscriptionMutation,
    CreateSubscriptionMutationVariables
  >(CreateSubscriptionDocument, baseOptions);
}
export type CreateSubscriptionMutationHookResult = ReturnType<
  typeof useCreateSubscriptionMutation
>;
export type CreateSubscriptionMutationResult = ApolloReactCommon.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>;
export const CreateTransactionsDocument = gql`
  mutation createTransactions(
    $input: [CreateTransactionInput!]!
    $portfolioId: ID!
  ) {
    createTransactions(input: $input, portfolioId: $portfolioId) {
      id
    }
  }
`;
export type CreateTransactionsMutationFn = ApolloReactCommon.MutationFunction<
  CreateTransactionsMutation,
  CreateTransactionsMutationVariables
>;

/**
 * __useCreateTransactionsMutation__
 *
 * To run a mutation, you first call `useCreateTransactionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTransactionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTransactionsMutation, { data, loading, error }] = useCreateTransactionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function useCreateTransactionsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTransactionsMutation,
    CreateTransactionsMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    CreateTransactionsMutation,
    CreateTransactionsMutationVariables
  >(CreateTransactionsDocument, baseOptions);
}
export type CreateTransactionsMutationHookResult = ReturnType<
  typeof useCreateTransactionsMutation
>;
export type CreateTransactionsMutationResult = ApolloReactCommon.MutationResult<CreateTransactionsMutation>;
export type CreateTransactionsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateTransactionsMutation,
  CreateTransactionsMutationVariables
>;
export const DeleteGroupDocument = gql`
  mutation deleteGroup($id: ID!) {
    deleteGroup(id: $id) {
      id
    }
  }
`;
export type DeleteGroupMutationFn = ApolloReactCommon.MutationFunction<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteGroupMutation,
    DeleteGroupMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    DeleteGroupMutation,
    DeleteGroupMutationVariables
  >(DeleteGroupDocument, baseOptions);
}
export type DeleteGroupMutationHookResult = ReturnType<
  typeof useDeleteGroupMutation
>;
export type DeleteGroupMutationResult = ApolloReactCommon.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;
export const DeleteDemoTransactionsDocument = gql`
  mutation deleteDemoTransactions($portfolioId: ID!) {
    deleteDemoTransactions(portfolioId: $portfolioId) {
      id
    }
  }
`;
export type DeleteDemoTransactionsMutationFn = ApolloReactCommon.MutationFunction<
  DeleteDemoTransactionsMutation,
  DeleteDemoTransactionsMutationVariables
>;

/**
 * __useDeleteDemoTransactionsMutation__
 *
 * To run a mutation, you first call `useDeleteDemoTransactionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDemoTransactionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDemoTransactionsMutation, { data, loading, error }] = useDeleteDemoTransactionsMutation({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *   },
 * });
 */
export function useDeleteDemoTransactionsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteDemoTransactionsMutation,
    DeleteDemoTransactionsMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    DeleteDemoTransactionsMutation,
    DeleteDemoTransactionsMutationVariables
  >(DeleteDemoTransactionsDocument, baseOptions);
}
export type DeleteDemoTransactionsMutationHookResult = ReturnType<
  typeof useDeleteDemoTransactionsMutation
>;
export type DeleteDemoTransactionsMutationResult = ApolloReactCommon.MutationResult<DeleteDemoTransactionsMutation>;
export type DeleteDemoTransactionsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteDemoTransactionsMutation,
  DeleteDemoTransactionsMutationVariables
>;
export const DeleteTransactionDocument = gql`
  mutation deleteTransaction($id: ID!) {
    deleteTransaction(id: $id) {
      id
    }
  }
`;
export type DeleteTransactionMutationFn = ApolloReactCommon.MutationFunction<
  DeleteTransactionMutation,
  DeleteTransactionMutationVariables
>;

/**
 * __useDeleteTransactionMutation__
 *
 * To run a mutation, you first call `useDeleteTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTransactionMutation, { data, loading, error }] = useDeleteTransactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTransactionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteTransactionMutation,
    DeleteTransactionMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    DeleteTransactionMutation,
    DeleteTransactionMutationVariables
  >(DeleteTransactionDocument, baseOptions);
}
export type DeleteTransactionMutationHookResult = ReturnType<
  typeof useDeleteTransactionMutation
>;
export type DeleteTransactionMutationResult = ApolloReactCommon.MutationResult<DeleteTransactionMutation>;
export type DeleteTransactionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteTransactionMutation,
  DeleteTransactionMutationVariables
>;
export const LogInDocument = gql`
  mutation logIn($email: String!, $password: String!) {
    logIn(email: $email, password: $password) {
      authToken
      error
    }
  }
`;
export type LogInMutationFn = ApolloReactCommon.MutationFunction<
  LogInMutation,
  LogInMutationVariables
>;

/**
 * __useLogInMutation__
 *
 * To run a mutation, you first call `useLogInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logInMutation, { data, loading, error }] = useLogInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLogInMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LogInMutation,
    LogInMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<LogInMutation, LogInMutationVariables>(
    LogInDocument,
    baseOptions,
  );
}
export type LogInMutationHookResult = ReturnType<typeof useLogInMutation>;
export type LogInMutationResult = ApolloReactCommon.MutationResult<LogInMutation>;
export type LogInMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LogInMutation,
  LogInMutationVariables
>;
export const SendSupportEmailDocument = gql`
  mutation sendSupportEmail($message: String!) {
    sendSupportEmail(message: $message) {
      error
    }
  }
`;
export type SendSupportEmailMutationFn = ApolloReactCommon.MutationFunction<
  SendSupportEmailMutation,
  SendSupportEmailMutationVariables
>;

/**
 * __useSendSupportEmailMutation__
 *
 * To run a mutation, you first call `useSendSupportEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSupportEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSupportEmailMutation, { data, loading, error }] = useSendSupportEmailMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendSupportEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendSupportEmailMutation,
    SendSupportEmailMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    SendSupportEmailMutation,
    SendSupportEmailMutationVariables
  >(SendSupportEmailDocument, baseOptions);
}
export type SendSupportEmailMutationHookResult = ReturnType<
  typeof useSendSupportEmailMutation
>;
export type SendSupportEmailMutationResult = ApolloReactCommon.MutationResult<SendSupportEmailMutation>;
export type SendSupportEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendSupportEmailMutation,
  SendSupportEmailMutationVariables
>;
export const SignUpDocument = gql`
  mutation signUp($email: String!, $password: String!) {
    signUp(email: $email, password: $password) {
      authToken
      error
    }
  }
`;
export type SignUpMutationFn = ApolloReactCommon.MutationFunction<
  SignUpMutation,
  SignUpMutationVariables
>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SignUpMutation,
    SignUpMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<SignUpMutation, SignUpMutationVariables>(
    SignUpDocument,
    baseOptions,
  );
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = ApolloReactCommon.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>;
export const SyncSubscriptionDocument = gql`
  mutation syncSubscription {
    syncSubscription
  }
`;
export type SyncSubscriptionMutationFn = ApolloReactCommon.MutationFunction<
  SyncSubscriptionMutation,
  SyncSubscriptionMutationVariables
>;

/**
 * __useSyncSubscriptionMutation__
 *
 * To run a mutation, you first call `useSyncSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncSubscriptionMutation, { data, loading, error }] = useSyncSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SyncSubscriptionMutation,
    SyncSubscriptionMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    SyncSubscriptionMutation,
    SyncSubscriptionMutationVariables
  >(SyncSubscriptionDocument, baseOptions);
}
export type SyncSubscriptionMutationHookResult = ReturnType<
  typeof useSyncSubscriptionMutation
>;
export type SyncSubscriptionMutationResult = ApolloReactCommon.MutationResult<SyncSubscriptionMutation>;
export type SyncSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SyncSubscriptionMutation,
  SyncSubscriptionMutationVariables
>;
export const UpdateGroupDocument = gql`
  mutation updateGroup($id: ID!, $input: UpdateGroupInput!) {
    updateGroup(id: $id, input: $input) {
      id
      name
      parentId
    }
  }
`;
export type UpdateGroupMutationFn = ApolloReactCommon.MutationFunction<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateGroupMutation,
    UpdateGroupMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateGroupMutation,
    UpdateGroupMutationVariables
  >(UpdateGroupDocument, baseOptions);
}
export type UpdateGroupMutationHookResult = ReturnType<
  typeof useUpdateGroupMutation
>;
export type UpdateGroupMutationResult = ApolloReactCommon.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;
export const UpdateSettingDocument = gql`
  mutation updateSetting($key: String!, $value: JSON!) {
    updateSetting(key: $key, value: $value) {
      ...settings
      id
    }
  }
  ${SettingsFragmentDoc}
`;
export type UpdateSettingMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSettingMutation,
  UpdateSettingMutationVariables
>;

/**
 * __useUpdateSettingMutation__
 *
 * To run a mutation, you first call `useUpdateSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettingMutation, { data, loading, error }] = useUpdateSettingMutation({
 *   variables: {
 *      key: // value for 'key'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateSettingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSettingMutation,
    UpdateSettingMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateSettingMutation,
    UpdateSettingMutationVariables
  >(UpdateSettingDocument, baseOptions);
}
export type UpdateSettingMutationHookResult = ReturnType<
  typeof useUpdateSettingMutation
>;
export type UpdateSettingMutationResult = ApolloReactCommon.MutationResult<UpdateSettingMutation>;
export type UpdateSettingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSettingMutation,
  UpdateSettingMutationVariables
>;
export const UpdateStripePaymentMethodDocument = gql`
  mutation updateStripePaymentMethod($input: UpdateStripePaymentMethodInput!) {
    updateStripePaymentMethod(input: $input) {
      clientSecret
      error
    }
  }
`;
export type UpdateStripePaymentMethodMutationFn = ApolloReactCommon.MutationFunction<
  UpdateStripePaymentMethodMutation,
  UpdateStripePaymentMethodMutationVariables
>;

/**
 * __useUpdateStripePaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateStripePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStripePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStripePaymentMethodMutation, { data, loading, error }] = useUpdateStripePaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStripePaymentMethodMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateStripePaymentMethodMutation,
    UpdateStripePaymentMethodMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateStripePaymentMethodMutation,
    UpdateStripePaymentMethodMutationVariables
  >(UpdateStripePaymentMethodDocument, baseOptions);
}
export type UpdateStripePaymentMethodMutationHookResult = ReturnType<
  typeof useUpdateStripePaymentMethodMutation
>;
export type UpdateStripePaymentMethodMutationResult = ApolloReactCommon.MutationResult<UpdateStripePaymentMethodMutation>;
export type UpdateStripePaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateStripePaymentMethodMutation,
  UpdateStripePaymentMethodMutationVariables
>;
export const UpdateSubscriptionDocument = gql`
  mutation updateSubscription($input: UpdateSubscriptionInput!) {
    updateSubscription(input: $input) {
      error
    }
  }
`;
export type UpdateSubscriptionMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSubscriptionMutation,
  UpdateSubscriptionMutationVariables
>;

/**
 * __useUpdateSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionMutation, { data, loading, error }] = useUpdateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSubscriptionMutation,
    UpdateSubscriptionMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateSubscriptionMutation,
    UpdateSubscriptionMutationVariables
  >(UpdateSubscriptionDocument, baseOptions);
}
export type UpdateSubscriptionMutationHookResult = ReturnType<
  typeof useUpdateSubscriptionMutation
>;
export type UpdateSubscriptionMutationResult = ApolloReactCommon.MutationResult<UpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSubscriptionMutation,
  UpdateSubscriptionMutationVariables
>;
export const UpdateTransactionDocument = gql`
  mutation updateTransaction($id: ID!, $input: UpdateTransactionInput!) {
    updateTransaction(id: $id, input: $input) {
      id
    }
  }
`;
export type UpdateTransactionMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTransactionMutation,
  UpdateTransactionMutationVariables
>;

/**
 * __useUpdateTransactionMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionMutation, { data, loading, error }] = useUpdateTransactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTransactionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTransactionMutation,
    UpdateTransactionMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateTransactionMutation,
    UpdateTransactionMutationVariables
  >(UpdateTransactionDocument, baseOptions);
}
export type UpdateTransactionMutationHookResult = ReturnType<
  typeof useUpdateTransactionMutation
>;
export type UpdateTransactionMutationResult = ApolloReactCommon.MutationResult<UpdateTransactionMutation>;
export type UpdateTransactionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTransactionMutation,
  UpdateTransactionMutationVariables
>;
export const VerifyEmailDocument = gql`
  mutation verifyEmail($verificationToken: String!) {
    verifyEmail(verificationToken: $verificationToken) {
      error
    }
  }
`;
export type VerifyEmailMutationFn = ApolloReactCommon.MutationFunction<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      verificationToken: // value for 'verificationToken'
 *   },
 * });
 */
export function useVerifyEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    VerifyEmailMutation,
    VerifyEmailMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    VerifyEmailMutation,
    VerifyEmailMutationVariables
  >(VerifyEmailDocument, baseOptions);
}
export type VerifyEmailMutationHookResult = ReturnType<
  typeof useVerifyEmailMutation
>;
export type VerifyEmailMutationResult = ApolloReactCommon.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;
