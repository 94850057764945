import { Decimal } from 'bigint-decimal/esm/jsbi';
import { startOfDay, subDays } from 'date-fns/fp';
import { useMemo, useRef } from 'react';

import adjustPrices from '@/calc/adjustPrices';
import getPriceMapSeries from '@/calc/getPriceMapSeries';
import { Price, PriceMapSeries } from '@/calc/types';
import {
  Price as GQLPrice,
  useDayPricesQuery,
  useLastPricesQuery,
  useSplitsQuery,
} from '@/graphql';
import { benchmark } from '@/utils';

// 3 for (long) weekends
// 1 for end of day starting dates
// 1 for timezone differences
const START_AT_DAY_MARGIN = 5;

interface UsePriceMapSeriesArgs {
  adjust?: boolean;
  dates: Date[];
  securityIds: string[];
}

interface UsePriceMapSeriesResult {
  data: PriceMapSeries;
  loading: boolean;
}

function convertPrices(
  prices: Pick<GQLPrice, 'at' | 'securityId' | 'value'>[],
): Price[] {
  return benchmark('convertPrices', () =>
    prices.map(({ at, securityId, value }) => ({
      at: new Date(at),
      securityId,
      value: new Decimal(value),
    })),
  );
}

export default function usePriceMapSeries({
  adjust = false,
  dates,
  securityIds,
}: UsePriceMapSeriesArgs): UsePriceMapSeriesResult {
  const prevResult = useRef<Omit<UsePriceMapSeriesResult, 'loading'>>({
    data: [],
  });

  const [startAt] = dates;
  const [endAt] = dates.slice(-1);
  const needsLastPrices = endAt >= startOfDay(new Date());

  const dayPricesQuery = useDayPricesQuery({
    variables: {
      securityIds,
      startAt: subDays(START_AT_DAY_MARGIN, startAt).toISOString(),
      endAt: endAt.toISOString(),
    },
    skip: securityIds.length === 0,
  });

  const lastPricesQuery = useLastPricesQuery({
    variables: {
      securityIds,
    },
    skip: !needsLastPrices || securityIds.length === 0,
  });

  const splitsQuery = useSplitsQuery({
    variables: { securityIds },
    skip: !adjust,
  });

  const dayPricesLoading =
    dayPricesQuery.loading || (adjust && splitsQuery.loading);

  const dayPrices = useMemo(
    () =>
      dayPricesLoading
        ? []
        : adjustPrices({
            prices: convertPrices(dayPricesQuery.data?.dayPrices ?? []),
            splits: (splitsQuery.data?.splits ?? []).map(
              ({ id, exAt, securityId, fromFactor, toFactor }) => ({
                id,
                exAt: new Date(exAt),
                securityId,
                fromFactor: new Decimal(fromFactor),
                toFactor: new Decimal(toFactor),
              }),
            ),
          }),
    [dayPricesQuery, dayPricesLoading, splitsQuery],
  );

  const loading =
    dayPricesLoading ||
    (needsLastPrices && securityIds.length !== 0 && !lastPricesQuery.data);

  const result = useMemo(
    () =>
      loading
        ? prevResult.current
        : {
            data: getPriceMapSeries({
              dates,
              prices: [
                ...dayPrices,
                ...convertPrices(lastPricesQuery.data?.lastPrices ?? []),
              ],
            }),
            loading,
          },
    [dates, dayPrices, lastPricesQuery, loading],
  );

  prevResult.current = result;

  return useMemo(() => ({ ...result, loading }), [loading, result]);
}
