import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import styles from './Scrollable.module.css';

interface ScrollableProps {
  children: React.ReactNode;
  outsideScrollbar?: boolean;
}

export default function Scrollable({
  children,
  outsideScrollbar = false,
}: ScrollableProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [scrollbarSize, setScrollbarSize] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setScrollbarSize(ref.current.offsetWidth - ref.current.clientWidth);
    }
  }, []);

  return (
    <div
      className={classNames(styles.outer, {
        [styles.outsideScrollbar]: outsideScrollbar,
      })}
      ref={ref}
      style={{ ['--scrollbar-size' as string]: `${scrollbarSize}px` }}
    >
      <div className={styles.inner}>{children}</div>
    </div>
  );
}
