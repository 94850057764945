import React from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';

import { useFormId } from '@/components/Form';
import Input, { InputProps } from '@/components/Input';

interface FormInputProps {
  autoFocus?: boolean;
  disabled?: boolean;
  name: string;
  right?: boolean;
  rules?: RegisterOptions;
  type?: InputProps['type'];
  value?: string;
}

export default function FormInput({
  autoFocus = false,
  disabled,
  name,
  right,
  rules,
  type = 'text',
  value,
}: FormInputProps) {
  const { register } = useFormContext();
  const formId = useFormId();
  const id = `${name}_${formId}`;

  return (
    <Input
      autoFocus={autoFocus}
      disabled={disabled}
      id={id}
      name={name}
      ref={register(rules)}
      right={right}
      type={type}
      value={value}
    />
  );
}
