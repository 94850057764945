import { add, DECIMAL_0, equal, multiply } from 'bigint-decimal/esm/jsbi';

import { Group, Holding, HoldingItem, SecuritiesMap } from '@/calc/types';

interface GetHoldingItemsArgs {
  holdings: Holding[];
  securitiesMap: SecuritiesMap;
  subgroups: Group[];
}

export default function getHoldingItems({
  holdings,
  securitiesMap,
  subgroups,
}: GetHoldingItemsArgs): HoldingItem[] {
  const securityToSubgroupMap = subgroups.reduce(
    (acc, g) => ({
      ...acc,
      ...Object.fromEntries(g.allSecurityIds.map((id) => [id, g.id])),
    }),
    {} as { [key: string]: string },
  );

  const initialGroupItemMap = Object.fromEntries(
    subgroups.map((g) => [
      g.id,
      {
        id: g.id,
        type: 'group',
        label: g.name,
        costBasis: DECIMAL_0,
        value: DECIMAL_0,
      },
    ]),
  );

  const { groupItemMap, securityItems } = holdings.reduce(
    (acc, h) => {
      const groupId = securityToSubgroupMap[h.securityId];

      if (groupId) {
        const groupItem = acc.groupItemMap[groupId];

        return {
          groupItemMap: {
            ...acc.groupItemMap,
            [groupId]: {
              ...groupItem,
              costBasis: add(groupItem.costBasis, h.costBasis),
              value: add(groupItem.value, multiply(h.quantity, h.price)),
            },
          },
          securityItems: acc.securityItems,
        };
      }

      if (!equal(h.quantity, DECIMAL_0)) {
        return {
          groupItemMap: acc.groupItemMap,
          securityItems: [
            ...acc.securityItems,
            {
              id: h.securityId,
              type: 'security' as const,
              label: securitiesMap[h.securityId]?.tickerSymbol ?? '',
              quantity: h.quantity,
              price: h.price,
              costBasis: h.costBasis,
              value: multiply(h.quantity, h.price),
            },
          ],
        };
      }

      return acc;
    },
    { groupItemMap: initialGroupItemMap, securityItems: [] } as {
      groupItemMap: { [key: string]: HoldingItem };
      securityItems: HoldingItem[];
    },
  );

  return [...Object.values(groupItemMap), ...securityItems];
}
