import {
  add,
  Decimal,
  DECIMAL_0,
  lessThan,
  multiply,
  subtract,
} from 'bigint-decimal/esm/jsbi';
import React, { useMemo } from 'react';
import { Column, Row } from 'react-table';

import { SecuritiesMap, Transaction } from '@/calc/types';
import Button from '@/components/Button';
import OverflowMenu, { OverflowMenuItem } from '@/components/OverflowMenu';
import Table, { Cell, Header } from '@/components/Table';
import TransactionFormModal from '@/components/TransactionFormModal';
import {
  capitalize,
  formatDate,
  formatPrice,
  formatQuantityDecimal,
} from '@/format';
import useModal from '@/hooks/useModal';
import { securityPath } from '@/paths';

import styles from './TransactionsTable.module.css';

function sortByDecimalColumn(
  rowA: Row<Omit<Transaction, 'id'>>,
  rowB: Row<Omit<Transaction, 'id'>>,
  columnId: string,
) {
  const valueA = rowA.values[columnId] || DECIMAL_0;
  const valueB = rowB.values[columnId] || DECIMAL_0;

  return lessThan(valueA, valueB) ? -1 : 1;
}

interface TransactionMenuProps {
  onDelete: (id: string) => void;
  onEdit: (id: string, values: Omit<Transaction, 'id'>) => void;
  transaction: Transaction;
}

function TransactionMenu({
  onDelete,
  onEdit,
  transaction,
}: TransactionMenuProps) {
  const [showModal] = useModal(({ hide }) => (
    <TransactionFormModal
      editing
      hide={hide}
      onSubmit={(values) => {
        onEdit(transaction.id, values);
      }}
      values={transaction}
    />
  ));

  return (
    <OverflowMenu
      renderButton={({ onClick, ref }) => (
        <Button
          icon="overflowMenu"
          iconSize="16"
          label="More actions"
          onClick={onClick}
          ref={ref}
          size="large"
          variant="lightButton"
        />
      )}
    >
      <OverflowMenuItem
        icon="edit"
        onClick={() => {
          showModal();
        }}
      >
        Edit
      </OverflowMenuItem>
      <OverflowMenuItem
        icon="delete"
        onClick={() => {
          onDelete(transaction.id);
        }}
      >
        Delete
      </OverflowMenuItem>
    </OverflowMenu>
  );
}

interface TransactionsTableProps {
  data: Omit<Transaction, 'id'>[];
  onDelete?: (id: string) => void;
  onEdit?: (id: string, values: Omit<Transaction, 'id'>) => void;
  portfolioId?: string;
  securitiesMap: SecuritiesMap;
}

export default function TransactionsTable({
  data,
  onDelete,
  onEdit,
  portfolioId,
  securitiesMap,
}: TransactionsTableProps) {
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'at',
          Cell: ({ value }) => <Cell>{formatDate(value)}</Cell>,
          Header: ({ column }) => <Header column={column}>Date</Header>,
          sortType: 'datetime',
        },
        {
          accessor: 'type',
          Cell: ({ value }) => <Cell>{capitalize(value)}</Cell>,
          Header: ({ column }) => <Header column={column}>Type</Header>,
        },
        {
          id: 'tickerSymbol',
          accessor: ({ securityId }) => securitiesMap[securityId]?.tickerSymbol,
          Cell: ({
            row,
            value,
          }: {
            row: { original: Transaction };
            value: string;
          }) =>
            portfolioId ? (
              <Button
                className={styles.button}
                href={securityPath({
                  portfolioId,
                  securityId: row.original.securityId,
                })}
                variant="lightButton"
              >
                {value}
              </Button>
            ) : (
              <Cell>{value}</Cell>
            ),
          Header: ({ column }) => <Header column={column}>Symbol</Header>,
        },
        {
          accessor: 'quantity',
          Cell: ({ value }) => (
            <Cell right>{formatQuantityDecimal(value)}</Cell>
          ),
          Header: ({ column }) => (
            <Header column={column} right>
              Quantity
            </Header>
          ),
          sortType: sortByDecimalColumn,
        },
        {
          accessor: 'price',
          Cell: ({ value }) => <Cell right>{formatPrice(value)}</Cell>,
          Header: ({ column }) => (
            <Header column={column} right>
              Price
            </Header>
          ),
          sortType: sortByDecimalColumn,
        },
        {
          accessor: 'fees',
          Cell: ({ value }) => <Cell right>{formatPrice(value)}</Cell>,
          Header: ({ column }) => (
            <Header column={column} right>
              Fees
            </Header>
          ),
          sortType: sortByDecimalColumn,
        },
        {
          id: 'total',
          accessor: ({ type, quantity, price, fees }) =>
            type === 'sell'
              ? subtract(multiply(quantity, price), fees)
              : add(multiply(quantity, price), fees),
          Cell: ({ value }: { value: Decimal }) => (
            <Cell right>{formatPrice(value)}</Cell>
          ),
          Header: ({ column }) => (
            <Header column={column} right>
              Total
            </Header>
          ),
          sortType: sortByDecimalColumn,
        },
        {
          id: 'overflowMenu',
          accessor: 'id',
          Cell: ({ row: { original } }: { row: { original: Transaction } }) => (
            <Cell overflowMenu>
              {onDelete && onEdit && (
                <TransactionMenu
                  onDelete={onDelete}
                  onEdit={onEdit}
                  transaction={original}
                />
              )}
            </Cell>
          ),
          Header: null,
        },
      ] as Column<Omit<Transaction, 'id'>>[],
    [onDelete, onEdit, portfolioId, securitiesMap],
  );

  return (
    <Table
      columns={columns}
      data={data}
      initialState={{
        sortBy: [{ id: 'at', desc: true }],
      }}
    />
  );
}
