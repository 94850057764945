import React from 'react';
import { useForm } from 'react-hook-form';

import Alert from '@/components/Alert';
import AuthLayout from '@/components/AuthLayout';
import Button from '@/components/Button';
import ButtonFooter from '@/components/ButtonFooter';
import Form from '@/components/Form';
import FormInput from '@/components/FormInput';
import FormLabel from '@/components/FormLabel';
import config from '@/config';
import { capitalize } from '@/format';

import styles from './AuthForm.module.css';

function getErrorMessage(error: string, provider?: string | null) {
  const p = capitalize(provider || 'unknown');

  switch (error) {
    case 'badLogin':
      return (
        <>
          The email and password don&apos;t match any account. Did you mistype
          one of them?
        </>
      );
    case 'emailExists':
      return (
        <>
          An account with the given email already exists. Please use a different
          email.
        </>
      );
    case 'noEmail':
      return (
        <>
          We couldn&apos;t get your email address. Is&nbsp;your {p} account
          verified?
        </>
      );
    default:
      return (
        <>We&apos;re having problems logging you in. Please try again later.</>
      );
  }
}

interface AuthFormData {
  email: string;
  password: string;
}

interface AuthFormProps {
  buttonLabel: string;
  error?: string | null;
  footer?: React.ReactNode;
  minPasswordLength?: number;
  onSubmit: (data: AuthFormData) => void;
  provider?: string | null;
}

export default function AuthForm({
  buttonLabel,
  error,
  footer,
  minPasswordLength = 8,
  onSubmit,
  provider,
}: AuthFormProps) {
  const functions = useForm<AuthFormData>();

  return (
    <AuthLayout>
      <Form className={styles.form} functions={functions} onSubmit={onSubmit}>
        {error && (
          <Alert variant="error">{getErrorMessage(error, provider)}</Alert>
        )}

        <Button
          className={styles.authButton}
          icon="google"
          iconSize="16"
          href={`${config.apiUrl}/auth/google`}
          size="large"
          variant="regularButton"
        >
          {buttonLabel} with Google
        </Button>
        <Button
          className={styles.authButton}
          icon="facebook"
          iconSize="16"
          href={`${config.apiUrl}/auth/facebook`}
          size="large"
          variant="regularButton"
        >
          {buttonLabel} with Facebook
        </Button>

        <hr className={styles.divider} />

        <div>
          <FormLabel name="email">Email</FormLabel>
          <FormInput
            name="email"
            rules={{
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid email',
              },
              required: true,
            }}
            type="email"
          />
        </div>

        <div>
          <FormLabel name="password">Password</FormLabel>
          <FormInput
            name="password"
            rules={{
              minLength: {
                value: minPasswordLength,
                message: `Minimum ${minPasswordLength} characters`,
              },
              required: true,
            }}
            type="password"
          />
        </div>

        <ButtonFooter>
          {footer && <div className={styles.footer}>{footer}</div>}

          <Button
            className={styles.button}
            size="large"
            type="submit"
            variant="primaryButton"
          >
            {buttonLabel}
          </Button>
        </ButtonFooter>
      </Form>
    </AuthLayout>
  );
}
