import React from 'react';

import styles from './ToastWrapper.module.css';

interface ToastWrapperProps {
  children: React.ReactNode;
  onClick: () => void;
}

export default function ToastWrapper({ children, onClick }: ToastWrapperProps) {
  return (
    <div className={styles.toastWrapper} role="alert">
      <div onClick={onClick} role="presentation">
        {children}
      </div>
    </div>
  );
}
