import { useCallback, useEffect, useRef } from 'react';

interface UseFileInputArgs {
  accept: string;
  onChange: (files: File[]) => void;
}

export default function useFileInput({
  accept,
  onChange,
}: UseFileInputArgs): () => void {
  const rootEl = document.getElementById('root');
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const input = document.createElement('input');
    input.type = 'file';
    input.style.display = 'none';
    input.accept = accept;
    input.onchange = () => {
      onChange(Array.from(input.files ?? []));
    };

    rootEl?.appendChild(input);
    ref.current = input;

    return () => {
      ref.current = null;
      input.remove();
    };
  }, [accept, onChange, rootEl]);

  const selectFiles = useCallback(() => {
    ref.current?.click();
  }, [ref]);

  return selectFiles;
}
