import { Decimal } from 'bigint-decimal/esm/jsbi';
import { useMemo } from 'react';

import getComparisonSeries from '@/calc/getComparisonSeries';
import { ActivitySeries, ComparisonSeries } from '@/calc/types';
import usePriceMapSeries from '@/hooks/usePriceMapSeries';

interface UseComparisonSeriesArgs {
  dates: Date[];
  initialValue: Decimal;
  securityIds: string[];
  activitySeries?: ActivitySeries;
}

interface UseComparisonSeriesResult {
  data: ComparisonSeries;
  loading: boolean;
}

export default function useComparisonSeries({
  dates,
  initialValue,
  securityIds,
  activitySeries,
}: UseComparisonSeriesArgs): UseComparisonSeriesResult {
  const { data: priceMapSeries, loading } = usePriceMapSeries({
    adjust: true,
    dates,
    securityIds,
  });

  return useMemo(
    () =>
      loading
        ? { data: [], loading }
        : {
            data: getComparisonSeries({
              dates,
              initialValue,
              priceMapSeries,
              securityIds,
              activitySeries,
            }),
            loading,
          },
    [dates, initialValue, loading, priceMapSeries, securityIds, activitySeries],
  );
}
