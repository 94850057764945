import { addDays, differenceInCalendarDays } from 'date-fns/fp';

interface GetDatesArgs {
  startAt: Date;
  endAt: Date;
}

export default function getDates({ startAt, endAt }: GetDatesArgs): Date[] {
  const datesCount = differenceInCalendarDays(startAt, endAt) + 1;
  return [...Array(datesCount)].map((_, i) => addDays(i, startAt));
}
