import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  loadStripe,
  PaymentMethod,
  StripeCardElement,
} from '@stripe/stripe-js';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';

import { ReactComponent as AmexLogoImage } from '@/assets/cards/amex.svg';
import { ReactComponent as CartesBancairesLogoImage } from '@/assets/cards/cartesBancaires.svg';
import { ReactComponent as DinersClubLogoImage } from '@/assets/cards/dinersClub.svg';
import { ReactComponent as DiscoverLogoImage } from '@/assets/cards/discover.svg';
import { ReactComponent as JCBLogoImage } from '@/assets/cards/jcb.svg';
import { ReactComponent as MastercardLogoImage } from '@/assets/cards/mastercard.svg';
import { ReactComponent as UnionPayLogoImage } from '@/assets/cards/unionPay.svg';
import { ReactComponent as VisaLogoImage } from '@/assets/cards/visa.svg';
import { ReactComponent as StripeLogoImage } from '@/assets/stripeLogo.svg';
import Alert from '@/components/Alert';
import Button from '@/components/Button';
import ButtonFooter from '@/components/ButtonFooter';
import Form, { useFormId } from '@/components/Form';
import FormFieldset from '@/components/FormFieldset';
import FormLabel from '@/components/FormLabel';
import FormRadioInput from '@/components/FormRadioInput';
import Input from '@/components/Input';
import Loader from '@/components/Loader';
import Modal, { ModalBaseProps } from '@/components/Modal';
import Toast from '@/components/Toast';
import config from '@/config';
import { formatDateLong } from '@/format';
import {
  StripePromotionCode,
  useCancelSubscriptionMutation,
  useCreateSubscriptionMutation,
  useCurrentUserQuery,
  useStripePaymentMethodQuery,
  useStripePromotionCodeQuery,
  useSyncSubscriptionMutation,
  useUpdateStripePaymentMethodMutation,
  useUpdateSubscriptionMutation,
} from '@/graphql';
import useToast from '@/hooks/useToast';

import styles from './BillingModal.module.css';
import inputStyles from './Input.module.css';

const roundPlanPriceFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

const fractionalPlanPriceFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

function formatPlanPrice(price: number): string {
  if (price % 1 === 0) {
    return roundPlanPriceFormat.format(price);
  }

  return fractionalPlanPriceFormat.format(price);
}

const stripePromise = loadStripe(config.stripe.publishableKey);

const CARD_BRAND_LOGOS: Record<
  string,
  React.ComponentType<React.SVGProps<SVGSVGElement>>
> = {
  amex: AmexLogoImage,
  cartes_bancaires: CartesBancairesLogoImage,
  diners_club: DinersClubLogoImage,
  discover: DiscoverLogoImage,
  jcb: JCBLogoImage,
  mastercard: MastercardLogoImage,
  unionpay: UnionPayLogoImage,
  visa: VisaLogoImage,
};

const CARD_BRAND_NAMES: Record<string, string> = {
  amex: 'American Express',
  cartes_bancaires: 'Cartes Bancaires',
  diners_club: 'Diners Club',
  discover: 'Discover',
  jcb: 'JCB',
  mastercard: 'Mastercard',
  unionpay: 'UnionPay',
  visa: 'Visa',
};

const CARD_ELEMENT_OPTIONS = {
  classes: {
    base: classNames(
      inputStyles.input,
      inputStyles.regularVariant,
      styles.cardElement,
    ),
    focus: inputStyles.isOpen,
  },
  style: {
    base: {
      iconColor: '#776e6e',
      color: '#403b3b',
      fontFamily: "'Lato', 'Helvetica Neue', helvetica, sans-serif",
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#776e6e',
      },
    },
    invalid: {
      iconColor: '#dc3740',
      color: '#b5000b',
    },
  },
};

interface PriceMultipliers {
  annualMultiplier: number;
  monthlyMultiplier: number;
}

const PLANS = [
  {
    value: 'STANDARD_MONTHLY',
    label: 'Monthly',
    monthlyPrice: ({ monthlyMultiplier }: PriceMultipliers) =>
      formatPlanPrice(15 * monthlyMultiplier),
    description: () => 'Cancel anytime',
    priceDescription: ({ monthlyMultiplier }: PriceMultipliers) =>
      `${formatPlanPrice(15 * monthlyMultiplier)}/month`,
  },
  {
    value: 'STANDARD_ANNUAL',
    label: 'Annual',
    monthlyPrice: ({ monthlyMultiplier }: PriceMultipliers) =>
      formatPlanPrice(12 * monthlyMultiplier),
    description: ({ annualMultiplier }: PriceMultipliers) =>
      `Pay ${formatPlanPrice(144 * annualMultiplier)} for a year`,
    priceDescription: ({ annualMultiplier }: PriceMultipliers) =>
      `${formatPlanPrice(144 * annualMultiplier)}/year`,
    savings: '20%',
  },
];

const UNEXPECTED_ERROR_MESSAGE =
  "We're having technical difficulties. Please try again later.";

function usePaymentCard() {
  const stripe = useStripe();

  function handleMissingStripe() {
    // TODO: Log error
    console.error("Can't load Stripe");
    return { error: UNEXPECTED_ERROR_MESSAGE };
  }

  async function confirmCardPayment(
    clientSecret: string,
  ): Promise<{ error?: string }> {
    if (!stripe) {
      return handleMissingStripe();
    }

    const { error } = await stripe.confirmCardPayment(clientSecret);

    return { error: error?.message };
  }

  async function confirmCardSetup(
    clientSecret: string,
  ): Promise<{ error?: string }> {
    if (!stripe) {
      return handleMissingStripe();
    }

    const { error } = await stripe.confirmCardSetup(clientSecret);

    return { error: error?.message };
  }

  async function createPaymentMethod({
    card,
    email,
  }: {
    card: StripeCardElement;
    email: string;
  }): Promise<{ error?: string; paymentMethod?: PaymentMethod }> {
    if (!stripe) {
      return handleMissingStripe();
    }

    const {
      error: createError,
      paymentMethod,
    } = await stripe.createPaymentMethod({
      type: 'card',
      card,
      billing_details: {
        email,
      },
    });

    if (createError) {
      return { error: createError.message || UNEXPECTED_ERROR_MESSAGE };
    }

    if (!paymentMethod) {
      // TODO: Log error
      console.error('Missing paymentMethod');
      return { error: UNEXPECTED_ERROR_MESSAGE };
    }

    return { paymentMethod };
  }

  return { confirmCardPayment, confirmCardSetup, createPaymentMethod };
}

interface PaymentCardLogoProps {
  brand: string;
}

function PaymentCardLogo({ brand }: PaymentCardLogoProps) {
  const Component = CARD_BRAND_LOGOS[brand];
  const name = CARD_BRAND_NAMES[brand] ?? brand;

  return Component ? (
    <Component aria-label={name} className={styles.paymentCard} />
  ) : (
    <>{name}</>
  );
}

interface FormPaymentCardInputProps {
  name: string;
}

function FormPaymentCardInput({ name }: FormPaymentCardInputProps) {
  const { control } = useFormContext();
  const elements = useElements();
  const [error, setError] = useState<string | undefined>(undefined);

  return (
    <Controller
      control={control}
      defaultValue={null}
      name={name}
      rules={{
        required: true,
        validate: () => error || true,
      }}
      onFocus={() => {
        elements?.getElement(CardElement)?.focus();
      }}
      render={({ onBlur, onChange }) => (
        <CardElement
          onBlur={onBlur}
          onChange={(e) => {
            if (!elements) {
              // TODO: Log error
              console.error("Can't load Stripe Elements");
              return;
            }

            setError(e.error?.message);

            if (e.complete || e.error) {
              onChange(elements.getElement(CardElement));
              control.trigger(name);
            } else {
              onChange(null);
            }
          }}
          options={CARD_ELEMENT_OPTIONS}
        />
      )}
    />
  );
}

interface FormPlanInputProps {
  name: string;
  stripePromotionCode?: StripePromotionCode | null;
}

function FormPlanInput({ name, stripePromotionCode }: FormPlanInputProps) {
  const multipliers = { annualMultiplier: 1, monthlyMultiplier: 1 };

  if (stripePromotionCode) {
    const percentOff = stripePromotionCode.couponPercentOff ?? 0;
    const durationInMonths = Math.min(
      stripePromotionCode.couponDurationInMonths ?? 12,
      12,
    );

    multipliers.monthlyMultiplier = (100 - percentOff) / 100;

    multipliers.annualMultiplier =
      (multipliers.monthlyMultiplier * durationInMonths) / 12 +
      (12 - durationInMonths) / 12;
  }

  return (
    <ul className={styles.plans}>
      {PLANS.map(({ value, label, monthlyPrice, description, savings }) => (
        <li key={value}>
          <FormRadioInput name={name} value={value}>
            <div>
              <header>
                <h3>{label}</h3>
                {savings && (
                  <div className={styles.pill}>
                    Save <strong>{savings}</strong>
                  </div>
                )}
              </header>
              <div className={styles.price}>
                <strong>{monthlyPrice(multipliers)}</strong> / month
              </div>
              <p>{description(multipliers)}</p>
            </div>
          </FormRadioInput>
        </li>
      ))}
    </ul>
  );
}

interface CancelSubscriptionProps {
  onCancel: () => void;
  onError: () => void;
  onSubmit: () => void;
  onSuccess: () => void;
  subscriptionNextPaymentDate: Date;
}

function CancelSubscription({
  onCancel,
  onError,
  onSubmit,
  onSuccess,
  subscriptionNextPaymentDate,
}: CancelSubscriptionProps) {
  const functions = useForm<{
    reason:
      | 'FOUND_BETTER_SOLUTION'
      | 'LACKS_FEATURES'
      | 'NOT_ENOUGH_USE'
      | 'OTHER'
      | 'TOO_EXPENSIVE';
  }>();
  const [error, setError] = useState<string | null>(null);
  const [cancelSubscription] = useCancelSubscriptionMutation({
    refetchQueries: ['currentUser'],
  });

  function handleError(message = UNEXPECTED_ERROR_MESSAGE) {
    setError(message);
    onError();
  }

  return (
    <Form
      functions={functions}
      onSubmit={async ({ reason }) => {
        onSubmit();

        // TODO: Log reason

        const { data } = await cancelSubscription();

        if (data?.cancelSubscription.error) {
          handleError(data.cancelSubscription.error);
          return;
        }

        onSuccess();
      }}
    >
      {error && <Alert variant="error">{error}</Alert>}
      {!error && (
        <Alert variant="info">
          Your subscription will remain active until{' '}
          {formatDateLong(subscriptionNextPaymentDate)}. You&nbsp;will not be
          charged anymore.
        </Alert>
      )}

      <FormFieldset name="reason" legend="Why are you canceling?">
        <FormRadioInput
          name="reason"
          rules={{ required: true }}
          value="FOUND_BETTER_SOLUTION"
        >
          I found a better solution
        </FormRadioInput>
        <FormRadioInput
          name="reason"
          rules={{ required: true }}
          value="LACKS_FEATURES"
        >
          Wealthie lacks some features I need
        </FormRadioInput>
        <FormRadioInput
          name="reason"
          rules={{ required: true }}
          value="NOT_ENOUGH_USE"
        >
          I don&apos;t use Wealthie often enough
        </FormRadioInput>
        <FormRadioInput
          name="reason"
          rules={{ required: true }}
          value="TOO_EXPENSIVE"
        >
          Wealthie is too expensive
        </FormRadioInput>
        <FormRadioInput name="reason" rules={{ required: true }} value="OTHER">
          Other
        </FormRadioInput>
      </FormFieldset>

      <p className={styles.cancelQuestion}>
        Are you sure you want to cancel your subscription?
      </p>

      <ButtonFooter>
        <Button onClick={onCancel} size="large">
          No, go back
        </Button>
        <Button danger variant="primaryButton" size="large" type="submit">
          Yes, cancel
        </Button>
      </ButtonFooter>
    </Form>
  );
}

interface ChangeCardProps {
  email: string;
  onCancel: () => void;
  onError: () => void;
  onSubmit: () => void;
  onSuccess: () => void;
  subscriptionNextPaymentDate: Date;
}

function ChangeCard({
  email,
  onCancel,
  onError,
  onSubmit,
  onSuccess,
  subscriptionNextPaymentDate,
}: ChangeCardProps) {
  const functions = useForm<{
    card: StripeCardElement;
  }>();
  const [updateStripePaymentMethod] = useUpdateStripePaymentMethodMutation({
    refetchQueries: ['stripePaymentMethod'],
  });
  const [error, setError] = useState<string | null>(null);
  const { confirmCardSetup, createPaymentMethod } = usePaymentCard();

  function handleError(message = UNEXPECTED_ERROR_MESSAGE) {
    setError(message);
    onError();
  }

  return (
    <Form
      functions={functions}
      onSubmit={async ({ card }) => {
        onSubmit();

        const {
          error: createError,
          paymentMethod,
        } = await createPaymentMethod({ card, email });

        if (!paymentMethod) {
          handleError(createError);
          return;
        }

        const { data } = await updateStripePaymentMethod({
          variables: { input: { stripePaymentMethodId: paymentMethod.id } },
        });

        if (data?.updateStripePaymentMethod.error) {
          handleError(data.updateStripePaymentMethod.error);
          return;
        }

        if (data?.updateStripePaymentMethod.clientSecret) {
          const { error: confirmError } = await confirmCardSetup(
            data.updateStripePaymentMethod.clientSecret,
          );

          if (confirmError) {
            handleError(confirmError);
            return;
          }
        }

        onSuccess();
      }}
    >
      {error && <Alert variant="error">{error}</Alert>}
      {!error && (
        <Alert variant="info">
          You will not be charged now. Your new card will be used for your next
          payment on {formatDateLong(subscriptionNextPaymentDate)}.
        </Alert>
      )}

      <FormLabel name="card">New card</FormLabel>
      <FormPaymentCardInput name="card" />

      <ButtonFooter>
        <p className={styles.poweredBy}>
          Powered by{' '}
          <Button href="https://stripe.com" target="_blank" variant="plain">
            <StripeLogoImage aria-label="Stripe" />
          </Button>
        </p>

        <Button onClick={onCancel} size="large">
          Cancel
        </Button>
        <Button variant="primaryButton" size="large" type="submit">
          Save
        </Button>
      </ButtonFooter>
    </Form>
  );
}

interface ChangePlanProps {
  onCancel: () => void;
  onError: () => void;
  onSubmit: () => void;
  onSuccess: () => void;
  subscriptionPlan: string;
}

function ChangePlan({
  onCancel,
  onError,
  onSubmit,
  onSuccess,
  subscriptionPlan,
}: ChangePlanProps) {
  const functions = useForm<{
    plan: string;
  }>({ defaultValues: { plan: subscriptionPlan } });
  const [error, setError] = useState<string | null>(null);
  const [updateSubscription] = useUpdateSubscriptionMutation({
    refetchQueries: ['currentUser'],
  });

  function handleError(message = UNEXPECTED_ERROR_MESSAGE) {
    setError(message);
    onError();
  }

  return (
    <Form
      functions={functions}
      onSubmit={async ({ plan }) => {
        onSubmit();

        const { data } = await updateSubscription({
          variables: { input: { subscriptionPlan: plan } },
        });

        if (data?.updateSubscription.error) {
          handleError(data.updateSubscription.error);
          return;
        }

        onSuccess();
      }}
    >
      {error && <Alert variant="error">{error}</Alert>}

      <FormLabel>New plan</FormLabel>
      <FormPlanInput name="plan" />

      <ButtonFooter>
        <Button onClick={onCancel} size="large">
          Cancel
        </Button>
        <Button variant="primaryButton" size="large" type="submit">
          Save
        </Button>
      </ButtonFooter>
    </Form>
  );
}

interface FormPromotionCodeInputProps {
  name: string;
}

function FormPromotionCodeInput({ name }: FormPromotionCodeInputProps) {
  const { control } = useFormContext();
  const [isAdding, setIsAdding] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [promotionCode, setPromotionCode] = useState<string | null>(
    window.localStorage.getItem('promoCode'),
  );
  const stripePromotionCodeQuery = useStripePromotionCodeQuery({
    variables: { code: promotionCode ?? '' },
    skip: !promotionCode,
  });
  const stripePromotionCode =
    stripePromotionCodeQuery.data?.stripePromotionCode ?? null;
  const formId = useFormId();
  const id = `${name}_${formId}`;

  useEffect(() => {
    if (stripePromotionCode) {
      setIsAdding(false);
      setError(null);
      control.setValue(name, stripePromotionCode);
    } else if (promotionCode && !stripePromotionCodeQuery.loading) {
      setError('Invalid code');
    }
  }, [
    control,
    name,
    promotionCode,
    stripePromotionCode,
    stripePromotionCodeQuery.loading,
  ]);

  useEffect(() => {
    control.trigger(name);
  }, [control, error, name]);

  function updateStripePromotionCode(code: string) {
    if (code !== '') {
      setPromotionCode(code);
    }
  }

  return (
    <Controller
      control={control}
      defaultValue={null}
      name={name}
      rules={{
        validate: () => error || true,
      }}
      render={({ onBlur, onChange }) => (
        <>
          {!isAdding && !stripePromotionCode && (
            <div className={styles.promoCode}>
              <Button
                onClick={() => {
                  setIsAdding(true);
                }}
                variant="link"
              >
                Add promo code
              </Button>
            </div>
          )}
          {isAdding && !stripePromotionCode && (
            <>
              <FormLabel name={name}>Promo code</FormLabel>
              <div className={styles.promoCode}>
                <Input
                  autoFocus
                  name={name}
                  id={id}
                  onBlur={(e) => {
                    onBlur();
                    updateStripePromotionCode(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                      e.preventDefault();
                      updateStripePromotionCode(e.currentTarget.value);
                    }
                  }}
                />
                <Button size="large">Apply</Button>
              </div>
            </>
          )}
          {stripePromotionCode && (
            <>
              <FormLabel>Promo code</FormLabel>
              <div className={styles.promoCode}>
                <ul className={styles.sublist}>
                  <li>{stripePromotionCode.code}</li>
                  <li>{stripePromotionCode.couponName}</li>
                </ul>
                <Button
                  onClick={() => {
                    setError(null);
                    setPromotionCode(null);
                    onChange(null);
                  }}
                  variant="link"
                >
                  Remove
                </Button>
              </div>
            </>
          )}
        </>
      )}
    />
  );
}

interface NewSubscriptionProps {
  email: string;
  onError: () => void;
  onSubmit: () => void;
  onSuccess: () => void;
  subscriptionNextPaymentDate: Date | null;
  trialEndDate: Date | null;
}

function NewSubscription({
  email,
  onError,
  onSubmit,
  onSuccess,
  subscriptionNextPaymentDate,
  trialEndDate,
}: NewSubscriptionProps) {
  const functions = useForm<{
    card: StripeCardElement;
    plan: string;
    stripePromotionCode: StripePromotionCode | null;
  }>({ defaultValues: { plan: 'STANDARD_MONTHLY' } });
  const [error, setError] = useState<string | null>(null);
  const [createSubscription] = useCreateSubscriptionMutation();
  const [syncSubscription] = useSyncSubscriptionMutation({
    refetchQueries: ['currentUser', 'stripePaymentMethod'],
  });
  const { confirmCardPayment, createPaymentMethod } = usePaymentCard();
  const stripePromotionCode = functions.watch('stripePromotionCode');

  function handleError(message = UNEXPECTED_ERROR_MESSAGE) {
    setError(message);
    onError();
  }

  return (
    <Form
      functions={functions}
      onSubmit={async ({ card, plan }) => {
        onSubmit();

        const {
          error: createError,
          paymentMethod,
        } = await createPaymentMethod({ card, email });

        if (!paymentMethod) {
          handleError(createError);
          return;
        }

        const { data } = await createSubscription({
          variables: {
            input: {
              stripePaymentMethodId: paymentMethod.id,
              stripePromotionCodeId: stripePromotionCode?.id,
              subscriptionPlan: plan,
            },
          },
        });

        if (data?.createSubscription.error) {
          handleError(data.createSubscription.error);
          return;
        }

        if (data?.createSubscription.clientSecret) {
          const { error: confirmError } = await confirmCardPayment(
            data.createSubscription.clientSecret,
          );

          if (confirmError) {
            handleError(confirmError);
            return;
          }
        }

        await syncSubscription();

        onSuccess();
      }}
    >
      {error && <Alert variant="error">{error}</Alert>}
      {!error && subscriptionNextPaymentDate && (
        <Alert variant="info">
          Your previously canceled subscription is still active until{' '}
          {formatDateLong(subscriptionNextPaymentDate)}. We will apply the
          remaining amount towards your new subscription.
        </Alert>
      )}
      {!error &&
        !subscriptionNextPaymentDate &&
        trialEndDate &&
        trialEndDate <= new Date() && (
          <Alert variant="info">
            Your trial has ended. You&apos;ll be able to add more transactions
            after you subscribe.
          </Alert>
        )}
      {!error &&
        !subscriptionNextPaymentDate &&
        trialEndDate &&
        trialEndDate > new Date() && (
          <Alert variant="info">
            Your trial ends on {formatDateLong(trialEndDate)}. Subscribe before
            then to continue using Wealthie.
          </Alert>
        )}

      <FormLabel>Plan</FormLabel>
      <FormPlanInput name="plan" stripePromotionCode={stripePromotionCode} />

      <FormPromotionCodeInput name="stripePromotionCode" />

      <FormLabel name="card">Card</FormLabel>
      <FormPaymentCardInput name="card" />

      <ButtonFooter>
        <p className={styles.poweredBy}>
          Secure payment powered by{' '}
          <Button href="https://stripe.com" target="_blank" variant="plain">
            <StripeLogoImage aria-label="Stripe" />
          </Button>
        </p>

        <Button variant="primaryButton" size="large" type="submit">
          Subscribe
        </Button>
      </ButtonFooter>
    </Form>
  );
}

interface ExistingSubscriptionProps {
  onCancelSubscription: () => void;
  onChangeCard: () => void;
  onChangePlan: () => void;
  subscriptionNextPaymentDate: Date;
  subscriptionPlan: string;
}

function ExistingSubscription({
  onCancelSubscription,
  onChangeCard,
  onChangePlan,
  subscriptionNextPaymentDate,
  subscriptionPlan,
}: ExistingSubscriptionProps) {
  const stripePaymentMethodQuery = useStripePaymentMethodQuery();

  if (!stripePaymentMethodQuery.data) {
    return null;
  }

  const plan = PLANS.find((p) => p.value === subscriptionPlan);
  const { stripePaymentMethod } = stripePaymentMethodQuery.data;

  if (!stripePaymentMethod || !plan) {
    // TODO: Log error
    return <p>Error, please contact support.</p>;
  }

  return (
    <>
      <dl className={styles.list}>
        <div>
          <dt>Plan</dt>
          <dd>
            <ul className={styles.sublist}>
              <li>{plan.label}</li>
            </ul>
            <Button onClick={onChangePlan} variant="link">
              Change
            </Button>
          </dd>
        </div>
        <div>
          <dt>Card</dt>
          <dd>
            <ul className={styles.sublist}>
              <li>
                <PaymentCardLogo brand={stripePaymentMethod.brand} />
                ···· {stripePaymentMethod.last4}
              </li>
              <li>
                Expires{' '}
                {stripePaymentMethod.expMonth.toString().padStart(2, '0')}/
                {stripePaymentMethod.expYear}
              </li>
            </ul>
            <Button onClick={onChangeCard} variant="link">
              Change
            </Button>
          </dd>
        </div>
      </dl>

      <div className={styles.footer}>
        <p>
          Your next payment will be on{' '}
          {formatDateLong(subscriptionNextPaymentDate)}.
        </p>
        <Button danger onClick={onCancelSubscription} variant="link">
          Cancel subscription
        </Button>
      </div>
    </>
  );
}

export default function BillingModal({
  hide,
  setCanHide,
}: ModalBaseProps & { setCanHide: (value: boolean) => void }) {
  const currentUserQuery = useCurrentUserQuery();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<
    'cancelingSubscription' | 'changingCard' | 'changingPlan' | 'default'
  >('default');

  if (!currentUserQuery.data) {
    return null;
  }

  const {
    email,
    stripeSubscriptionCancelAtPeriodEnd,
    stripeSubscriptionStatus,
    subscriptionNextPaymentAt,
    subscriptionPlan,
    trialEndAt,
  } = currentUserQuery.data.currentUser;

  const subscriptionNextPaymentDate = subscriptionNextPaymentAt
    ? new Date(subscriptionNextPaymentAt)
    : null;

  const trialEndDate = new Date(trialEndAt);

  function activateSafeLoading() {
    setLoading(true);
    setCanHide(false);
  }

  function deactivateSafeLoading() {
    setLoading(false);
    setCanHide(true);
  }

  return (
    <Modal
      className={classNames(styles.modal, { [styles.loading]: loading })}
      hide={hide}
      title="Billing"
    >
      {loading && (
        <div className={styles.loader}>
          <Loader />
          <p>Please wait...</p>
        </div>
      )}
      {state === 'cancelingSubscription' && subscriptionNextPaymentDate && (
        <CancelSubscription
          onCancel={() => {
            setState('default');
          }}
          onError={deactivateSafeLoading}
          onSubmit={activateSafeLoading}
          onSuccess={() => {
            window.analytics.track('subscription canceled');
            deactivateSafeLoading();
            showToast(() => (
              <Toast>Your subscription has been canceled.</Toast>
            ));
            hide();
          }}
          subscriptionNextPaymentDate={subscriptionNextPaymentDate}
        />
      )}
      {state === 'changingCard' && subscriptionNextPaymentDate && (
        <Elements stripe={stripePromise}>
          <ChangeCard
            email={email}
            onCancel={() => {
              setState('default');
            }}
            onError={deactivateSafeLoading}
            onSubmit={activateSafeLoading}
            onSuccess={() => {
              deactivateSafeLoading();
              setState('default');
            }}
            subscriptionNextPaymentDate={subscriptionNextPaymentDate}
          />
        </Elements>
      )}
      {state === 'changingPlan' && subscriptionPlan && (
        <ChangePlan
          onCancel={() => {
            setState('default');
          }}
          onError={deactivateSafeLoading}
          onSubmit={activateSafeLoading}
          onSuccess={() => {
            deactivateSafeLoading();
            setState('default');
          }}
          subscriptionPlan={subscriptionPlan}
        />
      )}
      {state === 'default' &&
        stripeSubscriptionStatus === 'active' &&
        !stripeSubscriptionCancelAtPeriodEnd &&
        subscriptionNextPaymentDate &&
        subscriptionPlan && (
          <ExistingSubscription
            onCancelSubscription={() => {
              setState('cancelingSubscription');
            }}
            onChangeCard={() => {
              setState('changingCard');
            }}
            onChangePlan={() => {
              setState('changingPlan');
            }}
            subscriptionNextPaymentDate={subscriptionNextPaymentDate}
            subscriptionPlan={subscriptionPlan}
          />
        )}
      {state === 'default' &&
        (stripeSubscriptionStatus !== 'active' ||
          (stripeSubscriptionCancelAtPeriodEnd &&
            subscriptionNextPaymentDate)) && (
          <Elements stripe={stripePromise}>
            <NewSubscription
              email={email}
              onError={deactivateSafeLoading}
              onSubmit={activateSafeLoading}
              onSuccess={() => {
                window.analytics.track('subscription started');
                deactivateSafeLoading();
                showToast(() => (
                  <Toast>You&apos;re now subscribed. Thank you!</Toast>
                ));
                hide();
              }}
              subscriptionNextPaymentDate={
                stripeSubscriptionStatus === 'active'
                  ? subscriptionNextPaymentDate
                  : null
              }
              trialEndDate={trialEndDate}
            />
          </Elements>
        )}
    </Modal>
  );
}
