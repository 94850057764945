import { Group } from '@/calc/types';
import {
  Group as GQLGroup,
  GroupSecurity as GQLGroupSecurity,
} from '@/graphql';

function getAllSecurityIds(group: Group): string[] {
  return [
    ...group.securityIds,
    ...group.subgroups.reduce<string[]>(
      (acc, sg) => [...acc, ...getAllSecurityIds(sg)],
      [],
    ),
  ];
}

function getPath(group: Group | null): Group['path'] {
  if (group === null) {
    return [];
  }

  return [...getPath(group.parent), { id: group.id, name: group.name }];
}

interface GetGroupsArgs {
  groups: Pick<GQLGroup, 'id' | 'name' | 'parentId'>[];
  groupsSecurities: Pick<GQLGroupSecurity, 'securityId' | 'groupId'>[];
}

export default function getGroups({
  groups: gqlGroups,
  groupsSecurities,
}: GetGroupsArgs): Record<string, Group> {
  const groupsSecuritiesMap = groupsSecurities.reduce<Record<string, string[]>>(
    (acc, { groupId, securityId }) => ({
      ...acc,
      [groupId]: [...(acc[groupId] || []), securityId],
    }),
    {},
  );

  const groups = gqlGroups.reduce<Record<string, Group>>(
    (acc, gqlGroup) => ({
      ...acc,
      [gqlGroup.id]: {
        ...gqlGroup,
        allSecurityIds: [],
        parent: null,
        path: [],
        // See comment below for allSecurityIds.
        securityIds: (groupsSecuritiesMap[gqlGroup.id] || []).sort(),
        subgroups: [],
      },
    }),
    {},
  );

  Object.values(groups).forEach((group) => {
    if (group.parentId) {
      groups[group.id].parent = groups[group.parentId];
      groups[group.parentId].subgroups.push(group);
    }
  });

  Object.values(groups).forEach((group) => {
    // Sort so that `useQuery` doesn't fire unnecessary GraphQL queries (it
    // uses deep equality to compare `variables` so if two arrays have the same
    // elements in the same order, cache will be used).
    groups[group.id].allSecurityIds = getAllSecurityIds(group).sort();
    groups[group.id].path = getPath(group);
  });

  return groups;
}
