import React from 'react';

import styles from './RadioInput.module.css';

export interface RadioInputProps {
  children: React.ReactNode;
  id?: string;
  name?: string;
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

export default React.forwardRef(
  (
    { children, id, name, onBlur, onChange, value }: RadioInputProps,
    ref?: React.Ref<HTMLInputElement>,
  ) => (
    <label className={styles.wrapper}>
      <div className={styles.radioInput}>
        <input
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          ref={ref}
          type="radio"
          value={value}
        />
        <div />
      </div>
      {children}
    </label>
  ),
);
