import { DECIMAL_0, toNumber } from 'bigint-decimal/esm/jsbi';
import { subDays } from 'date-fns/fp';
import { useMemo, useRef } from 'react';

import {
  getComparisonValue,
  getImpreciseComparisonValue,
} from '@/calc/getComparisonSeries';
import mergeSeries from '@/calc/mergeSeries';
import {
  PriceChartSeries,
  PriceChartSeriesItem,
  SeriesDescriptor,
} from '@/calc/types';
import { NUM_COLORS } from '@/components/Chart';
import { useSecuritiesQuery } from '@/graphql';
import useComparisonSeries from '@/hooks/useComparisonSeries';

import useActivitySeries from './useActivitySeries';
import usePriceMapSeries from './usePriceMapSeries';

interface UsePriceChartSeriesArgs {
  comparisonSecurityIds: string[];
  dates: Date[];
  portfolioId: string;
  securityId: string;
}

interface UsePriceChartSeriesResult {
  data: PriceChartSeries;
  loading: boolean;
  seriesDescriptors: SeriesDescriptor<PriceChartSeriesItem>[];
}

export default function usePriceChartSeries({
  comparisonSecurityIds,
  dates,
  portfolioId,
  securityId,
}: UsePriceChartSeriesArgs): UsePriceChartSeriesResult {
  const prevResult = useRef<Omit<UsePriceChartSeriesResult, 'loading'>>({
    data: [],
    seriesDescriptors: [],
  });

  const securityIds = useMemo(() => [securityId], [securityId]);

  // Add one day at the beginning that will contain all the past activity.
  // We eliminate it at the end just before returning.
  const augmentedDates = useMemo(() => [subDays(1, dates[0]), ...dates], [
    dates,
  ]);

  const augmentedActivitySeries = useActivitySeries({
    dates: augmentedDates,
    portfolioId,
    securityIds,
  });

  const activitySeries = useMemo(() => augmentedActivitySeries.data.slice(1), [
    augmentedActivitySeries,
  ]);

  const priceMapSeries = usePriceMapSeries({
    adjust: true,
    dates,
    securityIds,
  });

  const priceSeries = useMemo(
    () =>
      priceMapSeries.data.map(({ at, priceMap }) => ({
        at,
        price: priceMap[securityId] || DECIMAL_0,
      })),
    [priceMapSeries, securityId],
  );

  const comparisonSeries = useComparisonSeries({
    dates,
    initialValue: useMemo(
      () => (priceSeries.length !== 0 ? priceSeries[0].price : DECIMAL_0),
      [priceSeries],
    ),
    securityIds: comparisonSecurityIds,
  });

  const comparisonSecuritiesQuery = useSecuritiesQuery({
    variables: { ids: comparisonSecurityIds },
  });

  const comparisonSecurities = useMemo(
    () =>
      comparisonSecuritiesQuery.data?.securities
        .slice()
        .sort(
          (a, b) =>
            comparisonSecurityIds.indexOf(a.id) -
            comparisonSecurityIds.indexOf(b.id),
        ) || [],
    [comparisonSecuritiesQuery.data, comparisonSecurityIds],
  );

  const loading =
    [augmentedActivitySeries, comparisonSecuritiesQuery, priceMapSeries].some(
      (s) => s.loading,
    ) ||
    (comparisonSecurityIds.length !== 0 && comparisonSeries.loading);

  const result = useMemo(
    () =>
      loading
        ? prevResult.current
        : {
            data: mergeSeries(
              mergeSeries(priceSeries, activitySeries),
              comparisonSeries.data,
            ),
            seriesDescriptors: [
              {
                type: 'main' as const,
                label: 'Value',
                getImpreciseValue: (d) => toNumber(d.price),
                getValue: (d) => d.price,
              },
              ...comparisonSecurities.map(
                ({ tickerSymbol }, i) =>
                  ({
                    color: i % NUM_COLORS,
                    type: 'comparison' as const,
                    label: tickerSymbol,
                    getImpreciseValue: (d) => getImpreciseComparisonValue(d, i),
                    getValue: (d) => getComparisonValue(d, i),
                  } as SeriesDescriptor<PriceChartSeriesItem>),
              ),
            ],
          },
    [
      activitySeries,
      comparisonSecurities,
      comparisonSeries.data,
      loading,
      priceSeries,
    ],
  );

  prevResult.current = result;

  return useMemo(() => ({ ...result, loading }), [loading, result]);
}
