import { Decimal, DECIMAL_0, equal, lessThan } from 'bigint-decimal/esm/jsbi';
import classNames from 'classnames';
import React from 'react';

import Icon from '@/components/Icon';
import { formatPercentage } from '@/format';

import styles from './Header.module.css';

interface HeaderHighlightProps {
  changePercentage: Decimal;
  icon?: boolean;
  sideNote?: string;
}

export function HeaderHighlight({
  changePercentage,
  icon = false,
  sideNote,
}: HeaderHighlightProps) {
  return (
    <div
      className={classNames(styles.headerHighlight, {
        [styles.positiveHighlight]: lessThan(DECIMAL_0, changePercentage),
        [styles.negativeHighlight]: lessThan(changePercentage, DECIMAL_0),
      })}
    >
      {!equal(changePercentage, DECIMAL_0) && icon && (
        <Icon
          className={styles.icon}
          name={lessThan(changePercentage, DECIMAL_0) ? 'arrowDown' : 'arrowUp'}
          size="16"
        />
      )}
      <strong>{formatPercentage(changePercentage)}</strong>
      {sideNote}
    </div>
  );
}

interface HeaderItemProps {
  variant?: 'regular' | 'quantity' | 'negative' | 'positive';
  label: string;
  children: React.ReactNode;
}

export function HeaderItem({
  variant = 'regular',
  label,
  children,
}: HeaderItemProps) {
  return (
    <div
      aria-label={label}
      className={classNames(styles.headerItem, {
        [styles.negativeVariant]: variant === 'negative',
        [styles.positiveVariant]: variant === 'positive',
        [styles.quantityVariant]: variant === 'quantity',
      })}
    >
      {children}
    </div>
  );
}

interface HeaderGroupProps {
  children: React.ReactNode;
}

export function HeaderGroup({ children }: HeaderGroupProps) {
  return <div className={styles.headerGroup}>{children}</div>;
}

interface HeadingProps {
  children: React.ReactNode;
  icon: 'group' | 'home' | 'security';
}

export function Heading({ children, icon }: HeadingProps) {
  return (
    <h1 className={styles.heading}>
      <Icon
        className={classNames(styles.icon, styles[icon])}
        name={icon}
        size="32"
      />
      {children}
    </h1>
  );
}

interface HeaderProps {
  children: React.ReactNode;
}

export default function Header({ children }: HeaderProps) {
  return <header className={styles.header}>{children}</header>;
}
