import { scaleLinear } from '@visx/scale';
import { add, Decimal, toNumber } from 'bigint-decimal/esm/jsbi';
import React from 'react';

import PercentageBar from '@/components/PercentageBar';
import { formatPercentage, formatPrice } from '@/format';

import styles from './AllocationChart.module.css';

const MAX_ITEMS = 6;
const collator = new Intl.Collator('en-US', { numeric: true });

export interface AllocationDatum extends Object {
  id: string;
  label: string;
  value: Decimal;
}

interface AllocationChartProps {
  data: AllocationDatum[];
}

export default function AllocationChart({ data }: AllocationChartProps) {
  const total = data.reduce(
    (acc, { value }) => acc + parseFloat(value.toString()),
    0,
  );
  const percentScale = scaleLinear({
    range: [0, 100],
    domain: [0, total],
  });

  const bars = data
    .slice(0)
    .sort((a, b) => collator.compare(b.value.toString(), a.value.toString()))
    .reduce((acc, d, i) => {
      const [previous] = acc.slice(-1);

      if (acc.length >= MAX_ITEMS) {
        return [
          ...acc.slice(0, -1),
          {
            i: MAX_ITEMS - 1,
            id: 'other',
            label: `${data.length - MAX_ITEMS} other`,
            value: add(previous.value, d.value),
          },
        ];
      }

      return [...acc, { ...d, i }];
    }, [] as (AllocationDatum & { i: number })[])
    .sort((a, b) => collator.compare(a.label, b.label));

  return (
    <ul className={styles.container}>
      {bars.map(({ i, id, label, value }) => {
        const percentage = percentScale(toNumber(value)) ?? 0;

        return (
          <li key={id} style={{ ['--item-index' as string]: i }}>
            <span>{label}</span>
            <span className={styles.right}>{formatPrice(value)}</span>
            <span className={styles.right}>{formatPercentage(percentage)}</span>
            <PercentageBar
              className={styles.percentageBar}
              value={percentage}
            />
          </li>
        );
      })}
    </ul>
  );
}
