import {
  add,
  Decimal,
  DECIMAL_0,
  divide,
  equal,
  multiply,
  subtract,
} from 'bigint-decimal/esm/jsbi';

import getHoldingItems from '@/calc/getHoldingItems';
import { getChangePercentage } from '@/calc/getHoldingsSeries';
import { Group, HoldingsSeriesItem, SecuritiesMap } from '@/calc/types';

const DECIMAL_100 = new Decimal('100');

interface GetHoldingsTableDataArgs {
  holdingsSeries: [HoldingsSeriesItem?, HoldingsSeriesItem?];
  securitiesMap: SecuritiesMap;
  subgroups: Group[];
}

export default function getHoldingsTableData({
  holdingsSeries,
  securitiesMap,
  subgroups,
}: GetHoldingsTableDataArgs) {
  const [prevDatum, lastDatum] = holdingsSeries;

  if (!prevDatum || !lastDatum) {
    return [];
  }

  const last = getHoldingItems({
    holdings: lastDatum.holdings,
    securitiesMap,
    subgroups,
  });
  const prev = getHoldingItems({
    holdings: prevDatum.holdings,
    securitiesMap,
    subgroups,
  });
  const total = last.reduce((acc, hi) => add(acc, hi.value), DECIMAL_0);

  return last.map((hi) => {
    const prevHi = prev.find((phi) => phi.id === hi.id);

    return {
      ...hi,
      allocation: equal(total, DECIMAL_0)
        ? DECIMAL_0
        : multiply(divide(hi.value, total), DECIMAL_100),
      dayChangePercentage:
        prevHi && getChangePercentage(prevHi.value, hi.value),
      returnPercentage: getChangePercentage(hi.costBasis, hi.value),
      returnValue: subtract(hi.value, hi.costBasis),
    };
  });
}
