import { DECIMAL_0, equal } from 'bigint-decimal/esm/jsbi';
import classNames from 'classnames';
import Mousetrap from 'mousetrap';
import React, { useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { ReactComponent as LogoImage } from '@/assets/logo.svg';
import { Group, SecuritiesMap, Security } from '@/calc/types';
import AddTransactionModal from '@/components/AddTransactionModal';
import Button from '@/components/Button';
import dndTypes, { Item } from '@/components/dndTypes';
import GroupFormModal from '@/components/GroupFormModal';
import Icon from '@/components/Icon';
import ImportCSVModal from '@/components/ImportCSVModal';
import Link from '@/components/Link';
import OverflowMenu, { OverflowMenuItem } from '@/components/OverflowMenu';
import { PointerTarget } from '@/components/PointerDot';
import Scrollable from '@/components/Scrollable';
import SecurityInput from '@/components/SecurityInput';
import { usePortfoliosQuery } from '@/graphql';
import useGroup from '@/hooks/useGroup';
import useLatestHoldings from '@/hooks/useLatestHoldings';
import useModal from '@/hooks/useModal';
import usePathParams from '@/hooks/usePathParams';
import useSecuritiesMap from '@/hooks/useSecuritiesMap';
import useSubscription from '@/hooks/useSubscription';
import { groupPath, securityPath } from '@/paths';

import styles from './Sidebar.module.css';

const collator = new Intl.Collator('en-US', { numeric: true });

function blurActiveElement() {
  if (document.activeElement instanceof HTMLElement) {
    document.activeElement.blur();
  }
}

interface SecurityItemProps {
  indent: number;
  security: Security;
}

function SecurityItem({ indent, security }: SecurityItemProps) {
  const { portfolioId } = usePathParams('portfolioId');
  const { invokeOrSubscribe } = useSubscription();

  const [{ isDndActive }, drag] = useDrag({
    type: dndTypes.SECURITY,
    item: { id: security.id, type: dndTypes.SECURITY },
    collect: (monitor) => ({
      isDndActive: Object.values(dndTypes).includes(
        monitor.getItemType() as symbol,
      ),
    }),
    end: blurActiveElement,
  });

  const [showModal] = useModal(({ hide }) => (
    <AddTransactionModal
      hide={hide}
      portfolioId={portfolioId}
      values={{ securityId: security.id }}
    />
  ));

  const to = securityPath({ portfolioId, securityId: security.id });
  const isMatch = !!useRouteMatch({ path: to });

  return (
    <li
      className={classNames(styles.linkWrapper, {
        [styles.active]: isMatch,
        [styles.dndActive]: isDndActive,
        [styles.inactive]: isDndActive,
      })}
      ref={drag}
      style={{ ['--indent' as string]: indent }}
    >
      <div className={styles.dragIcon}>
        <Icon name="drag" size="16" />
      </div>
      <Button
        active={isMatch}
        className={styles.link}
        icon="security"
        href={securityPath({ portfolioId, securityId: security.id })}
        variant="plain"
      >
        {security.tickerSymbol}
      </Button>
      <Button
        className={styles.button}
        icon="plus"
        label="Add transaction"
        onClick={invokeOrSubscribe(showModal)}
        variant="plain"
      />
    </li>
  );
}

interface UseGroupDropArgs {
  assignSecuritiesToGroup: ReturnType<
    typeof useGroup
  >['assignSecuritiesToGroup'];
  group: Group;
  updateGroup: ReturnType<typeof useGroup>['updateGroup'];
}

function useGroupDrop({
  assignSecuritiesToGroup,
  group,
  updateGroup,
}: UseGroupDropArgs) {
  return useDrop({
    accept: [dndTypes.GROUP, dndTypes.SECURITY],
    canDrop: (item: Item) => !group.path.map((p) => p.id).includes(item.id),
    drop: (item) => {
      switch (item.type) {
        case dndTypes.SECURITY:
          assignSecuritiesToGroup([item.id], group.id);
          break;

        case dndTypes.GROUP:
          updateGroup(item.id, { parentId: group.id });
          break;

        default: // nothing
      }
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
  });
}

interface GroupItemProps {
  group: Group;
  indent: number;
  securitiesMap: SecuritiesMap;
}

const GroupSubitems = React.memo(
  ({ group, indent, securitiesMap }: GroupItemProps) => {
    if (group.subgroups.length === 0 && group.securityIds.length === 0) {
      return null;
    }

    const securities = group.securityIds
      .map((id) => securitiesMap[id])
      .filter((s): s is Security => s !== undefined)
      .sort((a, b) => collator.compare(a.tickerSymbol, b.tickerSymbol));

    const subgroups = group.subgroups
      .slice()
      .sort((a, b) => collator.compare(a.name, b.name));

    return (
      <ul>
        {subgroups.map((g) => (
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          <GroupItem
            group={g}
            indent={indent + 1}
            key={g.id}
            securitiesMap={securitiesMap}
          />
        ))}
        {securities.map((s) => (
          <SecurityItem indent={indent + 1} key={s.id} security={s} />
        ))}
      </ul>
    );
  },
);

function GroupItem({ group, indent, securitiesMap }: GroupItemProps) {
  const { portfolioId } = usePathParams('portfolioId');

  const {
    assignSecuritiesToGroup,
    createGroup,
    deleteGroup,
    updateGroup,
  } = useGroup({
    id: group.id,
    portfolioId,
  });

  const dndRef = useRef<HTMLDivElement>(null);

  const [{ isDndActive }, drag] = useDrag({
    type: dndTypes.GROUP,
    item: { id: group.id, type: dndTypes.GROUP },
    collect: (monitor) => ({
      isDndActive: Object.values(dndTypes).includes(
        monitor.getItemType() as symbol,
      ),
    }),
    end: blurActiveElement,
  });

  const [{ canDrop, isOver }, drop] = useGroupDrop({
    assignSecuritiesToGroup,
    group,
    updateGroup,
  });

  drag(drop(dndRef));

  const [showCreateModal] = useModal(({ hide }) => (
    <GroupFormModal
      hide={hide}
      onSubmit={({ name }) => {
        createGroup(name);
      }}
    />
  ));

  const [showRenameModal] = useModal(({ hide }) => (
    <GroupFormModal
      editing
      hide={hide}
      onSubmit={({ name }) => {
        updateGroup(group.id, { name });
      }}
      values={{ name: group.name }}
    />
  ));

  const to = groupPath({ portfolioId, groupId: group.id });
  const isMatch = !!useRouteMatch({ path: to });

  return (
    <li>
      <div
        className={classNames(styles.linkWrapper, {
          [styles.active]: isMatch,
          [styles.dndActive]: isDndActive,
          [styles.dndDrop]: canDrop && isOver,
          [styles.inactive]: isDndActive && !canDrop,
        })}
        onDrop={(e) => {
          // Workaround for:
          // https://github.com/react-dnd/react-dnd/issues/3179
          e.preventDefault();
        }}
        ref={dndRef}
        style={{ ['--indent' as string]: indent }}
      >
        <div className={styles.dragIcon}>
          <Icon name="drag" size="16" />
        </div>
        <Button
          active={isMatch}
          className={styles.link}
          icon="group"
          href={to}
          variant="plain"
        >
          {group.name}
        </Button>
        <OverflowMenu
          renderButton={({ onClick, ref }) => (
            <Button
              className={styles.button}
              icon="overflowMenu"
              label="More actions"
              onClick={onClick}
              variant="plain"
              ref={ref}
            />
          )}
        >
          <OverflowMenuItem icon="newGroup" onClick={showCreateModal}>
            New group
          </OverflowMenuItem>
          <OverflowMenuItem icon="edit" onClick={showRenameModal}>
            Rename
          </OverflowMenuItem>
          <OverflowMenuItem
            icon="delete"
            onClick={() => {
              deleteGroup(group.id);
            }}
          >
            Delete
          </OverflowMenuItem>
        </OverflowMenu>
      </div>
      <GroupSubitems
        group={group}
        indent={indent}
        securitiesMap={securitiesMap}
      />
    </li>
  );
}

interface PortfolioItemProps {
  group: Group;
  loading: boolean;
  securitiesMap: SecuritiesMap;
}

function PortfolioItem({ group, loading, securitiesMap }: PortfolioItemProps) {
  const history = useHistory();
  const { portfolioId } = usePathParams('portfolioId');

  const { assignSecuritiesToGroup, createGroup, updateGroup } = useGroup({
    id: group.id,
    portfolioId,
  });

  const [{ canDrop, isOver }, drop] = useGroupDrop({
    assignSecuritiesToGroup,
    group,
    updateGroup,
  });

  const [showCreateModal] = useModal(({ hide }) => (
    <GroupFormModal
      hide={hide}
      onSubmit={({ name }) => {
        createGroup(name);
      }}
    />
  ));

  const { invokeOrSubscribe } = useSubscription();

  const [showAddTransactionModal] = useModal(({ hide }) => (
    <AddTransactionModal hide={hide} portfolioId={portfolioId} />
  ));

  const [showImportCSVModal] = useModal(({ hide }) => (
    <ImportCSVModal hide={hide} portfolioId={portfolioId} />
  ));

  const to = groupPath({ portfolioId, groupId: group.id });
  const isMatch = !!useRouteMatch({ path: to });
  const hasSecurities = Object.keys(securitiesMap).length > 0;

  useEffect(() => {
    if (!hasSecurities && !isMatch && !loading) {
      history.replace(to);
    }
  }, [history, hasSecurities, isMatch, loading, to]);

  return (
    <li>
      <div
        className={classNames(styles.linkWrapper, styles.root, {
          [styles.active]: isMatch,
          [styles.dndDrop]: canDrop && isOver,
        })}
        onDrop={(e) => {
          // Workaround for:
          // https://github.com/react-dnd/react-dnd/issues/3179
          e.preventDefault();
        }}
        ref={drop}
      >
        <Button
          active={isMatch}
          className={styles.link}
          icon="home"
          href={to}
          variant="plain"
        >
          {group.name}
        </Button>
        <OverflowMenu
          renderButton={({ onClick, ref }) => (
            <PointerTarget name="plusButton">
              <Button
                className={styles.button}
                icon="plus"
                iconBold
                label="Add"
                onClick={onClick}
                ref={ref}
                variant="plain"
              />
            </PointerTarget>
          )}
        >
          <OverflowMenuItem
            icon="plus"
            onClick={invokeOrSubscribe(showAddTransactionModal)}
          >
            Add transaction
          </OverflowMenuItem>
          <OverflowMenuItem
            icon="import"
            onClick={invokeOrSubscribe(showImportCSVModal)}
          >
            Import CSV
          </OverflowMenuItem>
          {hasSecurities && (
            <OverflowMenuItem icon="newGroup" onClick={showCreateModal}>
              New group
            </OverflowMenuItem>
          )}
        </OverflowMenu>
      </div>
      {hasSecurities && (
        <GroupSubitems group={group} indent={0} securitiesMap={securitiesMap} />
      )}
    </li>
  );
}

export default function Sidebar() {
  const history = useHistory();
  const securityInputRef = useRef<HTMLInputElement>(null);
  const { portfolioId } = usePathParams('portfolioId');
  const { data } = usePortfoliosQuery();

  const group = useGroup({
    id: data?.portfolios[0].rootGroup.id || '',
    portfolioId,
    skip: !data,
  });

  const latestHoldings = useLatestHoldings({
    portfolioId,
    securityIds: group.group?.allSecurityIds || [],
  });

  const securityIds =
    latestHoldings.data[1]?.holdings.reduce(
      (acc, { securityId, quantity }) =>
        !equal(quantity, DECIMAL_0) ? [...acc, securityId] : acc,
      [] as string[],
    ) ?? [];

  const securitiesMap = useSecuritiesMap({ securityIds });

  useEffect(() => {
    Mousetrap.bind('/', () => {
      if (securityInputRef.current) {
        securityInputRef.current.focus();
      }
      return false;
    });

    return () => {
      Mousetrap.unbind('/');
    };
  }, [securityInputRef]);

  return (
    <div className={styles.sidebar}>
      <div className={styles.topBar}>
        <Link className={styles.logo} to="/">
          <LogoImage className={styles.logoImage} aria-label="Home" />
        </Link>
        <SecurityInput
          onChange={(id) => {
            history.push(securityPath({ portfolioId, securityId: id }));
          }}
          openOnFocus
          ref={securityInputRef}
          rounded
        />
      </div>
      {group.group && data?.portfolios.length === 1 && (
        <Scrollable>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
          <ul
            className={styles.groups}
            onClick={() => {
              // TODO: Remove when we figure out better keyboard nav
              if (
                document.activeElement instanceof HTMLElement &&
                document.activeElement.tagName === 'A'
              ) {
                document.activeElement.blur();
              }
            }}
          >
            <PortfolioItem
              group={group.group}
              loading={[group, latestHoldings, securitiesMap].some(
                (x) => x.loading,
              )}
              securitiesMap={securitiesMap.data}
            />
          </ul>
        </Scrollable>
      )}
    </div>
  );
}
