import React, { createContext, useContext } from 'react';
import { FormProvider, SubmitHandler, UseFormMethods } from 'react-hook-form';

const FormIdContext = createContext<string | null>(null);

function randomId() {
  const arr = new Uint8Array(4);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, (n) => `0${n.toString(16)}`.slice(-2)).join('');
}

export function getErrorMessage(type: string) {
  switch (type) {
    case 'required':
      return <>Required</>;
    default:
      return <>Invalid format</>;
  }
}

export function useFormId() {
  return useContext(FormIdContext);
}

interface FormProps<T> {
  children: React.ReactNode;
  className?: string;
  functions: UseFormMethods<T>;
  onSubmit: (data: T) => void;
}

export default function Form<T>({
  children,
  className,
  functions,
  onSubmit,
}: FormProps<T>) {
  const { handleSubmit } = functions;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...functions}>
      <FormIdContext.Provider value={randomId()}>
        <form
          className={className}
          noValidate
          // TODO: https://github.com/react-hook-form/react-hook-form/issues/2466
          onSubmit={handleSubmit(onSubmit as SubmitHandler<T>)}
        >
          {children}
        </form>
      </FormIdContext.Provider>
    </FormProvider>
  );
}
