export default function getComparisonCharacters(labels: string[]) {
  return labels.reduce((acc, l) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const letter of l.toUpperCase()) {
      if (!Object.values(acc).includes(letter)) {
        return {
          ...acc,
          [l]: letter,
        };
      }
    }

    return acc;
  }, {} as { [key: string]: string });
}
