import React from 'react';

import Button from '@/components/Button';
import Content from '@/components/Content';
import IllustrationPanel from '@/components/IllustrationPanel';
import Toolbar from '@/components/Toolbar';
import TransactionFormModal from '@/components/TransactionFormModal';
import TransactionsTable from '@/components/TransactionsTable';
import { useSecuritiesQuery } from '@/graphql';
import useModal from '@/hooks/useModal';
import usePathParams from '@/hooks/usePathParams';
import useSubscription from '@/hooks/useSubscription';
import useTransactions from '@/hooks/useTransactions';

export default function SecurityTransactions() {
  const { portfolioId, securityId } = usePathParams(
    'portfolioId',
    'securityId',
  );

  const {
    createTransactions,
    deleteTransaction,
    loading,
    transactions,
    updateTransaction,
  } = useTransactions({
    portfolioId,
    securityIds: [securityId],
  });

  const securitiesQuery = useSecuritiesQuery({
    variables: { ids: [securityId] },
  });
  const security = securitiesQuery.data?.securities[0];

  const { invokeOrSubscribe } = useSubscription();

  const [showModal] = useModal(({ hide }) => (
    <TransactionFormModal
      hide={hide}
      onSubmit={(values) => {
        createTransactions([values]);
      }}
      values={{ securityId }}
    />
  ));

  return (
    <>
      {transactions.length === 0 && !loading && (
        <Content extraSpacing>
          <IllustrationPanel illustration="noData">
            <p>
              You haven&apos;t added any transactions for{' '}
              {security?.tickerSymbol}.
            </p>

            <Button
              icon="plus"
              onClick={invokeOrSubscribe(showModal)}
              size="large"
            >
              Add transaction
            </Button>
          </IllustrationPanel>
        </Content>
      )}

      {transactions.length !== 0 && (
        <Content>
          <Toolbar>
            <Button
              icon="plus"
              onClick={invokeOrSubscribe(showModal)}
              variant="lightButton"
            >
              Add transaction
            </Button>
          </Toolbar>

          <TransactionsTable
            data={transactions}
            onDelete={deleteTransaction}
            onEdit={updateTransaction}
            portfolioId={portfolioId}
            securitiesMap={security ? { [securityId]: security } : {}}
          />
        </Content>
      )}
    </>
  );
}
