import { add, Decimal, multiply, subtract } from 'bigint-decimal/esm/jsbi';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Transaction } from '@/calc/types';
import Button from '@/components/Button';
import ButtonFooter from '@/components/ButtonFooter';
import Form from '@/components/Form';
import FormDateInput from '@/components/FormDateInput';
import FormDecimalInput from '@/components/FormDecimalInput';
import FormInput from '@/components/FormInput';
import FormLabel from '@/components/FormLabel';
import FormSecurityInput from '@/components/FormSecurityInput';
import FormSelectInput from '@/components/FormSelectInput';
import Modal from '@/components/Modal';
import Switch from '@/components/Switch';
import { formatPrice } from '@/format';

import styles from './TransactionFormModal.module.css';

interface TransactionFormModalProps {
  addMore?: boolean;
  editing?: boolean;
  hide: () => void;
  onSubmit: (values: Omit<Transaction, 'id'>) => void;
  values?: Partial<Omit<Transaction, 'id'>>;
}

export default function TransactionFormModal({
  addMore: initialAddMore = false,
  editing,
  hide,
  onSubmit,
  values,
}: TransactionFormModalProps) {
  const functions = useForm<Omit<Transaction, 'id'>>({
    defaultValues: {
      at: new Date(),
      type: 'buy',
      securityId: '',
      quantity: new Decimal('1'),
      price: new Decimal('0'),
      fees: new Decimal('0'),
      ...values,
    },
  });
  const { watch, reset } = functions;
  const { fees, quantity, price, type } = watch([
    'fees',
    'quantity',
    'price',
    'type',
  ]);
  const [addMore, setAddMore] = useState(initialAddMore);

  return (
    <Modal
      className={styles.modal}
      hide={hide}
      title={editing ? 'Edit transaction' : 'Add transaction'}
    >
      <Form<Omit<Transaction, 'id'>>
        onSubmit={(newValues) => {
          onSubmit(newValues);

          if (addMore) {
            reset();
          } else {
            hide();
          }
        }}
        functions={functions}
      >
        <div className={styles.security}>
          <FormLabel name="securityId">Security</FormLabel>
          <FormSecurityInput
            autoFocus
            name="securityId"
            rules={{ required: true }}
            size="large"
          />
        </div>

        <div className={styles.dateType}>
          <div>
            <FormLabel name="at">Date</FormLabel>
            <FormDateInput name="at" rules={{ required: true }} />
          </div>

          <div>
            <FormLabel name="type">Type</FormLabel>
            <FormSelectInput
              items={[
                { value: 'buy', label: 'Buy' },
                { value: 'sell', label: 'Sell' },
              ]}
              name="type"
              rules={{ required: true }}
            />
          </div>
        </div>

        <div className={styles.values}>
          <div>
            <FormLabel name="quantity">Quantity</FormLabel>
            <FormDecimalInput
              min="0"
              name="quantity"
              rules={{ required: true }}
            />
          </div>

          <div>
            <FormLabel name="price">Price</FormLabel>
            <FormDecimalInput min="0" name="price" rules={{ required: true }} />
          </div>

          <div>
            <FormLabel name="fees">Fees</FormLabel>
            <FormDecimalInput
              min="0"
              name="fees"
              rules={{ required: true }}
              step="0.01"
            />
          </div>

          <div>
            <FormLabel name="total" right>
              Total
            </FormLabel>
            <FormInput
              disabled
              name="total"
              right
              value={formatPrice(
                type === 'sell'
                  ? subtract(multiply(quantity, price), fees)
                  : add(multiply(quantity, price), fees),
              )}
            />
          </div>
        </div>

        <ButtonFooter>
          {!editing && (
            <div className={styles.addMore}>
              <Switch
                label="Add more than one"
                onChange={(value) => {
                  setAddMore(value);
                }}
                size="large"
                value={addMore}
              />
            </div>
          )}

          <Button variant="primaryButton" size="large" type="submit">
            {editing ? 'Save' : 'Add'}
          </Button>
        </ButtonFooter>
      </Form>
    </Modal>
  );
}
