import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import ToastWrapper from '@/components/ToastWrapper';

const HIDE_DELAY = 5000; // ms

type RenderToastFn = () => React.ReactNode;

interface ToastContextValue {
  showToast: (renderToast: RenderToastFn) => void;
}

const ToastContext = createContext<ToastContextValue | null>(null);

interface ToastProviderProps {
  children: React.ReactNode;
}

export function ToastProvider({ children }: ToastProviderProps) {
  const [toast, setToast] = useState<React.ReactNode | null>(null);

  const hideToast = useCallback(() => {
    setToast(null);
  }, []);

  const showToast = useCallback(
    (renderToast: RenderToastFn) => {
      setToast(renderToast());

      setTimeout(hideToast, HIDE_DELAY);
    },
    [hideToast],
  );

  const value = useMemo(() => ({ showToast }), [showToast]);

  return (
    <ToastContext.Provider value={value}>
      {children}
      {toast && <ToastWrapper onClick={hideToast}>{toast}</ToastWrapper>}
    </ToastContext.Provider>
  );
}

export default function useToast(): ToastContextValue {
  const context = useContext(ToastContext);

  if (context === null) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}
