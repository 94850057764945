/* eslint-disable react/jsx-props-no-spreading */
import {
  Decimal,
  DECIMAL_0,
  equal,
  lessThan,
  toNumber,
} from 'bigint-decimal/esm/jsbi';
import classNames from 'classnames';
import React from 'react';
import { TableOptions, useSortBy, useTable } from 'react-table';

import Icon from '@/components/Icon';
import { formatPercentage, formatPrice } from '@/format';

import styles from './Table.module.css';

interface CellProps {
  children: React.ReactNode;
  highlight?: null | 'muted' | 'negative' | 'positive' | 'regular';
  overflowMenu?: boolean;
  right?: boolean;
}

export function Cell({
  children,
  highlight = null,
  overflowMenu = false,
  right = false,
}: CellProps) {
  return (
    <div
      className={classNames({
        [styles.overflowMenuWrapper]: overflowMenu,
      })}
    >
      <div
        className={classNames(styles.cell, {
          [styles.mutedHighlight]: highlight === 'muted',
          [styles.negativeHighlight]: highlight === 'negative',
          [styles.positiveHighlight]: highlight === 'positive',
          [styles.regularHighlight]: highlight === 'regular',
          [styles.right]: right,
          [styles.rowHover]: overflowMenu,
        })}
      >
        {children}
      </div>
    </div>
  );
}

interface ChangeCellProps {
  changePercentage: Decimal;
}

export function ChangeCell({ changePercentage }: ChangeCellProps) {
  return (
    <div
      className={classNames(styles.cell, styles.changeCell, {
        [styles.positiveHighlight]: lessThan(DECIMAL_0, changePercentage),
        [styles.negativeHighlight]: lessThan(changePercentage, DECIMAL_0),
        [styles.mutedHighlight]: equal(changePercentage, DECIMAL_0),
      })}
    >
      {!equal(changePercentage, DECIMAL_0) && (
        <Icon
          name={lessThan(DECIMAL_0, changePercentage) ? 'arrowUp' : 'arrowDown'}
          size="16"
        />
      )}
      <strong>{formatPercentage(changePercentage)}</strong>
    </div>
  );
}

interface HeaderProps {
  children: React.ReactNode;
  column: {
    isSorted: boolean;
    isSortedDesc?: boolean;
  };
  right?: boolean;
}

interface ValueAllocationCellProps {
  allocation: Decimal;
  value: Decimal;
}

export function ValueAllocationCell({
  allocation,
  value,
}: ValueAllocationCellProps) {
  return (
    <div className={classNames(styles.cell, styles.valueAllocationCell)}>
      <div className={styles.labelWrapper}>
        <span className={styles.allocation}>
          {formatPercentage(allocation)}
        </span>{' '}
        <span>{formatPrice(value)}</span>
      </div>
      <div className={styles.percentageBar}>
        <div style={{ width: `${toNumber(allocation)}%` }} />
      </div>
    </div>
  );
}

export function Header({ children, column, right = false }: HeaderProps) {
  return (
    <div
      className={classNames(styles.header, {
        [styles.right]: right,
        [styles.sorted]: column.isSorted,
      })}
    >
      {children}{' '}
      {column.isSorted && (
        <Icon
          bold
          name={column.isSortedDesc ? 'chevronDown' : 'chevronUp'}
          size="16"
        />
      )}
    </div>
  );
}

interface RowHoverProps {
  children: React.ReactNode;
}

export function RowHover({ children }: RowHoverProps) {
  return <div className={styles.rowHover}>{children}</div>;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export default function Table<T extends object>(props: TableOptions<T>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable<T>(
    {
      ...props,
      autoResetSortBy: false,
      disableSortRemove: true,
    },
    useSortBy,
  );

  return (
    <table {...getTableProps()} className={styles.table}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              if (!column.Header) {
                return null;
              }

              return (
                <th
                  {...column.getHeaderProps([
                    column.headerProps || {},
                    column.getSortByToggleProps({ title: 'Toggle sorting' }),
                  ])}
                >
                  {column.render('Header')}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  className={classNames({
                    [styles.compactCell]: cell.column.compactCell,
                  })}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
