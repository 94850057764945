import React, { useState } from 'react';

import Button from '@/components/Button';
import { formatQuantityRounded } from '@/format';
import { useSecurityDetailsQuery } from '@/graphql';
import usePathParams from '@/hooks/usePathParams';

import styles from './SecurityDetails.module.css';

export default function SecurityDetails() {
  const { securityId } = usePathParams('securityId');
  const { data } = useSecurityDetailsQuery({ variables: { id: securityId } });
  const { CEO, country, employees, description, name, website } =
    data?.securityDetails || {};
  const [showMoreDescription, setShowMoreDescription] = useState(false);

  return (
    <>
      {data && (
        <>
          <div className={styles.heading}>
            <h3>{name}</h3>
            {website && (
              <Button
                icon="globe"
                target="_blank"
                href={`https://${website}`}
                variant="link"
              >
                {website}
              </Button>
            )}
          </div>
          <dl className={styles.list}>
            {CEO && (
              <div>
                <dt>CEO:</dt>
                <dd>{CEO}</dd>
              </div>
            )}
            {employees && (
              <div>
                <dt>Employees:</dt>
                <dd>{formatQuantityRounded(employees)}</dd>
              </div>
            )}
            {country && (
              <div>
                <dt>Country:</dt>
                <dd>{country}</dd>
              </div>
            )}
          </dl>
          {description && (
            <p className={styles.description}>
              {showMoreDescription
                ? description
                : description.slice(
                    0,
                    description.slice(100).indexOf('.') + 101,
                  )}{' '}
              <Button
                onClick={() => setShowMoreDescription(!showMoreDescription)}
                size="large"
                variant="link"
              >
                {showMoreDescription ? 'Less' : 'More'}
              </Button>
            </p>
          )}
        </>
      )}
    </>
  );
}
