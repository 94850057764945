import { multiply } from 'bigint-decimal/esm/jsbi';
import classNames from 'classnames';
import React from 'react';

import { ActivitySeriesItem, SecuritiesMap } from '@/calc/types';
import { capitalize, formatPrice, formatQuantityDecimal } from '@/format';

import styles from './ChartActivityTooltip.module.css';

interface ChartActivityTooltipProps {
  datum: ActivitySeriesItem;
  securitiesMap: SecuritiesMap;
}

export default function ChartActivityTooltip({
  datum: { activity },
  securitiesMap,
}: ChartActivityTooltipProps) {
  if (activity.length === 0) {
    return null;
  }

  return (
    <table className={styles.activity}>
      <tbody>
        {activity.map((a) => {
          switch (a.type) {
            case 'split': {
              const { id, fromFactor, toFactor, securityId } = a.data;

              return (
                <tr key={id}>
                  <td className={styles.type}>Split</td>
                  <td className={styles.tickerSymbol}>
                    {securitiesMap[securityId]?.tickerSymbol}
                  </td>
                  <td
                    className={classNames(styles.fromFactor, 'right')}
                    colSpan={2}
                  >
                    {fromFactor.toString()}
                  </td>
                  <td className={classNames(styles.toFactor, 'right')}>
                    {toFactor.toString()}
                  </td>
                </tr>
              );
            }

            case 'transaction': {
              const { id, securityId, price, quantity, type } = a.data;

              return (
                <tr key={id}>
                  <td className={styles.type}>{capitalize(type)}</td>
                  <td className={styles.tickerSymbol}>
                    {securitiesMap[securityId]?.tickerSymbol}
                  </td>
                  <td className={classNames(styles.price, 'right')}>
                    {formatPrice(price)}
                  </td>
                  <td className={classNames(styles.quantity, 'right')}>
                    {formatQuantityDecimal(quantity)}
                  </td>
                  <td className="right">
                    {formatPrice(multiply(quantity, price))}
                  </td>
                </tr>
              );
            }

            default: {
              // TODO: Add splits
              return null;
            }
          }
        })}
      </tbody>
    </table>
  );
}
