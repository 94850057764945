import React from 'react';

import { Transaction } from '@/calc/types';
import Button from '@/components/Button';
import Toast from '@/components/Toast';
import TransactionFormModal from '@/components/TransactionFormModal';
import { usePortfoliosQuery } from '@/graphql';
import useToast from '@/hooks/useToast';
import useTransactions from '@/hooks/useTransactions';
import { groupPath } from '@/paths';

interface AddTransactionModalProps {
  addMore?: boolean;
  hide: () => void;
  portfolioId: string;
  securityIds?: string[];
  values?: Partial<Omit<Transaction, 'id'>>;
}

export default function AddTransactionModal({
  addMore,
  hide,
  portfolioId,
  securityIds,
  values,
}: AddTransactionModalProps) {
  const { createTransactions } = useTransactions({
    portfolioId,
  });

  // This is needed only for the root group ID
  const portfoliosQuery = usePortfoliosQuery();
  const rootGroupId =
    portfoliosQuery.data?.portfolios.find((p) => p.id === portfolioId)
      ?.rootGroup.id || '';

  const { showToast } = useToast();

  return (
    <TransactionFormModal
      addMore={addMore}
      hide={hide}
      onSubmit={async (newValues) => {
        await createTransactions([newValues]);

        if (securityIds && !securityIds.includes(newValues.securityId)) {
          showToast(() => (
            <Toast>
              Transaction added, but it&apos;s not a part of this group.{' '}
              <Button
                href={`${groupPath({
                  portfolioId,
                  groupId: rootGroupId,
                })}/transactions`}
                variant="invertedLink"
              >
                Go to all transactions
              </Button>
            </Toast>
          ));
        } else {
          showToast(() => <Toast>Transaction added.</Toast>);
        }
      }}
      values={values}
    />
  );
}
