/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import { useSelect } from 'downshift';
import React from 'react';

import Icon from '@/components/Icon';

import inputStyles from './Input.module.css';
import styles from './SelectInput.module.css';

export interface SelectInputProps {
  id?: string;
  onBlur?: () => void;
  onChange: (v: string) => void;
  items: { value: string; label: string }[];
  value?: string;
}

export default React.forwardRef(
  (
    { id, onBlur, onChange, items, value }: SelectInputProps,
    ref?: React.Ref<HTMLButtonElement>,
  ) => {
    const {
      isOpen,
      selectedItem,
      getToggleButtonProps,
      getMenuProps,
      getItemProps,
    } = useSelect({
      items,
      onSelectedItemChange: (data) => {
        if (data.selectedItem) {
          onChange(data.selectedItem.value);
        }
      },
      selectedItem: items.find((i) => i.value === value),
    });

    return (
      <div className={styles.container}>
        <button
          {...getToggleButtonProps({ ref })}
          className={classNames(
            inputStyles.input,
            inputStyles.regularVariant,
            styles.button,
            {
              [inputStyles.isOpen]: isOpen,
            },
          )}
          id={id}
          onBlur={onBlur}
          type="button"
        >
          {selectedItem?.label}
          <Icon className={styles.icon} name="chevronDown" size="24" />
        </button>
        <ul
          {...getMenuProps()}
          className={classNames(styles.items, {
            [styles.isOpen]: isOpen,
          })}
        >
          {isOpen &&
            items.map((item, index) => (
              <li
                className={styles.item}
                key={item.value}
                {...getItemProps({ item, index })}
              >
                {item.label}
              </li>
            ))}
        </ul>
      </div>
    );
  },
);
