import { differenceInHours } from 'date-fns/fp';
import React from 'react';

import BillingModal from '@/components/BillingModal';
import { useCurrentUserQuery } from '@/graphql';
import useModal from '@/hooks/useModal';

interface UseSubscriptionResult {
  activeOrTrial: boolean;
  invokeOrSubscribe: (fn: () => void) => () => void;
  showBillingModal: () => void;
  trialDaysRemaining: number | null;
}

export default function useSubscription(): UseSubscriptionResult {
  const currentUserQuery = useCurrentUserQuery();

  const [showBillingModal] = useModal(({ hide, setCanHide }) => (
    <BillingModal hide={hide} setCanHide={setCanHide} />
  ));

  if (!currentUserQuery.data) {
    return {
      activeOrTrial: true,
      invokeOrSubscribe: () => showBillingModal,
      showBillingModal,
      trialDaysRemaining: null,
    };
  }

  const {
    stripeSubscriptionStatus,
    trialEndAt,
  } = currentUserQuery.data.currentUser;

  const activeOrTrial =
    stripeSubscriptionStatus === 'active' || new Date(trialEndAt) > new Date();

  const trialDaysRemaining =
    stripeSubscriptionStatus !== 'active'
      ? differenceInHours(new Date(), new Date(trialEndAt)) / 24
      : null;

  return {
    activeOrTrial,
    invokeOrSubscribe: (fn) => () => {
      if (activeOrTrial) {
        fn();
      } else {
        showBillingModal();
      }
    },
    showBillingModal,
    trialDaysRemaining,
  };
}
