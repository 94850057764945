import { parse } from 'date-fns/fp';
import React, { useEffect, useState } from 'react';
import DayPicker from 'react-day-picker';
import { ClassNames } from 'react-day-picker/types/ClassNames';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';

import Input from '@/components/Input';
import { formatDate } from '@/format';

import styles from './DateInput.module.css';

const FORMATS = ['MM/dd/yyyy', 'MM/dd/yy'];
const MIN_YEAR = 1000;

function noop() {
  // noop
}

function parseDate(input: string): Date | undefined {
  // eslint-disable-next-line no-restricted-syntax
  for (const format of FORMATS) {
    const parsed = parse(new Date(), format, input);

    if (!Number.isNaN(parsed.getTime()) && parsed.getFullYear() >= MIN_YEAR) {
      return parsed;
    }
  }

  return undefined;
}

interface DateInputProps {
  id?: string;
  name?: string;
  onBlur?: () => void;
  onChange: (date: Date) => void;
  value?: Date;
  variant?: 'plain' | 'regular';
}

export default React.forwardRef(
  (
    {
      id,
      name,
      onBlur = noop,
      onChange,
      value = new Date(),
      variant = 'regular',
    }: DateInputProps,
    ref?: React.Ref<HTMLInputElement>,
  ) => {
    const rootEl = document.getElementById('root');
    const [isOpen, setIsOpen] = useState(false);
    const [referenceEl, setReferenceEl] = useState<HTMLDivElement | null>(null);
    const [popperEl, setPopperEl] = useState<HTMLDivElement | null>(null);

    const popper = usePopper(referenceEl, popperEl, {
      modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
      placement: 'bottom-start',
    });
    const [currentValue, setCurrentValue] = useState(value);
    const [inputValue, setInputValue] = useState(formatDate(currentValue));
    const [changed, setChanged] = useState(false);

    useEffect(() => {
      setCurrentValue(value);
      setInputValue(formatDate(value));
    }, [value]);

    useEffect(() => {
      if (changed) {
        const newInputValue = formatDate(currentValue);

        setInputValue(newInputValue);
        setIsOpen(false);
        setChanged(false);

        if (formatDate(value) !== newInputValue) {
          onChange(currentValue);
        }
      }
    }, [currentValue, changed, onChange, value]);

    function handleBlur() {
      setTimeout(() => {
        if (
          !popperEl?.contains(document.activeElement) &&
          !referenceEl?.contains(document.activeElement)
        ) {
          setChanged(true);
          onBlur();
        }
      });
    }

    return (
      <>
        <div className={styles[`${variant}Variant`]} ref={setReferenceEl}>
          <Input
            id={id}
            name={name}
            onBlur={handleBlur}
            onChange={(e) => {
              setInputValue(e.target.value);

              const date = parseDate(e.target.value);

              if (date) {
                setCurrentValue(date);
              }
            }}
            onFocus={() => {
              setIsOpen(true);
            }}
            onKeyDown={(e) => {
              switch (e.code) {
                case 'Enter':
                  e.currentTarget.blur();
                  e.preventDefault();
                  break;

                case 'Escape':
                  setCurrentValue(value);
                  e.currentTarget.blur();
                  e.stopPropagation();
                  break;

                default: // noop
              }
            }}
            ref={ref}
            value={inputValue}
            variant={variant}
          />
        </div>
        {rootEl &&
          isOpen &&
          ReactDOM.createPortal(
            <div
              ref={setPopperEl}
              style={popper.styles.popper}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...popper.attributes.popper}
            >
              <DayPicker
                classNames={(styles as unknown) as ClassNames}
                disabledDays={{ after: new Date() }}
                fromMonth={new Date(MIN_YEAR, 0)}
                month={currentValue}
                onBlur={handleBlur}
                onDayClick={(date) => {
                  setCurrentValue(date);
                  setChanged(true);
                }}
                selectedDays={currentValue}
              />
            </div>,
            rootEl,
          )}
      </>
    );
  },
);
