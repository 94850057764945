import React, { useEffect } from 'react';

import { useAuth } from '@/auth';
import AuthForm from '@/components/AuthForm';
import Button from '@/components/Button';
import { useSignUpMutation } from '@/graphql';

export default function Login() {
  const { isLoggedIn, logIn } = useAuth();
  const [signUp, { data }] = useSignUpMutation();

  useEffect(() => {
    logIn(data?.signUp.authToken);
  });

  if (isLoggedIn()) {
    return null;
  }

  return (
    <AuthForm
      buttonLabel="Sign up"
      error={data?.signUp.error}
      footer={
        <>
          Got an account?{' '}
          <Button href="/login" variant="link">
            Log in
          </Button>
        </>
      }
      onSubmit={(variables) => {
        signUp({ variables });
      }}
    />
  );
}
