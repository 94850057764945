import React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

import DecimalInput from '@/components/DecimalInput';
import { useFormId } from '@/components/Form';

interface FormDecimalInputProps {
  min?: string;
  name: string;
  rules?: RegisterOptions;
  step?: string;
}

export default function FormDecimalInput({
  min,
  name,
  rules,
  step,
}: FormDecimalInputProps) {
  const { control } = useFormContext();
  const formId = useFormId();
  const id = `${name}_${formId}`;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ onChange, onBlur, ref, value }) => (
        <>
          <DecimalInput
            id={id}
            name={name}
            min={min}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
            step={step}
            value={value}
          />
        </>
      )}
    />
  );
}
