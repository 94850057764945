import React from 'react';

import Tabs from '@/components/Tabs';

import styles from './HeaderTabs.module.css';

export { Tab } from '@/components/Tabs';

interface HeaderTabsProps {
  children: React.ReactNode;
}

export default function HeaderTabs({ children }: HeaderTabsProps) {
  return (
    <div className={styles.container}>
      <Tabs>{children}</Tabs>
    </div>
  );
}
