import classNames from 'classnames';
import React, { useEffect } from 'react';

import Button from '@/components/Button';

import styles from './Modal.module.css';

export interface ModalBaseProps {
  hide: (force?: boolean) => void;
}

interface ModalProps extends ModalBaseProps {
  className?: string;
  children: React.ReactNode;
  title?: string;
}

export default function Modal({
  className,
  children,
  hide,
  title,
}: ModalProps) {
  useEffect(() => {
    window.gtag('event', 'show_modal', {
      event_label: title,
      event_category: 'ui',
    });

    window.analytics.track('modal showed', { title });
  }, [title]);

  return (
    <aside className={classNames(styles.modal, className)} role="dialog">
      <header className={styles.header}>
        {title && <h2>{title}</h2>}
        <Button
          label="Close"
          icon="x"
          iconBold={false}
          onClick={() => {
            hide();
          }}
          round
          size="large"
          variant="lightButton"
        />
      </header>
      {children}
    </aside>
  );
}
