import React from 'react';
import { useForm } from 'react-hook-form';

import Button from '@/components/Button';
import ButtonFooter from '@/components/ButtonFooter';
import Form from '@/components/Form';
import FormInput from '@/components/FormInput';
import FormLabel from '@/components/FormLabel';
import Modal from '@/components/Modal';

interface GroupFormValues {
  name: string;
}

interface GroupFormModalProps {
  editing?: boolean;
  hide: () => void;
  onSubmit: (values: GroupFormValues) => void;
  values?: GroupFormValues;
}

export default function GroupFormModal({
  editing,
  hide,
  onSubmit,
  values,
}: GroupFormModalProps) {
  const functions = useForm<GroupFormValues>({
    defaultValues: {
      name: '',
      ...values,
    },
  });

  return (
    <Modal hide={hide} title={editing ? 'Rename group' : 'New group'}>
      <Form<GroupFormValues>
        functions={functions}
        onSubmit={(newValues) => {
          onSubmit(newValues);
          hide();
        }}
      >
        <FormLabel name="name">Name</FormLabel>
        <FormInput autoFocus name="name" rules={{ required: true }} />

        <ButtonFooter>
          <Button variant="primaryButton" size="large" type="submit">
            {editing ? 'Rename' : 'Create'}
          </Button>
        </ButtonFooter>
      </Form>
    </Modal>
  );
}
