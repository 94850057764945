import classNames from 'classnames';
import React from 'react';

import Icon from '@/components/Icon';

import styles from './Alert.module.css';

interface AlertProps {
  children: React.ReactNode;
  variant: 'error' | 'info' | 'warning';
}

export default function Alert({ children, variant }: AlertProps) {
  return (
    <div className={classNames(styles.alert, styles[variant])}>
      <Icon bold className={styles.icon} name={variant} size="24" />
      <p>{children}</p>
    </div>
  );
}
