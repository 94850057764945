import React from 'react';

import styles from './ModalOverlay.module.css';

interface ModalOverlayProps {
  children: React.ReactNode;
  onClick: () => void;
}

export default function ModalOverlay({ children, onClick }: ModalOverlayProps) {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      className={styles.modalOverlay}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClick();
        }
      }}
    >
      {children}
    </div>
  );
}
