import React from 'react';

import styles from './ComparisonMarker.module.css';

interface ComparisonMarkerProps {
  character: string;
  color: number;
}

export default function ComparisonMarker({
  character,
  color,
}: ComparisonMarkerProps) {
  return (
    <div
      className={styles.securityMarker}
      style={{
        ['--color' as string]: `var(--color-chart-${color})`,
      }}
    >
      {character}
    </div>
  );
}
