import React from 'react';

import styles from './ChartMarkerWrapper.module.css';

interface ChartMarkerWrapperProps {
  children: React.ReactNode;
  left: number;
  top: number;
}

export default function ChartMarkerWrapper({
  children,
  left,
  top,
}: ChartMarkerWrapperProps) {
  return (
    <div className={styles.chartMarkerWrapper} style={{ left, top }}>
      {children}
    </div>
  );
}
