import { endOfDay, subDays } from 'date-fns/fp';
import { useMemo } from 'react';

import { HoldingsSeriesItem } from '@/calc/types';
import useActivitySeries from '@/hooks/useActivitySeries';
import useHoldingsSeries from '@/hooks/useHoldingsSeries';

interface UseLatestHoldingsArgs {
  portfolioId: string;
  securityIds: string[];
}

interface UseLatestHoldingsResult {
  loading: boolean;
  data: [HoldingsSeriesItem?, HoldingsSeriesItem?];
}

export default function useLatestHoldings({
  portfolioId,
  securityIds,
}: UseLatestHoldingsArgs): UseLatestHoldingsResult {
  // Make it memoizable by using strings. `useMemo` uses `Object.is` to compare
  // dependencies, two `Date` objects will never be the same.
  const endOfToday = endOfDay(new Date()).toISOString();
  const dates = useMemo(
    () => [subDays(1, new Date(endOfToday)), new Date(endOfToday)],
    [endOfToday],
  );

  const activitySeries = useActivitySeries({
    dates,
    portfolioId,
    securityIds,
  });

  const holdingsSeries = useHoldingsSeries({
    dates,
    securityIds,
    activitySeries: activitySeries.data,
  });

  return useMemo<UseLatestHoldingsResult>(() => {
    const [prev, last] = holdingsSeries.data;

    return {
      data: [prev, last],
      loading: [activitySeries, holdingsSeries].some((x) => x.loading),
    };
  }, [activitySeries, holdingsSeries]);
}
