import base from '@/config/base';
import { Config } from '@/config/types';

const config: Config = {
  ...base,
  apiUrl: 'https://api.wealthie.app',
  googleAnalytics: {
    cookieDomain: '.wealthie.app',
    cookieFlags: 'SameSite=None;Secure',
    measurementId: 'G-RBZRWCX2WR',
  },
  segment: {
    key: 'Q1n17AhQYpEH92O3R1WIXLFHZ9JK2MYB',
  },
  stripe: {
    publishableKey:
      'pk_live_51Id2O4JYoSpkj2xyFv8kOwnjBESHJTRfvKv6Z83qcSFCaChXfziOOifVvGPq6S0jax4mMFJl4mvKDyhoRhEP5f8800dHCsSesY',
  },
};

export default config;
