import { useCallback } from 'react';

import {
  useCurrentUserQuery,
  UserSettings,
  useUpdateSettingMutation,
} from '@/graphql';

type UseSettingResult<T extends keyof UserSettings> = [
  NonNullable<UserSettings[T]> | undefined,
  (value: UserSettings[T]) => void,
];

export default function useSetting<T extends keyof UserSettings>(
  key: T,
): UseSettingResult<T> {
  const currentUserQuery = useCurrentUserQuery();
  const [updateSetting] = useUpdateSettingMutation();

  const callback = useCallback(
    (value: UserSettings[T]) => {
      updateSetting({
        variables: { key, value: value ?? null },
        optimisticResponse: currentUserQuery.data && {
          updateSetting: {
            ...currentUserQuery.data.currentUser,
            settings: {
              ...currentUserQuery.data.currentUser.settings,
              [key]: value,
            },
          },
        },
      });
    },
    [currentUserQuery, updateSetting, key],
  );

  if (!currentUserQuery.data) {
    return [undefined, callback];
  }

  return [
    currentUserQuery.data.currentUser.settings[key] ?? undefined,
    callback,
  ];
}
