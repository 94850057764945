import React, { useMemo } from 'react';

import getDates from '@/calc/getDates';
import getHoldingItems from '@/calc/getHoldingItems';
import { ChartSeriesItem, Group } from '@/calc/types';
import ChartActivityTooltip from '@/components/ChartActivityTooltip';
import ChartToolbar from '@/components/ChartToolbar';
import ChartTooltip from '@/components/ChartTooltip';
import ChartWithAllocation from '@/components/ChartWithAllocation';
import useChartSeries from '@/hooks/useChartSeries';
import usePathParams from '@/hooks/usePathParams';
import useSecuritiesMap from '@/hooks/useSecuritiesMap';
import useSetting from '@/hooks/useSetting';

interface GroupChartProps {
  endAt: Date;
  startAt: Date;
  group: Group;
}

export default function GroupChart({
  endAt,
  startAt,
  group: { allSecurityIds: securityIds, subgroups },
}: GroupChartProps) {
  const { portfolioId } = usePathParams('portfolioId');
  const [comparisonSecurityIds = [], setComparisonSecurityIds] = useSetting(
    'comparisonSecurityIds',
  );
  const dates = useMemo(() => getDates({ startAt, endAt }), [endAt, startAt]);

  const { data: series, seriesDescriptors, loading } = useChartSeries({
    comparisonSecurityIds,
    dates,
    portfolioId,
    // TODO: This fetches prices for securities that may not be held anymore
    securityIds,
  });

  const { data: securitiesMap } = useSecuritiesMap({ securityIds });

  return (
    <>
      <ChartToolbar
        comparisonSecurityIds={comparisonSecurityIds}
        endAt={endAt}
        onAdd={(id) => {
          setComparisonSecurityIds([...comparisonSecurityIds, id]);
        }}
        onRemove={(id) => {
          setComparisonSecurityIds(
            comparisonSecurityIds.filter((cId) => cId !== id),
          );
        }}
        startAt={startAt}
      />

      <ChartWithAllocation<ChartSeriesItem>
        series={series}
        getAllocationData={(d) =>
          getHoldingItems({
            subgroups,
            holdings: d?.holdings || [],
            securitiesMap,
          })
        }
        getDate={(d) => d.at}
        renderTooltipContent={(d) => (
          <>
            <ChartTooltip datum={d} seriesDescriptors={seriesDescriptors} />
            <ChartActivityTooltip datum={d} securitiesMap={securitiesMap} />
          </>
        )}
        loading={loading}
        seriesDescriptors={seriesDescriptors}
      />
    </>
  );
}
