import classNames from 'classnames';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import Button from '@/components/Button';

import styles from './Tabs.module.css';

interface TabProps {
  children: string;
  to: string;
}

export function Tab({ children, to }: TabProps) {
  const match = useRouteMatch({ path: to, exact: true });

  return (
    <Button
      className={classNames(styles.tab, { [styles.active]: match })}
      href={to}
      variant="plain"
    >
      {children}
    </Button>
  );
}

interface TabsProps {
  children: React.ReactNode;
}

export default function Tabs({ children }: TabsProps) {
  return <nav className={styles.tabs}>{children}</nav>;
}
