const config = {
  apiUrl:
    process.env.REACT_APP_API_EXTERNAL_URL ?? 'https://api.dev.wealthie.app',
  googleAnalytics: {
    cookieDomain: '.dev.wealthie.app',
    cookieFlags: 'SameSite=None;Secure',
    measurementId: 'G-KLXRZ0T4TF',
  },
  segment: {
    key: 'BFTunXbBugytzNJJklaTlIU6uBeTTLPI',
  },
  sentry: {
    dsn:
      'https://ef5f8ebc8fc7442cb84e26e4d813bcf5@o622899.ingest.sentry.io/5753000',
  },
  stripe: {
    publishableKey:
      'pk_test_51Id2O4JYoSpkj2xypF8YVuP7pztDbEtR6laMVEG9lL1mCJdjUSOXlVzHun1n69ro4zP3ur8I8ki1Obu0dXh0HJxD00uvBQEfVj',
  },
};

export default config;
