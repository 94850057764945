import React from 'react';

import { ReactComponent as LogoImage } from '@/assets/logo.svg';
import Button from '@/components/Button';

import styles from './AuthLayout.module.css';

interface AuthLayoutProps {
  children: React.ReactNode;
}

export default function AuthLayout({ children }: AuthLayoutProps) {
  return (
    <main className={styles.main}>
      <Button href="https://wealthie.app" variant="plain">
        <LogoImage aria-label="Back to website" className={styles.logo} />
      </Button>
      {children}
    </main>
  );
}
