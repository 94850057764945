import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import Button from '@/components/Button';
import GroupHeader from '@/components/GroupHeader';
import GroupTabs from '@/components/GroupTabs';
import Icon from '@/components/Icon';
import IllustrationPanel from '@/components/IllustrationPanel';
import Layout from '@/components/Layout';
import PointerDot from '@/components/PointerDot';
import { useCreateDemoMutation } from '@/graphql';
import useGroup from '@/hooks/useGroup';
import usePathParams from '@/hooks/usePathParams';
import useTransactions from '@/hooks/useTransactions';
import { groupPath } from '@/paths';
import GroupOverview from '@/views/GroupOverview';
import GroupTransactions from '@/views/GroupTransactions';

import styles from './Group.module.css';

export default function Group() {
  const { portfolioId, groupId } = usePathParams('portfolioId', 'groupId');
  const { path } = useRouteMatch();
  const [parentId, setParentId] = useState<string | null>(null);
  const { group, loading: groupLoading } = useGroup({
    id: groupId,
    portfolioId,
  });

  const { loading: transactionsLoading, transactions } = useTransactions({
    portfolioId,
    securityIds: group?.allSecurityIds || [],
  });

  const [createDemo] = useCreateDemoMutation({
    refetchQueries: ['groups', 'groupsSecurities', 'transactions'],
    variables: { portfolioId },
  });

  const history = useHistory();

  useEffect(() => {
    if (group) {
      setParentId(group.parentId);
    }
  }, [group]);

  if (!group && !groupLoading && parentId) {
    history.replace(groupPath({ portfolioId, groupId: parentId }));
  }

  return (
    <Layout
      breadcrumbs={group?.path.map(({ id, name }) => ({
        label: name,
        to: groupPath({ portfolioId, groupId: id }),
      }))}
    >
      {group && (
        <>
          {!transactionsLoading &&
            transactions.length === 0 &&
            (group.parent ? (
              <IllustrationPanel centerVertically illustration="emptyGroup">
                <p>Oops, this group is empty.</p>
                <p>
                  You can drag and drop securities and other groups into it in
                  the sidebar on the left.
                </p>
              </IllustrationPanel>
            ) : (
              <IllustrationPanel centerVertically illustration="emptyPortfolio">
                <p>Your portfolio doesn’t have any transactions.</p>
                <p>
                  You can add transactions by clicking on{' '}
                  <span className={styles.buttonText}>
                    the{' '}
                    <Icon
                      bold
                      className={styles.buttonIcon}
                      name="plus"
                      size="16"
                    />{' '}
                    button
                  </span>{' '}
                  in the sidebar on the left.
                  <PointerDot name="plusButton" />
                </p>
                <p>
                  If you’re not ready to add your transactions yet, we can{' '}
                  <Button
                    onClick={() => {
                      createDemo();
                    }}
                    variant="link"
                  >
                    add back a few demo ones
                  </Button>
                  .
                </p>
              </IllustrationPanel>
            ))}
          {transactions.length !== 0 && (
            <>
              <GroupHeader group={group} />
              <GroupTabs id={groupId} portfolioId={portfolioId} />

              <Switch>
                <Route path={`${path}/transactions`}>
                  <GroupTransactions />
                </Route>
                <Route path={path}>
                  <GroupOverview />
                </Route>
              </Switch>
            </>
          )}
        </>
      )}
    </Layout>
  );
}
