import classNames from 'classnames';
import React from 'react';

import styles from './Input.module.css';

export interface InputProps {
  autoFocus?: boolean;
  disabled?: boolean;
  id?: string;
  min?: string;
  name?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  right?: boolean;
  step?: string;
  type?: 'email' | 'password' | 'text';
  value?: string;
  variant?: 'plain' | 'regular';
}

export default React.forwardRef(
  (
    {
      autoFocus = false,
      disabled = false,
      id,
      min,
      name,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      right = false,
      step,
      type = 'text',
      value,
      variant = 'regular',
    }: InputProps,
    ref?: React.Ref<HTMLInputElement>,
  ) => (
    <input
      // For fields shown after a specific user action.
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      className={classNames(styles.input, styles[`${variant}Variant`], {
        [styles.right]: right,
      })}
      disabled={disabled}
      id={id}
      min={min}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      ref={ref}
      step={step}
      type={type}
      value={value}
    />
  ),
);
