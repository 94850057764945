import React from 'react';

import AddTransactionModal from '@/components/AddTransactionModal';
import Button from '@/components/Button';
import Content from '@/components/Content';
import ImportCSVModal from '@/components/ImportCSVModal';
import Loader from '@/components/Loader';
import Toolbar, { ToolbarGroup } from '@/components/Toolbar';
import TransactionsTable from '@/components/TransactionsTable';
import useGroup from '@/hooks/useGroup';
import useModal from '@/hooks/useModal';
import usePathParams from '@/hooks/usePathParams';
import useSecuritiesMap from '@/hooks/useSecuritiesMap';
import useSubscription from '@/hooks/useSubscription';
import useTransactions from '@/hooks/useTransactions';

export default function GroupTransactions() {
  const { groupId, portfolioId } = usePathParams('portfolioId', 'groupId');
  const { invokeOrSubscribe } = useSubscription();

  const { group } = useGroup({
    id: groupId,
    portfolioId,
  });

  const securityIds = group?.allSecurityIds || [];

  const { data: securitiesMap } = useSecuritiesMap({ securityIds });

  const {
    deleteTransaction,
    loading,
    transactions,
    updateTransaction,
  } = useTransactions({
    portfolioId,
    securityIds,
  });

  const [showAddTransactionModal] = useModal(({ hide }) => (
    <AddTransactionModal
      hide={hide}
      portfolioId={portfolioId}
      securityIds={group?.parentId ? securityIds : undefined}
    />
  ));

  const [showImportCSVModal] = useModal(({ hide }) => (
    <ImportCSVModal hide={hide} portfolioId={portfolioId} />
  ));

  return (
    group && (
      <Content>
        {transactions.length === 0 && loading ? (
          <Loader />
        ) : (
          <>
            <Toolbar>
              <ToolbarGroup>
                <Button
                  icon="plus"
                  onClick={invokeOrSubscribe(showAddTransactionModal)}
                  variant="lightButton"
                >
                  Add transaction
                </Button>
              </ToolbarGroup>
              <ToolbarGroup>
                <Button
                  icon="import"
                  onClick={invokeOrSubscribe(showImportCSVModal)}
                  variant="lightButton"
                >
                  Import CSV
                </Button>
              </ToolbarGroup>
            </Toolbar>

            <TransactionsTable
              data={transactions}
              onDelete={deleteTransaction}
              onEdit={updateTransaction}
              portfolioId={portfolioId}
              securitiesMap={securitiesMap}
            />
          </>
        )}
      </Content>
    )
  );
}
