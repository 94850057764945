import { DECIMAL_0, equal, lessThan } from 'bigint-decimal/esm/jsbi';
import React, { useMemo } from 'react';

import {
  getChangePercentage,
  getPrice,
  getTotalCostBasis,
  getTotalValue,
} from '@/calc/getHoldingsSeries';
import Header, {
  HeaderGroup,
  HeaderHighlight,
  HeaderItem,
  Heading,
} from '@/components/Header';
import Icon from '@/components/Icon';
import {
  formatPercentage,
  formatPrice,
  formatPriceRounded,
  formatQuantity,
} from '@/format';
import useLatestHoldings from '@/hooks/useLatestHoldings';
import usePathParams from '@/hooks/usePathParams';

import styles from './SecurityHeader.module.css';

interface SecurityHeaderProps {
  security: {
    id: string;
    market: string | null;
    name: string | null;
    tickerSymbol: string;
  };
}

export default function SecurityHeader({ security }: SecurityHeaderProps) {
  const { portfolioId } = usePathParams('portfolioId');
  const securityIds = [security.id];

  const {
    data: [prevDatum, lastDatum],
  } = useLatestHoldings({
    portfolioId,
    securityIds,
  });

  const values = useMemo(() => {
    if (
      !lastDatum ||
      !prevDatum ||
      equal(lastDatum.holdings[0]?.quantity, DECIMAL_0)
    ) {
      return null;
    }

    const returnPercentage = getChangePercentage(
      getTotalCostBasis(lastDatum),
      getTotalValue(lastDatum),
    );

    return (
      <HeaderGroup>
        <HeaderItem label="Value">
          {formatPriceRounded(getTotalValue(lastDatum))}
        </HeaderItem>
        <HeaderItem
          variant={
            (equal(returnPercentage, DECIMAL_0) && 'regular') ||
            (lessThan(DECIMAL_0, returnPercentage) ? 'positive' : 'negative')
          }
          label="Return"
        >
          {!equal(returnPercentage, DECIMAL_0) && (
            <Icon
              name={
                lessThan(DECIMAL_0, returnPercentage) ? 'arrowUp' : 'arrowDown'
              }
              size="32"
            />
          )}
          {formatPercentage(returnPercentage)}
          <HeaderHighlight
            changePercentage={getChangePercentage(
              getPrice(prevDatum),
              getPrice(lastDatum),
            )}
            icon
            sideNote="today"
          />
        </HeaderItem>
      </HeaderGroup>
    );
  }, [lastDatum, prevDatum]);

  return (
    <Header>
      <HeaderGroup>
        <HeaderItem label="Symbol">
          <Heading icon="security">
            {security.tickerSymbol}
            <span className={styles.market}>
              .{security.market?.toUpperCase()}
            </span>
          </Heading>
        </HeaderItem>
        {lastDatum && prevDatum && (
          <>
            <HeaderItem label="Price">
              {formatPrice(getPrice(lastDatum))}
              {!values && (
                <HeaderHighlight
                  changePercentage={getChangePercentage(
                    getPrice(prevDatum),
                    getPrice(lastDatum),
                  )}
                  icon
                  sideNote="today"
                />
              )}
            </HeaderItem>
            {values && (
              <HeaderItem label="Quantity" variant="quantity">
                {formatQuantity(lastDatum.holdings[0]?.quantity)}
              </HeaderItem>
            )}
          </>
        )}
      </HeaderGroup>

      {values}
    </Header>
  );
}
