import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '@/auth';
import AuthForm from '@/components/AuthForm';
import Button from '@/components/Button';
import { useLogInMutation } from '@/graphql';

export default function Login() {
  const { isLoggedIn, logIn } = useAuth();
  const [sendLogIn, { data }] = useLogInMutation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const authToken = params.get('authToken') || data?.logIn.authToken;
  const error = params.get('error') || data?.logIn.error;
  const provider = params.get('provider');

  useEffect(() => {
    logIn(authToken);
  });

  if (authToken || isLoggedIn()) {
    return null;
  }

  return (
    <AuthForm
      buttonLabel="Log in"
      error={error}
      provider={provider}
      footer={
        <>
          No account?{' '}
          <Button href="/signup" variant="link">
            Create one
          </Button>
        </>
      }
      // If we increase it in sign-up, allow people to still log in.
      minPasswordLength={0}
      onSubmit={(variables) => {
        sendLogIn({ variables });
      }}
    />
  );
}
