import classNames from 'classnames';
import React from 'react';

import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrowDown.svg';
import { ReactComponent as ArrowUpIcon } from '@/assets/icons/arrowUp.svg';
import { ReactComponent as CheckIcon } from '@/assets/icons/check.svg';
import { ReactComponent as ChevronDownIcon } from '@/assets/icons/chevronDown.svg';
import { ReactComponent as ChevronUpIcon } from '@/assets/icons/chevronUp.svg';
import { ReactComponent as CreditCardIcon } from '@/assets/icons/creditCard.svg';
import { ReactComponent as DeleteIcon } from '@/assets/icons/delete.svg';
import { ReactComponent as DragIcon } from '@/assets/icons/drag.svg';
import { ReactComponent as EditIcon } from '@/assets/icons/edit.svg';
import { ReactComponent as ErrorIcon } from '@/assets/icons/error.svg';
import { ReactComponent as FacebookIcon } from '@/assets/icons/facebook.svg';
import { ReactComponent as GlobeIcon } from '@/assets/icons/globe.svg';
import { ReactComponent as GoogleIcon } from '@/assets/icons/google.svg';
import { ReactComponent as GroupIcon } from '@/assets/icons/group.svg';
import { ReactComponent as HelpIcon } from '@/assets/icons/help.svg';
import { ReactComponent as HomeIcon } from '@/assets/icons/home.svg';
import { ReactComponent as ImportIcon } from '@/assets/icons/import.svg';
import { ReactComponent as InfoIcon } from '@/assets/icons/info.svg';
import { ReactComponent as LogOutIcon } from '@/assets/icons/logOut.svg';
import { ReactComponent as NewGroupIcon } from '@/assets/icons/newGroup.svg';
import { ReactComponent as OverflowMenuIcon } from '@/assets/icons/overflowMenu.svg';
import { ReactComponent as ParentGroupIcon } from '@/assets/icons/parentGroup.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';
import { ReactComponent as PortfolioIcon } from '@/assets/icons/portfolio.svg';
import { ReactComponent as SearchIcon } from '@/assets/icons/search.svg';
import { ReactComponent as SecurityIcon } from '@/assets/icons/security.svg';
import { ReactComponent as UserIcon } from '@/assets/icons/user.svg';
import { ReactComponent as WarningIcon } from '@/assets/icons/warning.svg';
import { ReactComponent as XIcon } from '@/assets/icons/x.svg';

import styles from './Icon.module.css';

export const MAPPING = {
  arrowDown: ArrowDownIcon,
  arrowUp: ArrowUpIcon,
  check: CheckIcon,
  chevronDown: ChevronDownIcon,
  chevronUp: ChevronUpIcon,
  creditCard: CreditCardIcon,
  delete: DeleteIcon,
  drag: DragIcon,
  edit: EditIcon,
  error: ErrorIcon,
  facebook: FacebookIcon,
  globe: GlobeIcon,
  google: GoogleIcon,
  group: GroupIcon,
  help: HelpIcon,
  home: HomeIcon,
  import: ImportIcon,
  info: InfoIcon,
  logOut: LogOutIcon,
  newGroup: NewGroupIcon,
  overflowMenu: OverflowMenuIcon,
  parentGroup: ParentGroupIcon,
  plus: PlusIcon,
  portfolio: PortfolioIcon,
  search: SearchIcon,
  security: SecurityIcon,
  user: UserIcon,
  warning: WarningIcon,
  x: XIcon,
};

interface IconProps {
  bold?: boolean;
  className?: string;
  name: keyof typeof MAPPING;
  size: '16' | '24' | '32';
}

export default function Icon({
  bold = false,
  className,
  name,
  size,
}: IconProps) {
  const Component = MAPPING[name];

  return (
    <Component
      className={classNames(
        styles.icon,
        styles[`size${size}`],
        {
          [styles.bold]: bold,
        },
        className,
      )}
    />
  );
}
