import { Decimal, DECIMAL_0, toNumber } from 'bigint-decimal/esm/jsbi';
import React, { useState } from 'react';

import Input from '@/components/Input';

const formatter = new Intl.NumberFormat('en-US');

function format(value: Decimal): string {
  return formatter.format(toNumber(value));
}

function noop() {
  // noop
}

function parse(value: string): Decimal | null {
  try {
    return new Decimal(value.replace(/[$,_()]/g, ''));
  } catch {
    return null;
  }
}

interface DecimalInputProps {
  id?: string;
  min?: string;
  name?: string;
  onBlur?: () => void;
  onChange: (value: Decimal) => void;
  step?: string;
  value?: Decimal;
}

export default React.forwardRef(
  (
    {
      id,
      min,
      name,
      onBlur = noop,
      onChange,
      step,
      value = DECIMAL_0,
    }: DecimalInputProps,
    ref?: React.Ref<HTMLInputElement>,
  ) => {
    const [currentValue, setCurrentValue] = useState(value);
    const [inputValue, setInputValue] = useState(format(currentValue));

    return (
      <Input
        id={id}
        min={min}
        name={name}
        onBlur={() => {
          setInputValue(format(currentValue));
          onBlur();
        }}
        onChange={(e) => {
          setInputValue(e.target.value);

          const newValue = parse(e.target.value);

          if (newValue !== null) {
            setCurrentValue(newValue);
            onChange(newValue);
          }
        }}
        ref={ref}
        step={step}
        value={inputValue}
      />
    );
  },
);
