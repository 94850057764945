import { subMonths } from 'date-fns/fp';
import React, { useCallback } from 'react';

import Content from '@/components/Content';
import { useDateRangeQueryParams } from '@/components/DateRangePicker';
import dndTypes from '@/components/dndTypes';
import GroupChart from '@/components/GroupChart';
import HoldingsTable from '@/components/HoldingsTable';
import useGroup from '@/hooks/useGroup';
import usePathParams from '@/hooks/usePathParams';

export default function GroupOverview() {
  const { portfolioId, groupId } = usePathParams('portfolioId', 'groupId');
  const { startAt, endAt } = useDateRangeQueryParams({
    startAt: subMonths(3, new Date()),
  });

  const {
    assignSecuritiesToGroup,
    createGroup,
    deleteGroup,
    group,
    updateGroup,
  } = useGroup({
    id: groupId,
    portfolioId,
  });

  const handleGroupDrop = useCallback(
    (toGroupId, item) => {
      switch (item.type) {
        case dndTypes.SECURITY:
          assignSecuritiesToGroup([item.id], toGroupId);
          break;

        case dndTypes.GROUP:
          updateGroup(item.id, { parentId: toGroupId });
          break;

        default: // nothing
      }
    },
    [assignSecuritiesToGroup, updateGroup],
  );

  const handleParentGroupDrop = useCallback(
    (toGroupId, item) => {
      switch (item.type) {
        case dndTypes.SECURITY:
          assignSecuritiesToGroup([item.id], toGroupId);
          break;

        case dndTypes.GROUP:
          updateGroup(item.id, { parentId: toGroupId });
          break;

        default: // nothing
      }
    },
    [assignSecuritiesToGroup, updateGroup],
  );

  const handleGroupRename = useCallback(
    (renamedGroupId, name) => {
      updateGroup(renamedGroupId, { name });
    },
    [updateGroup],
  );

  return (
    group && (
      <>
        <Content>
          <GroupChart group={group} startAt={startAt} endAt={endAt} />
        </Content>

        <Content extraSpacing>
          <HoldingsTable
            group={group}
            onGroupCreate={createGroup}
            onGroupDelete={deleteGroup}
            onGroupDrop={handleGroupDrop}
            onGroupRename={handleGroupRename}
            onParentGroupDrop={handleParentGroupDrop}
            portfolioId={portfolioId}
          />
        </Content>
      </>
    )
  );
}
