import {
  addDays,
  differenceInCalendarDays,
  endOfDay,
  isEqual,
  subDays,
  subMonths,
  subYears,
} from 'date-fns/fp';
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Button from '@/components/Button';
import DateInput from '@/components/DateInput';
import { ToolbarGroup, ToolbarLabel } from '@/components/Toolbar';

const MAX_RANGE = 365 * 15;
const MIN_RANGE = 28;

function useRanges() {
  // Make it memoizable by using strings. `useMemo` uses `Object.is` to compare
  // dependencies, two `Date` objects will never be the same.
  const endOfToday = endOfDay(new Date()).toISOString();

  const result = useMemo(() => {
    const endAt = new Date(endOfToday);

    return [
      {
        label: '3M',
        value: '3m',
        startAt: endOfDay(subMonths(3, endAt)),
        endAt,
      },
      {
        label: '1Y',
        value: '1y',
        startAt: endOfDay(subYears(1, endAt)),
        endAt,
      },
      {
        label: '3Y',
        value: '3y',
        startAt: endOfDay(subYears(3, endAt)),
        endAt,
      },
    ];
  }, [endOfToday]);

  return result;
}

interface DateRange {
  endAt: Date;
  startAt: Date;
}

export function useDateRangeQueryParams({
  startAt = endOfDay(new Date()),
  endAt = endOfDay(new Date()),
}: Partial<DateRange> = {}): DateRange {
  const location = useLocation();
  const ranges = useRanges();

  const startAtString = startAt.toISOString();
  const endAtString = endAt.toISOString();

  const result = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const range = ranges.find((r) => r.value === params.get('range'));

    if (range) {
      return { startAt: range.startAt, endAt: range.endAt };
    }

    const newStartAt = endOfDay(new Date(params.get('from') || startAtString));
    const newEndAt = endOfDay(new Date(params.get('to') || endAtString));

    return { startAt: newStartAt, endAt: newEndAt };
  }, [endAtString, location.search, ranges, startAtString]);

  return result;
}

export default function DateRangePicker({ startAt, endAt }: DateRange) {
  const history = useHistory();
  const location = useLocation();
  const ranges = useRanges();

  function changeFromTo(newStartAt: Date, newEndAt: Date) {
    history.replace(
      `${location.pathname}?${new URLSearchParams({
        from: newStartAt.toISOString(),
        to: newEndAt.toISOString(),
      }).toString()}`,
    );
  }

  return (
    <>
      <ToolbarGroup>
        {ranges.map((range) => {
          const path = `${location.pathname}?${new URLSearchParams({
            range: range.value,
          }).toString()}`;

          return (
            <Button
              active={
                isEqual(range.startAt, startAt) && isEqual(range.endAt, endAt)
              }
              key={range.label}
              replace
              href={path}
              variant="lightButton"
            >
              {range.label}
            </Button>
          );
        })}
      </ToolbarGroup>
      <ToolbarGroup>
        <ToolbarLabel label="From:">
          <DateInput
            value={startAt}
            onChange={(date) => {
              const newStartAt = endOfDay(date);
              const diff = differenceInCalendarDays(newStartAt, endAt);

              if (diff > MAX_RANGE) {
                changeFromTo(newStartAt, addDays(MAX_RANGE, newStartAt));
              } else if (diff < MIN_RANGE) {
                changeFromTo(newStartAt, addDays(MIN_RANGE, newStartAt));
              } else {
                changeFromTo(newStartAt, endAt);
              }
            }}
            variant="plain"
          />
        </ToolbarLabel>
      </ToolbarGroup>
      <ToolbarGroup>
        <ToolbarLabel label="To:">
          <DateInput
            value={endAt}
            onChange={(date) => {
              const newEndAt = endOfDay(date);
              const diff = differenceInCalendarDays(startAt, newEndAt);

              if (diff > MAX_RANGE) {
                changeFromTo(subDays(MAX_RANGE, newEndAt), newEndAt);
              } else if (diff < MIN_RANGE) {
                changeFromTo(subDays(MIN_RANGE, newEndAt), newEndAt);
              } else {
                changeFromTo(startAt, newEndAt);
              }
            }}
            variant="plain"
          />
        </ToolbarLabel>
      </ToolbarGroup>
    </>
  );
}
