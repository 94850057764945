import classNames from 'classnames';
import React from 'react';

import Icon from '@/components/Icon';

import styles from './Banner.module.css';

interface BannerProps {
  children: React.ReactNode;
  variant: 'error' | 'info' | 'warning';
}

export default function Banner({ children, variant }: BannerProps) {
  return (
    <div className={classNames(styles.banner, styles[variant])}>
      <Icon bold className={styles.icon} name={variant} size="24" />
      <p>{children}</p>
    </div>
  );
}
