import React from 'react';

import styles from './Toast.module.css';

interface ToastProps {
  children: React.ReactNode;
}

export default function Toast({ children }: ToastProps) {
  return <div className={styles.toast}>{children}</div>;
}
