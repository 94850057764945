import React from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';

import { useFormId } from '@/components/Form';

import styles from './FormTextarea.module.css';

interface FormTextareaProps {
  autoFocus?: boolean;
  name: string;
  rules?: RegisterOptions;
}

export default function FormTextarea({
  autoFocus = false,
  name,
  rules,
}: FormTextareaProps) {
  const { register } = useFormContext();
  const formId = useFormId();
  const id = `${name}_${formId}`;

  return (
    <div className={styles.wrapper}>
      <textarea
        // For fields shown after a specific user action.
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        className={styles.textarea}
        id={id}
        name={name}
        ref={register(rules)}
      />
    </div>
  );
}
