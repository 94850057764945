import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import { Security, Transaction } from '@/calc/types';
import Alert from '@/components/Alert';
import Button from '@/components/Button';
import ButtonFooter from '@/components/ButtonFooter';
import Icon, { MAPPING } from '@/components/Icon';
import Modal from '@/components/Modal';
import Scrollable from '@/components/Scrollable';
import Switch from '@/components/Switch';
import Toast from '@/components/Toast';
import TransactionsTable from '@/components/TransactionsTable';
import { useSecuritiesByMarketAndTickerSymbolsQuery } from '@/graphql';
import useFileInput from '@/hooks/useFileInput';
import useToast from '@/hooks/useToast';
import useTransactions from '@/hooks/useTransactions';
import {
  getTickerSymbols,
  getTransactions,
  injectUniqueHashes,
  parseCSV,
} from '@/import/csv';

import styles from './ImportCSVModal.module.css';

const SELECT_FILE_STATES: Record<
  string,
  { className: string; icon: keyof typeof MAPPING; message: React.ReactNode }
> = {
  correct: {
    className: 'correctState',
    icon: 'check',
    message: <>Drop this file to start the import</>,
  },
  regular: {
    className: 'regularState',
    icon: 'import',
    message: <>Drop a CSV file here or click to upload</>,
  },
  wrongCount: {
    className: 'wrongState',
    icon: 'error',
    message: <>You can drop only one file at a time</>,
  },
  wrongType: {
    className: 'wrongState',
    icon: 'error',
    message: (
      <>
        Choose a file that ends with &ldquo;<strong>.csv</strong>&rdquo;
      </>
    ),
  },
};

interface FilesItem {
  files: File[];
  items: DataTransferItemList;
}

function getFileTypes(item: FilesItem | null): string[] {
  if (!item) {
    return [];
  }

  const { files, items } = item;

  if (files && files.length > 0) {
    return files.map((f) => f.type);
  }

  if (items && items.length > 0) {
    return Array.from(items).map((i) => i.type);
  }

  return [];
}

interface SelectFileProps {
  onChange: (file: File) => void;
}

function SelectFile({ onChange }: SelectFileProps) {
  const accept = 'text/csv';
  const [state, setState] = useState<keyof typeof SELECT_FILE_STATES>(
    'regular',
  );

  const selectCsvFile = useFileInput({
    accept,
    onChange: (files) => {
      onChange(files[0]);
    },
  });

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      canDrop: () => state === 'correct',
      drop: (item: FilesItem) => {
        onChange(item.files[0]);
      },
      collect: (monitor) => {
        const item = monitor.getItem() as FilesItem | null;
        const types = getFileTypes(item);

        // Only some events will propagate correct file info due to a bug in
        // react-dnd so don't change state if there's no data.
        // See https://github.com/react-dnd/react-dnd/pull/3262
        if (types.length !== 0) {
          if (types.length > 1) {
            setState('wrongCount');
          } else if (types[0] !== accept) {
            setState('wrongType');
          } else {
            setState('correct');
          }
        }

        return {
          isOver: monitor.isOver(),
        };
      },
    }),
    [onChange, state, setState],
  );

  return (
    <div className={classNames(styles.selectFile, styles.text)}>
      <p>
        You can import any CSV file that contains at least the following
        columns: Date, Type (Buy/Sell), Symbol, Quantity, Price.
      </p>

      <p>
        Most brokerages allow you to export your transactions history into a CSV
        file. Look for a button or a menu item called &ldquo;Export&rdquo;.
      </p>

      <Button
        className={classNames(styles.fileButton, {
          [styles[SELECT_FILE_STATES[state].className]]: isOver,
        })}
        onClick={selectCsvFile}
        ref={drop}
        variant="plain"
      >
        <Icon
          bold
          className={styles.fileIcon}
          name={
            isOver
              ? SELECT_FILE_STATES[state].icon
              : SELECT_FILE_STATES.regular.icon
          }
          size="32"
        />
        <span>
          {isOver
            ? SELECT_FILE_STATES[state].message
            : SELECT_FILE_STATES.regular.message}
        </span>
      </Button>
    </div>
  );
}

interface ImportCSVModalProps {
  hide: () => void;
  portfolioId: string;
}

export default function ImportCSVModal({
  hide,
  portfolioId,
}: ImportCSVModalProps) {
  const { createTransactions, loading, transactions } = useTransactions({
    portfolioId,
  });

  const [file, setFile] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<string | null>(null);

  useEffect(() => {
    // TODO: Remove if we reafactor useModal so that we never pass empty files
    if (file && file.name !== '') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFileContent(e.target?.result as string);
      };
      reader.readAsText(file, 'UTF-8');
    }
  }, [file]);

  const { showToast } = useToast();

  const { csvTransactions, errors } = useMemo(
    () =>
      fileContent !== null
        ? parseCSV(fileContent)
        : { csvTransactions: null, errors: [] },
    [fileContent],
  );

  const tickerSymbols = useMemo(() => getTickerSymbols(csvTransactions ?? []), [
    csvTransactions,
  ]);

  const securitiesByMarketAndTickerSymbolsQuery = useSecuritiesByMarketAndTickerSymbolsQuery(
    {
      // TODO: Support different markets
      variables: { market: 'us', tickerSymbols },
      skip: tickerSymbols.length === 0,
    },
  );

  const securities = useMemo(
    () =>
      securitiesByMarketAndTickerSymbolsQuery.data
        ?.securitiesByMarketAndTickerSymbols || [],
    [securitiesByMarketAndTickerSymbolsQuery],
  );

  const securitiesMap = useMemo(
    () =>
      securities.reduce<Record<string, Security>>(
        (acc, s) => ({ ...acc, [s.id]: s }),
        {},
      ),
    [securities],
  );

  const tickerSymbolsMap = useMemo(
    () =>
      securities.reduce<Record<string, string>>(
        (acc, s) => ({ ...acc, [s.tickerSymbol]: s.id }),
        {},
      ),
    [securities],
  );

  const { transactions: newTransactions, unknownTickerSymbols } = useMemo(
    () =>
      csvTransactions !== null &&
      !securitiesByMarketAndTickerSymbolsQuery.loading
        ? getTransactions({
            csvTransactions,
            tickerSymbolsMap,
          })
        : { transactions: null, unknownTickerSymbols: [] },
    [
      csvTransactions,
      securitiesByMarketAndTickerSymbolsQuery.loading,
      tickerSymbolsMap,
    ],
  );

  const uniqueHashes = useMemo(
    () => (!loading ? new Set(transactions.map((t) => t.uniqueHash)) : null),
    [loading, transactions],
  );

  const [filteredTransactions, setFilteredTransactions] = useState<
    Omit<Transaction, 'id'>[]
  >([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      if (newTransactions !== null && uniqueHashes !== null) {
        const result = await injectUniqueHashes(newTransactions);

        setFilteredTransactions(
          result.filter((t) => !uniqueHashes.has(t.uniqueHash)),
        );
        setLoaded(true);
      }
    })();
  }, [newTransactions, securitiesMap, uniqueHashes, unknownTickerSymbols]);

  const [discardDuplicate, setDiscardDuplicate] = useState(true);

  const importedTransactions = discardDuplicate
    ? filteredTransactions
    : newTransactions ?? [];

  const duplicateCount =
    (newTransactions?.length ?? 0) - filteredTransactions.length;

  return (
    <Modal
      className={classNames(styles.modal, styles.text)}
      hide={hide}
      title="Import CSV"
    >
      {!loaded || importedTransactions.length === 0 ? (
        <>
          {loaded && (
            <Alert variant="error">
              {errors.length === 0 ? (
                <>
                  We couldn&apos;t find any transactions to import from the
                  selected file.
                </>
              ) : (
                <>The format of your CSV file was not recognized.</>
              )}
            </Alert>
          )}
          <SelectFile onChange={setFile} />
        </>
      ) : (
        <>
          {unknownTickerSymbols.length !== 0 && (
            <Alert variant="warning">
              We couldn&apos;t identify some of the symbols:{' '}
              {unknownTickerSymbols.join(', ')}. Transactions for these
              securities have been skipped.
            </Alert>
          )}

          {duplicateCount !== 0 && (
            <div className={styles.discardSwitch}>
              <Switch
                label={`Discard ${duplicateCount} duplicate transactions`}
                onChange={(value) => {
                  setDiscardDuplicate(value);
                }}
                size="large"
                value={discardDuplicate}
              />
            </div>
          )}

          <p>Found {importedTransactions.length} transactions:</p>

          <div className={styles.transactions}>
            <Scrollable outsideScrollbar>
              <div className={styles.scrollable}>
                <TransactionsTable
                  data={importedTransactions}
                  securitiesMap={securitiesMap}
                />
              </div>
            </Scrollable>
          </div>

          <p>Would you like to import them?</p>

          <ButtonFooter>
            <Button onClick={hide} size="large">
              Cancel
            </Button>
            <Button
              onClick={async () => {
                await createTransactions(importedTransactions);

                showToast(() => (
                  <Toast>
                    {importedTransactions.length} transactions imported.
                  </Toast>
                ));

                hide();
              }}
              variant="primaryButton"
              size="large"
            >
              Import
            </Button>
          </ButtonFooter>
        </>
      )}
    </Modal>
  );
}
