import classNames from 'classnames';
import React from 'react';

import Icon, { MAPPING } from '@/components/Icon';
import Link from '@/components/Link';

import styles from './Button.module.css';

const noop = () => {
  // noop
};

interface BaseProps {
  active?: boolean;
  className?: string;
  danger?: boolean;
  icon?: keyof typeof MAPPING | React.ReactNode;
  iconBold?: boolean;
  iconSize?: '16' | '24';
  round?: boolean;
  size?: 'medium' | 'large';
  variant?:
    | 'invertedLink'
    | 'link'
    | 'lightButton'
    | 'plain'
    | 'primaryButton'
    | 'regularButton';
}

interface ButtonBaseProps extends BaseProps {
  disabled?: boolean;
  href?: never;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  replace?: never;
  target?: never;
  type?: 'button' | 'submit' | 'reset';
}

interface LinkBaseProps extends BaseProps {
  disabled?: never;
  href: string;
  onClick?: never;
  onMouseDown?: never;
  replace?: boolean;
  target?: '_blank';
  type?: never;
}

interface ButtonWithChildrenProps extends ButtonBaseProps {
  children: React.ReactNode;
  label?: never;
}

interface ButtonWithLabelProps extends ButtonBaseProps {
  children?: never;
  label: string;
}

interface LinkWithChildrenProps extends LinkBaseProps {
  children: React.ReactNode;
  label?: never;
}

interface LinkWithLabelProps extends LinkBaseProps {
  children?: never;
  label: string;
}

export default React.forwardRef(
  (
    {
      active = false,
      children,
      className,
      danger = false,
      disabled = false,
      href,
      icon,
      iconBold,
      iconSize,
      label,
      onClick = noop,
      onMouseDown = noop,
      replace,
      round = false,
      size = 'medium',
      target,
      type = 'button',
      variant = 'regularButton',
    }:
      | ButtonWithChildrenProps
      | ButtonWithLabelProps
      | LinkWithChildrenProps
      | LinkWithLabelProps,
    ref?: React.Ref<HTMLButtonElement>,
  ) => {
    const classes = classNames(
      styles.button,
      className,
      styles[`${size}Size`],
      styles[`${variant}Variant`],
      {
        [styles.active]: active,
        [styles.danger]: danger,
        [styles.iconOnly]: children === undefined,
        [styles.round]: round,
      },
    );
    const content = (
      <>
        {typeof icon === 'string' && (
          <Icon
            bold={iconBold ?? (active || size === 'large')}
            className={styles.icon}
            name={icon as keyof typeof MAPPING}
            size={iconSize ?? (size === 'medium' ? '16' : '24')}
          />
        )}
        {typeof icon !== 'string' && icon}
        {children}
      </>
    );

    return href ? (
      <Link
        aria-label={label}
        className={classes}
        replace={replace}
        target={target}
        to={href}
      >
        {content}
      </Link>
    ) : (
      <button
        aria-label={label}
        className={classes}
        disabled={disabled}
        onClick={onClick}
        onMouseDown={onMouseDown}
        ref={ref}
        // eslint-disable-next-line react/button-has-type
        type={type}
      >
        {content}
      </button>
    );
  },
);
