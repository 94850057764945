import * as Sentry from '@sentry/react';

const BENCHMARK_HIGH_VALUE = 100;

export function assertNever(x: never): never {
  throw new Error(`Unexpected object: ${x}`);
}

export function benchmark<T>(name: string, fn: () => T): T {
  const isBrowser = typeof window !== 'undefined';

  const transaction =
    isBrowser &&
    Sentry.startTransaction({
      name: 'benchmark',
      op: name,
    });
  const start = isBrowser && performance.now();

  const result = fn();

  if (transaction) {
    transaction.finish();
  }

  if (start) {
    const value = performance.now() - start;

    if (process.env.NODE_ENV === 'development') {
      console.debug(
        `%c${name} took ${Math.round(value)}ms`,
        value >= BENCHMARK_HIGH_VALUE && 'color: red',
      );
    }

    if (process.env.NODE_ENV !== 'test') {
      window.gtag('event', 'timing_complete', {
        name,
        value,
        event_category: 'performance',
      });

      window.analytics.track('benchmark', { name, value });
    }
  }

  return result;
}

/**
 * Return a base64-encoded SHA256 of a string.
 */
export async function sha256(data: string): Promise<string> {
  const encoder = new TextEncoder();
  const bytes = encoder.encode(data);
  const hash = await crypto.subtle.digest('SHA-256', bytes);

  return btoa(String.fromCharCode(...new Uint8Array(hash)));
}
