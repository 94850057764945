import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import AuthLayout from '@/components/AuthLayout';
import Button from '@/components/Button';
import { useVerifyEmailMutation } from '@/graphql';
import usePathParams from '@/hooks/usePathParams';

export default function Login() {
  const [verifyEmail, { data }] = useVerifyEmailMutation();
  const history = useHistory();
  const { verificationToken } = usePathParams('verificationToken');
  const error = data?.verifyEmail.error;

  useEffect(() => {
    verifyEmail({ variables: { verificationToken } });
  }, [verificationToken, verifyEmail]);

  useEffect(() => {
    if (data && !error) {
      setTimeout(() => {
        history.replace('/');
      }, 3000);
    }
  }, [data, error, history]);

  if (!data) {
    return null;
  }

  return (
    <AuthLayout>
      {error ? (
        <>
          <p>We couldn&apos;t confirm your email address.</p>
          <p>
            Please contact{' '}
            <Button href="mailto:support@wealthie.app" variant="link">
              customer support
            </Button>
            .
          </p>
        </>
      ) : (
        <>
          <p>Thank you for confirming your email address.</p>
          <p>
            <Button href="/" variant="link">
              Click here to continue
            </Button>
            .
          </p>
        </>
      )}
    </AuthLayout>
  );
}
