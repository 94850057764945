import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Layout from '@/components/Layout';
import SecurityHeader from '@/components/SecurityHeader';
import SecurityTabs from '@/components/SecurityTabs';
import { useSecurityQuery } from '@/graphql';
import usePathParams from '@/hooks/usePathParams';
import { groupPath, securityPath } from '@/paths';
import SecurityNews from '@/views/SecurityNews';
import SecurityOverview from '@/views/SecurityOverview';
import SecurityTransactions from '@/views/SecurityTransactions';

export default function Security() {
  const { portfolioId, securityId } = usePathParams(
    'portfolioId',
    'securityId',
  );
  const { path } = useRouteMatch();
  const security = useSecurityQuery({
    variables: { id: securityId, portfolioId },
  });

  return (
    <Layout
      breadcrumbs={security.data?.security.path
        ?.map(({ id, name }) => ({
          label: name,
          to: groupPath({ portfolioId, groupId: id }),
        }))
        .reverse()
        .concat({
          label: security.data.security.tickerSymbol,
          to: securityPath({ portfolioId, securityId }),
        })}
    >
      {security.data && (
        <>
          <SecurityHeader security={security.data.security} />
          <SecurityTabs id={securityId} portfolioId={portfolioId} />

          <Switch>
            <Route path={`${path}/transactions`}>
              <SecurityTransactions />
            </Route>
            <Route path={`${path}/news`}>
              <SecurityNews />
            </Route>
            <Route path={path}>
              <SecurityOverview />
            </Route>
          </Switch>
        </>
      )}
    </Layout>
  );
}
