import {
  add,
  Decimal,
  DECIMAL_0,
  divide,
  equal,
  multiply,
  subtract,
  toNumber,
} from 'bigint-decimal/esm/jsbi';

import {
  ActivitySeries,
  Comparison,
  ComparisonSeries,
  ComparisonSeriesItem,
  PriceMapSeries,
  Transaction,
  TransactionActivityItem,
} from '@/calc/types';
import { benchmark } from '@/utils';

interface GetComparisonSeriesArgs {
  dates: Date[];
  initialValue: Decimal;
  priceMapSeries: PriceMapSeries;
  securityIds: string[];
  activitySeries?: ActivitySeries;
}

function updateQuantityWithTransactions(
  quantity: Decimal,
  price: Decimal,
  transactions: Transaction[],
) {
  return transactions.reduce((acc, t) => {
    const value = multiply(t.price, t.quantity);
    const diff = equal(price, DECIMAL_0) ? DECIMAL_0 : divide(value, price);

    switch (t.type) {
      case 'buy':
        return add(acc, diff);
      case 'sell':
        return subtract(acc, diff);
      default:
        return acc;
    }
  }, quantity);
}

export default function getComparisonSeries({
  dates,
  initialValue,
  priceMapSeries,
  securityIds,
  activitySeries,
}: GetComparisonSeriesArgs): ComparisonSeries {
  return benchmark('getComparisonSeries', () => {
    const initialPriceMap = priceMapSeries[0].priceMap;
    let prevComparisonMap: { [key: string]: Comparison } = securityIds.reduce(
      (acc, securityId) => {
        const price = initialPriceMap[securityId] || DECIMAL_0;

        return {
          ...acc,
          [securityId]: {
            price,
            quantity: equal(price, DECIMAL_0)
              ? DECIMAL_0
              : divide(initialValue, price),
          },
        };
      },
      {},
    );

    return dates.map((at, i) => {
      const { priceMap } = priceMapSeries[i];
      const transactions =
        // i > 0 because we don't want to apply any transactions to initialValue
        activitySeries && i > 0
          ? activitySeries[i].activity
              .filter(
                (a): a is TransactionActivityItem => a.type === 'transaction',
              )
              .map((a) => a.data)
          : [];

      const comparisonMap = securityIds.reduce((acc, securityId) => {
        const price = priceMap[securityId] || DECIMAL_0;

        const comparison = {
          securityId,
          price,
          quantity: updateQuantityWithTransactions(
            acc[securityId].quantity,
            price,
            transactions,
          ),
        };

        return { ...acc, [securityId]: comparison };
      }, prevComparisonMap);

      prevComparisonMap = comparisonMap;

      const comparisons = Object.values(comparisonMap);

      return { at, comparisons };
    });
  });
}

export function getComparisonValue(
  item: ComparisonSeriesItem,
  i: number,
): Decimal {
  const { price, quantity } = item.comparisons[i] || {
    price: DECIMAL_0,
    quantity: DECIMAL_0,
  };
  return multiply(price, quantity);
}

export function getImpreciseComparisonValue(
  item: ComparisonSeriesItem,
  i: number,
): number {
  const { price, quantity } = item.comparisons[i] || {
    price: DECIMAL_0,
    quantity: DECIMAL_0,
  };
  return toNumber(price) * toNumber(quantity);
}
