/* eslint-disable react/jsx-props-no-spreading */
import { Decimal, DECIMAL_0, lessThan } from 'bigint-decimal/esm/jsbi';
import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Column, Row } from 'react-table';

import getHoldingsTableData from '@/calc/getHoldingsTableData';
import { Group } from '@/calc/types';
import Button from '@/components/Button';
import dndTypes, { Item } from '@/components/dndTypes';
import GroupFormModal from '@/components/GroupFormModal';
import Icon from '@/components/Icon';
import OverflowMenu, { OverflowMenuItem } from '@/components/OverflowMenu';
import Switch from '@/components/Switch';
import Table, {
  Cell,
  ChangeCell,
  Header,
  RowHover,
  ValueAllocationCell,
} from '@/components/Table';
import Toolbar, { ToolbarGroup } from '@/components/Toolbar';
import { formatPrice, formatQuantityDecimal } from '@/format';
import useLatestHoldings from '@/hooks/useLatestHoldings';
import useModal from '@/hooks/useModal';
import usePathParams from '@/hooks/usePathParams';
import useSecuritiesMap from '@/hooks/useSecuritiesMap';
import { groupPath, securityPath } from '@/paths';

import styles from './HoldingsTable.module.css';

const STICKY_TYPES = ['parentGroup'];

const collator = new Intl.Collator('en-US', {
  caseFirst: 'false',
  numeric: true,
});

function blurActiveElement() {
  if (document.activeElement instanceof HTMLElement) {
    document.activeElement.blur();
  }
}

function sortByNameColumn(
  rowA: Row<HoldingDatum>,
  rowB: Row<HoldingDatum>,
  _columnId: string,
  isDesc?: boolean,
) {
  const { label: labelA = '', type: typeA } = rowA.original;
  const { label: labelB = '', type: typeB } = rowB.original;

  if (STICKY_TYPES.includes(typeA)) {
    return isDesc ? 1 : -1;
  }

  if (typeA === typeB) {
    return collator.compare(labelA, labelB);
  }

  if (isDesc) {
    return typeA === 'group' ? 1 : -1;
  }

  return typeA === 'group' ? -1 : 1;
}

function sortByDecimalColumn(
  rowA: Row<HoldingDatum>,
  rowB: Row<HoldingDatum>,
  columnId: string,
  isDesc?: boolean,
) {
  const typeA = rowA.values.type;
  const valueA = rowA.values[columnId] || DECIMAL_0;
  const valueB = rowB.values[columnId] || DECIMAL_0;

  if (STICKY_TYPES.includes(typeA)) {
    return isDesc ? 1 : -1;
  }

  return lessThan(valueA, valueB) ? -1 : 1;
}

interface GroupCellProps {
  groupId: string;
  label: string;
  onDelete: (groupId: string) => void;
  onDrop: (groupId: string, item: Item) => void;
  onRename: (groupId: string, name: string) => void;
}

function GroupCell({
  groupId,
  label,
  onDelete,
  onDrop,
  onRename,
}: GroupCellProps) {
  const dndRef = useRef<HTMLDivElement>(null);
  const { portfolioId } = usePathParams('portfolioId');

  const [{ isDragging }, drag] = useDrag({
    type: dndTypes.GROUP,
    item: { id: groupId, type: dndTypes.GROUP },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: blurActiveElement,
  });

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [dndTypes.GROUP, dndTypes.SECURITY],
    canDrop: (item: Item) => item.id !== groupId,
    drop: (item) => {
      onDrop(groupId, item);
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
  });

  drag(drop(dndRef));

  const [showRenameModal] = useModal(({ hide }) => (
    <GroupFormModal
      editing
      hide={hide}
      onSubmit={({ name }) => {
        onRename(groupId, name);
      }}
      values={{ name: label }}
    />
  ));

  return (
    <div
      className={classNames(styles.nameCell, {
        [styles.active]: canDrop && isOver,
        [styles.inactive]: isDragging,
      })}
      onDrop={(e) => {
        // Workaround for:
        // https://github.com/react-dnd/react-dnd/issues/3179
        e.preventDefault();
      }}
      ref={dndRef}
    >
      <RowHover>
        <div className={styles.dragHandle}>
          <Icon name="drag" size="16" />
        </div>
      </RowHover>

      <Button
        className={styles.button}
        icon="group"
        href={groupPath({ portfolioId, groupId })}
        variant="lightButton"
      >
        {label}
      </Button>

      <RowHover>
        <OverflowMenu
          renderButton={({ onClick, ref }) => (
            <Button
              icon="overflowMenu"
              iconSize="16"
              label="More actions"
              onClick={onClick}
              variant="lightButton"
              ref={ref}
              size="large"
            />
          )}
        >
          <OverflowMenuItem icon="edit" onClick={showRenameModal}>
            Rename
          </OverflowMenuItem>
          <OverflowMenuItem
            icon="delete"
            onClick={() => {
              onDelete(groupId);
            }}
          >
            Delete
          </OverflowMenuItem>
        </OverflowMenu>
      </RowHover>
    </div>
  );
}

interface ParentGroupCellProps {
  parentGroupId: string;
  onDrop: (groupId: string, item: Item) => void;
  portfolioId: string;
}

function ParentGroupCell({
  onDrop,
  parentGroupId,
  portfolioId,
}: ParentGroupCellProps) {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [dndTypes.GROUP, dndTypes.SECURITY],
    drop: (item: Item) => {
      onDrop(parentGroupId, item);
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
  });

  // https://github.com/react-dnd/react-dnd/issues/1476#issuecomment-654842903
  const [canDropDelayed, setCanDropDelayed] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCanDropDelayed(canDrop);
    });
  }, [canDrop]);

  if (!canDropDelayed) {
    return null;
  }

  return (
    <Cell highlight={canDrop && isOver ? 'regular' : null}>
      <div
        onDrop={(e) => {
          // Workaround for:
          // https://github.com/react-dnd/react-dnd/issues/3179
          e.preventDefault();
        }}
        ref={drop}
      >
        <Button
          icon="parentGroup"
          href={groupPath({ portfolioId, groupId: parentGroupId })}
          variant="plain"
        >
          (up)
        </Button>
      </div>
    </Cell>
  );
}

interface SecurityCellProps {
  label: string;
  securityId: string;
}

function SecurityCell({ label, securityId }: SecurityCellProps) {
  const dndRef = useRef<HTMLDivElement>(null);
  const { portfolioId } = usePathParams('portfolioId');

  const [{ isDndActive }, drag] = useDrag({
    type: dndTypes.SECURITY,
    item: { id: securityId, type: dndTypes.SECURITY },
    collect: (monitor) => ({
      isDndActive: Object.values(dndTypes).includes(
        monitor.getItemType() as symbol,
      ),
    }),
    end: blurActiveElement,
  });

  drag(dndRef);

  return (
    <div
      className={classNames(styles.nameCell, {
        [styles.inactive]: isDndActive,
      })}
      ref={dndRef}
    >
      <RowHover>
        <div className={styles.dragHandle}>
          <Icon name="drag" size="16" />
        </div>
      </RowHover>

      <Button
        className={styles.button}
        icon="security"
        href={securityPath({ portfolioId, securityId })}
        variant="lightButton"
      >
        {label}
      </Button>
    </div>
  );
}

type HoldingRowType = 'group' | 'parentGroup' | 'security';

interface HoldingDatum {
  id?: string;
  allocation?: Decimal;
  label?: string;
  quantity?: Decimal | null;
  price?: Decimal | null;
  value?: Decimal;
  costBasis?: Decimal;
  dayChangePercentage?: Decimal;
  returnPercentage?: Decimal;
  returnValue?: Decimal;
  type: HoldingRowType;
}

interface HoldingsTableProps {
  group: Group;
  onGroupCreate: (name: string) => void;
  onGroupDelete: (groupId: string) => void;
  onGroupDrop: (groupId: string, item: Item) => void;
  onGroupRename: (groupId: string, name: string) => void;
  onParentGroupDrop: (groupId: string, item: Item) => void;
  portfolioId: string;
}

export default function HoldingsTable({
  group: { allSecurityIds: securityIds, parentId, subgroups },
  onGroupCreate,
  onGroupDelete,
  onGroupDrop,
  onGroupRename,
  onParentGroupDrop,
  portfolioId,
}: HoldingsTableProps) {
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'label',
          Cell: ({
            row: {
              original: { id = null, type },
            },
            value = '',
          }) => (
            <>
              {type === 'group' && id !== null && (
                <GroupCell
                  groupId={id}
                  label={value}
                  onDelete={onGroupDelete}
                  onDrop={onGroupDrop}
                  onRename={onGroupRename}
                />
              )}
              {type === 'parentGroup' && parentId !== null && (
                <ParentGroupCell
                  onDrop={onParentGroupDrop}
                  parentGroupId={parentId}
                  portfolioId={portfolioId}
                />
              )}
              {type === 'security' && id !== null && (
                <SecurityCell securityId={id} label={value} />
              )}
            </>
          ),
          compactCell: true,
          Header: ({ column }) => <Header column={column}>Name</Header>,
          sortType: sortByNameColumn,
        },
        {
          accessor: 'price',
          Cell: ({ value = null }) =>
            value !== null && <Cell right>{formatPrice(value)}</Cell>,
          Header: ({ column }) => (
            <Header column={column} right>
              Price
            </Header>
          ),
          sortType: sortByDecimalColumn,
        },
        {
          accessor: 'dayChangePercentage',
          Cell: ({ value = null }) =>
            value !== null && <ChangeCell changePercentage={value} />,
          compactCell: true,
          Header: ({ column }) => (
            <Header column={column} right>
              Day&apos;s change
            </Header>
          ),
          sortType: sortByDecimalColumn,
        },
        {
          accessor: 'quantity',
          Cell: ({ value = null }) =>
            value !== null && <Cell right>{formatQuantityDecimal(value)}</Cell>,
          Header: ({ column }) => (
            <Header column={column} right>
              Quantity
            </Header>
          ),
          sortType: sortByDecimalColumn,
        },
        {
          accessor: 'costBasis',
          Cell: ({ value = null }) =>
            value !== null && <Cell right>{formatPrice(value)}</Cell>,
          Header: ({ column }) => (
            <Header column={column} right>
              Cost
            </Header>
          ),
          sortType: sortByDecimalColumn,
        },
        {
          accessor: 'returnPercentage',
          Cell: ({ value = null }) =>
            value !== null && <ChangeCell changePercentage={value} />,
          compactCell: true,
          Header: ({ column }) => (
            <Header column={column} right>
              Return
            </Header>
          ),
          headerProps: {
            colSpan: 2,
          },
          sortType: sortByDecimalColumn,
        },
        {
          accessor: 'returnValue',
          Cell: ({ value = null }) =>
            value !== null && (
              <Cell
                highlight={
                  (lessThan(DECIMAL_0, value) && 'positive') ||
                  (lessThan(value, DECIMAL_0) && 'negative') ||
                  'muted'
                }
                right
              >
                {formatPrice(value)}
              </Cell>
            ),
          compactCell: true,
          Header: null,
        },
        {
          accessor: 'value',
          Cell: ({
            row: {
              original: { allocation = DECIMAL_0 },
            },
            value = null,
          }) =>
            value !== null && (
              <ValueAllocationCell allocation={allocation} value={value} />
            ),
          compactCell: true,
          Header: ({ column }) => (
            <Header column={column} right>
              Value
            </Header>
          ),
          sortType: sortByDecimalColumn,
        },
      ] as Column<HoldingDatum>[],
    [
      onGroupDelete,
      onGroupDrop,
      onGroupRename,
      onParentGroupDrop,
      parentId,
      portfolioId,
    ],
  );

  const [showGroups, setShowGroups] = useState(true);

  const [showCreateModal] = useModal(({ hide }) => (
    <GroupFormModal
      hide={hide}
      onSubmit={({ name }) => {
        onGroupCreate(name);
        setShowGroups(true);
      }}
    />
  ));

  const {
    data: holdingsSeries,
    loading: holdingsSeriesLoading,
  } = useLatestHoldings({
    portfolioId,
    securityIds,
  });

  const {
    data: securitiesMap,
    loading: securitiesMapLoading,
  } = useSecuritiesMap({ securityIds });

  if (holdingsSeriesLoading || securitiesMapLoading) {
    return null;
  }

  const holdingsTableData = getHoldingsTableData({
    holdingsSeries,
    securitiesMap,
    subgroups: showGroups ? subgroups : [],
  });

  if (holdingsTableData.length === 0) {
    return (
      <div className={styles.empty}>
        <p>All of this group&apos;s holdings have been sold.</p>
        <p>
          You can drag and drop additional securities into this group in the
          sidebar on the left.
        </p>
      </div>
    );
  }

  return (
    <>
      <Toolbar>
        <ToolbarGroup>
          <Button
            icon="newGroup"
            onClick={showCreateModal}
            variant="lightButton"
          >
            New group
          </Button>
        </ToolbarGroup>
        <ToolbarGroup>
          <div className={styles.switchWrapper}>
            <Switch
              label="Groups"
              onChange={(value) => {
                setShowGroups(value);
              }}
              value={showGroups}
            />
          </div>
        </ToolbarGroup>
      </Toolbar>

      <Table<HoldingDatum>
        columns={columns}
        data={[{ type: 'parentGroup' as HoldingRowType }]
          .filter(({ type }) => type === 'parentGroup' && parentId)
          .concat(holdingsTableData)}
        initialState={{
          sortBy: [{ id: 'label' }],
        }}
      />
    </>
  );
}
