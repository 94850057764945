import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';

import styles from './Tooltip.module.css';

interface TooltipProps {
  children: React.ReactNode;
  placement?: 'bottom' | 'left' | 'right' | 'top';
  referenceEl: Parameters<typeof usePopper>[0];
}

export default function Tooltip({
  children,
  placement = 'bottom',
  referenceEl,
}: TooltipProps) {
  const rootEl = document.getElementById('root');
  const [popperEl, setPopperEl] = useState<HTMLDivElement | null>(null);
  const [arrowEl, setArrowEl] = useState<HTMLDivElement | null>(null);

  const popper = usePopper(referenceEl, popperEl, {
    modifiers: [
      { name: 'arrow', options: { element: arrowEl } },
      { name: 'offset', options: { offset: [0, 32] } },
    ],
    placement,
  });

  if (!rootEl) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={styles.tooltip}
      ref={setPopperEl}
      style={popper.styles.popper}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...popper.attributes.popper}
    >
      <div
        className={styles.arrow}
        ref={setArrowEl}
        style={popper.styles.arrow}
      />
      {children}
    </div>,
    rootEl,
  );
}
